import React from "react";
import { formatDate, formatTime } from "../../controllers/datetime";
import { Link } from "react-router-dom";
import "../../styles/Buttons.css";

export const CalendarShift = ({ shiftsUser, onCancel }) => {
  if (shiftsUser.length === 0) {
    return (
      <div className="container">
        <div className="card card-body row text-card card-calendar">
          <h2>No tienes turnos agendados</h2>
          <div className="mt-3 col-">
            <Link to="/barber-shops" className="btn btn-on">
              BUSCAR TURNOS
            </Link>{" "}
          </div>
        </div>
      </div>
    );
  }

  const handleCancel = (idShift) => {
    onCancel(idShift);
  };

  return (
    <div className="container">
      <div>
        <h2 className="mb-4 mt-4 h1-tittle-white">TURNO RESERVADO</h2>
        <ul className="list-group mt-5">
          {shiftsUser.map((shift, index) => (
            <li key={index} className="list-group-item custom-list-group">
              <div className="row">
                <div className="col-md-6 p-text-white">
                  <p>
                    <strong>Fecha:</strong> {formatDate(shift.date_shift)}
                  </p>
                  <p>
                    <strong>Hora:</strong> {formatTime(shift.time_shift)}
                  </p>
                  <p>
                    <strong>Barbero:</strong> {shift.name_barber}{" "}
                    {shift.lastname}
                  </p>
                </div>
                <div className="col-md-6 p-text-white">
                  <p>
                    <strong>Barbería:</strong> {shift.name}
                  </p>
                  <p>
                    <strong>Dirección:</strong> {shift.address}
                  </p>
                </div>
              </div>
              <div className="text-end mt-3">
                <button
                  className="btn btn-off"
                  onClick={() => handleCancel(shift.id_shift)}
                >
                  Cancelar turno
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CalendarShift;

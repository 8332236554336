import React, { useEffect } from "react";
import "../../styles/GalleryBarbers.css";
const GalleryBarber = ({ filesBarber }) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {}, [filesBarber]);

  if (filesBarber && filesBarber.length === 0) {
    return (
      <>
        <br />
        <div className="ms-5 mt-5 card col-4 text-center text-info">
          El barbero no ha cargado fotos en su galería aún.
        </div>
        <button
          className="btn btn-danger ms-5 mt-3 col-4"
          onClick={() => {
            window.history.back();
          }}
        >
          VOLVER
        </button>
      </>
    );
  }

  return (
    <div className="container mt-5">
      <br />
      <h2 className="h2-barber">GALERIA DEL BARBERO</h2>
      <div className="gallery-container mt-4">
        {filesBarber.map((photo) => (
          <div key={photo.id_file} className="gallery-item">
            <img
              src={`${apiUrl}/gallery_photos/${photo.file_name}`}
              alt={`${photo.id_file}`}
            />
          </div>
        ))}
      </div>
      <button
        className="btn btn-danger ms-5 mt-3 col-4"
        onClick={() => {
          window.history.back();
        }}
      >
        VOLVER
      </button>
    </div>
  );
};

export default GalleryBarber;

import React, { useEffect, useState } from "react";
import Sidebar from "../../components/admin/global/Sidebar";
import LoadBarberShop from "../../components/admin/components/pages/Barbershops/LoadBarberShop";
import Logo4 from "../../assets/img/Logo4.png";
import { useNavigate } from "react-router-dom";
import instance from "../../connection_api";
import useSessionCheck from "../../customHooks/useSessionCheck";
import {
  swalAlertDanger,
  swalAlertInfo,
  swalAlertSuccess,
  swalConfirmationAlert,
} from "../../components/common/Alerts";
const LoadBarberPage = () => {
  useSessionCheck();
  const navigate = useNavigate();

  const [cities, setCities] = useState([]);
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseCities = await instance.get("/getCities");
        const responsePlans = await instance.get("/get_barbershop_plans");

        setCities(responseCities.data.cities);
        setPlans(responsePlans.data.plans);
        setIsLoading(false);
      } catch (error) {
        swalAlertDanger(
          "ERROR AL OBTENER LOS DATOS",
          "Intente nuevamente más tarde",
          () => {
            navigate("/barbermap-admin-cba-administrator");
          }
        );
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <>
        <div className="container bg-light text-center p-4 mt-5 rounded">
          <div className="loading-container">
            <img src={Logo4} alt="Loading" className="loading-image" />
          </div>
        </div>
      </>
    );
  }

  return (
    <div>
      <Sidebar />
      <div className="load-barber-shop">
        <LoadBarberShop cities={cities} plans={plans} />
      </div>
    </div>
  );
};

export default LoadBarberPage;

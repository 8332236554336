import React from "react";
import "../../styles/Buttons.css";
import { ArrowRightCircle, Shop } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

const ActionButtonMap = () => {
  return (
    <div>
      <section className="justify-items-center background-body noselect">
        <Link to="/barbershop-registration">
          <button className="btn-contact">
            CREAR MI BARBERIA
            <Shop size={25} className="ms-4" />
          </button>
        </Link>

        <Link to="/barber-shops">
          <button className="btn-contact">
            QUIERO SACAR UN TURNO
            <ArrowRightCircle size={25} className="ms-4" />
          </button>
        </Link>
      </section>

      <div className="d-flex justify-content-center mb-5">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/9Im58WXbPL4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
};

export default ActionButtonMap;

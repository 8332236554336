import React, { useEffect, useState } from "react";
import NavbarGestion from "../components/navbar/NavbarManagement";
import BannerGestion from "../components/baner/ManagementBanner";
import BannerGridManagement from "../components/companies/BannerGridManagement";
import BannerInicio from "../components/companies/BannerInicio";
import PricesAction from "../components/companies/PricesAction";
import Footer from "../components/footer/Footer";
import instance from "../connection_api";
import {
  swalAlertDanger,
} from "../components/common/Alerts";
import ActionButton from "../components/companies/ActionButton";

const ManagementPage = () => {
  const [plansData, setPlansData] = useState([]);

  useEffect(() => {
    instance
    .get("/get_barbershop_plans")
    .then((res) => {
      setPlansData(res.data.plans)
      
    })
    .catch((error) => {
      swalAlertDanger(
        "ERROR AL OBTENER LOS DATOS",
        "Intente nuevamente más tarde",
        () => {}
      );
    });
  }, []);

  if (plansData.length === 0) {
    return <div>Cargando ...</div>;
  }

  return (
    <div>
      <NavbarGestion />
      <BannerGestion plansData={plansData}/>
      <BannerGridManagement />
      <ActionButton/>
      <BannerInicio />
      <PricesAction />
      <Footer />
    </div>
  );
};

export default ManagementPage;
import React, { useState } from "react";
import ModalWindow from "../common/ModalWindow";
import FormAddService from "./FormAddService";
import FormEditService from "./FormEditService";
import instance from "../../connection_api";
import {
  swalAlertDanger,
  swalAlertSuccess,
  swalConfirmationAlert,
} from "../common/Alerts";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const BarberShopServicesAdmin = ({ services, handleAddService }) => {
  const [serviceToEdit, setServiceToEdit] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const navigate = useNavigate();

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleDeleteService = (data) => {
    swalConfirmationAlert(
      "¿Desea eliminar el servicio?",
      "No podrá deshacer esta acción",
      () => {
        instance
          .post("/barbermap_barbershop_delete_service", { data })
          .then((res) => {
            if (res.status === 200) {
              swalAlertSuccess(
                "Servicio eliminado con éxito",
                "Los clientes ya no tendrán este servicio disponible en tu barbería",
                () => {
                  window.location.reload();
                }
              );
            } else {
              swalAlertDanger(
                "Error al eliminar el servicio",
                "Intentelo nuevamente más tarde",
                () => {
                  navigate("/barbermap-barbershop-owner");
                }
              );
            }
          })
          .catch(() => {
            swalAlertDanger(
              "Error al eliminar el servicio",
              "Intentelo nuevamente más tarde",
              () => {
                navigate("/barbermap-barbershop-owner");
              }
            );
          });
      }
    );
  };

  const handleEditService = (data) => {
    instance
      .post("/barbermap_barbershop_edit_service", { data })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          swalAlertSuccess("Servicio modificado", "", () => {
            window.location.reload();
          });
        } else {
          swalAlertDanger(
            "Error al editar el servicio",
            "Intentelo nuevamente más tarde",
            () => {
              navigate("/barbermap-barbershop-owner");
            }
          );
        }
      })
      .catch(() => {
        swalAlertDanger(
          "Error al editar el servicio",
          "Intentelo nuevamente más tarde",
          () => {
            navigate("/barbermap-barbershop-owner");
          }
        );
      });
  };

  return (
    <>
      <div className="container">
        <h2>MIS SERVICIOS</h2>
        <button className="btn btn-primary mt-3" onClick={handleOpenModal}>
          + Agregar servicio
        </button>
      </div>
      {services && services.length > 0 ? (
        <div className="container mt-4 text-center">
          <table className="table table-bordered table-striped table-responsive-md">
            <thead className="thead-dark">
              <tr>
                <th>Servicio</th>
                <th>Precio</th>
                <th>Duración</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {services.map((service) => (
                <tr key={service.id_service}>
                  <td>{service.service_description}</td>
                  <td>${service.service_price}</td>
                  <td>{service.service_time}</td>
                  <td>
                    <button
                      className="btn btn-info"
                      onClick={() => {
                        setServiceToEdit(service);
                        setModalEdit(true);
                      }}
                    >
                      <EditIcon />
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        handleDeleteService(service.id_service);
                      }}
                    >
                      <DeleteIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="container mt-4">
          <p>No hay servicios creados por el momento.</p>
        </div>
      )}
      <ModalWindow
        title={"Edición de servicios"}
        body={
          <FormEditService
            service={serviceToEdit}
            handleEditService={handleEditService}
          />
        }
        modalOpen={modalEdit}
        setModalOpen={setModalEdit}
        footer={"Podrás modificar la descripción y el precio de tu servicio"}
      />
      <ModalWindow
        title={"Creación de servicio de barbería"}
        body={<FormAddService handleAddService={handleAddService} />}
        modalOpen={modalVisible}
        setModalOpen={setModalVisible}
        footer={
          <b>
            Los clientes podrán visualizar los servicios que tu barbería tiene
            disponibles
          </b>
        }
      />
    </>
  );
};

export default BarberShopServicesAdmin;

import React from "react";
import { Link } from "react-router-dom";


const Privacy = () => {
    return (

        <div className="container">
            <br />
            <h1 className="h1-tittle-white mt-5">Te damos la bienvenida a la Política de privacidad de <Link to="/" className="text-brown">BARBERMAP</Link></h1>
            <hr />
            <h4 className=" d-flex justify-content-end">
                Fecha de vigencia: [10/11/2023] -
                Última actualización: [8/12/2023]
            </h4>
            <p className="p-text-white mt-5">
                Bienvenido/a a BARBERMAP. En Metacode (Empresa desorrolladora del software), respetamos y valoramos tu privacidad. Esta Política de Privacidad describe cómo recopilamos, utilizamos y compartimos la información personal que nos proporcionas o que recopilamos a través de nuestro sitio web, aplicación u otros servicios relacionados.
            </p>
            <h3 className="h1-tittle-white">
                Información que Recopilamos
            </h3>
            <p className="p-text-white">
                Recopilamos información que nos proporcionas directamente cuando interactúas con nuestros Servicios, como cuando creas una cuenta, completas formularios o nos contactas para obtener soporte. También recopilamos automáticamente información sobre tu dispositivo y tu actividad en nuestros Servicios mediante cookies y tecnologías similares.
            </p>
            <h3 className="h1-tittle-white">
                Uso de la Información
            </h3>
            <p className="p-text-white">
                Utilizamos la información recopilada para proporcionar, mantener, mejorar y personalizar nuestros Servicios. Esto incluye el posicionamiento en el mapa, el envío de comunicaciones de servicio al cliente y la personalización de la experiencia del usuario. También podemos utilizar la información para fines de análisis y marketing.
            </p>
            <h3 className="h1-tittle-white">
                Compartir Información
            </h3>
            <p className="p-text-white">
                No vendemos, alquilamos ni compartimos tu información personal con terceros sin tu consentimiento, excepto según lo permitido por la ley. Podemos compartir información con proveedores de servicios que nos ayudan a operar y mejorar nuestros Servicios.

            </p>
            <h3 className="h1-tittle-white">
                Seguridad de la Información

            </h3>
            <p className="p-text-white">

                Tomamos medidas razonables para proteger la información personal que recopilamos y almacenamos. Sin embargo, ten en cuenta que ninguna transmisión por Internet o método de almacenamiento electrónico es completamente seguro.

            </p>
            <h3 className="h1-tittle-white">
                Tus Derechos y Elecciones

            </h3>
            <p className="p-text-white">
                Puedes ejercer ciertos derechos en relación con tu información personal, como acceder, corregir, eliminar o limitar su procesamiento. También puedes optar por no recibir comunicaciones de marketing directo.

            </p>
            <h3 className="h1-tittle-white">
                Cambios en esta Política de Privacidad

            </h3>
            <p className="p-text-white">
                Nos reservamos el derecho de actualizar esta Política de Privacidad en cualquier momento. Te notificaremos sobre cambios significativos a través de los medios apropiados y obtendremos tu consentimiento si es necesario.

            </p>
            <h3 className="h1-tittle-white">
                Contacto
            </h3>
            <p className="p-text-white">
                Si tienes preguntas o inquietudes sobre esta Política de Privacidad, por favor contáctanos en  <Link to="mailto:servicebarbermap@gmail.com">support@barbermap.com.ar.</Link>
            </p>
        </div>
    )
}


export default Privacy;
import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../styles/BannerStyle.css";
import "../../styles/ManagementHome.css";
import Navbar from "../../components/navbar/NavbarManagement";
import Footer from "../../components/footer/Footer";
import { Link } from "react-router-dom";
import Video from "../../assets/videos/Profiles.mp4"

const ProfileBarberTutorial = () => {
  const [showVideo, setShowVideo] = useState(false);

  const handleVideoClick = () => {
    setShowVideo(true);
  };

  const handleCloseClick = () => {
    setShowVideo(false);
  };
  return (
    <div className="noselect">
      <section className="banner background-banner-tutorial">
        <Navbar />
        <Container className="container-banner">
          <Row>
            <Col>
              <span className="tagline">
                CONFIGURACIÓN DE PERFIL DE LA BARBERIA
              </span>
              <h1>
                {"Perfil de la barberia "}
              </h1>
              <p>Resuelve tus dudas y encuentra los mejores consejos.</p>
            </Col>
          </Row>
        </Container>
      </section>

      <div>
        <br />
        {!showVideo ? (
          <div>
            <h1>
              <Link to="/salon-management-software-tutorial" className="no-style">
                Tutoriales
              </Link>
              ✂ Perfil de la barbería
            </h1>
            <button className="btn-card btn-on" onClick={handleVideoClick}>
              Ver video
            </button>
          </div>
        ) : (
            <div className='container-video-innovation'>
            <button className="close-button" onClick={handleCloseClick}>
            <i class="fa-solid fa-xmark"></i>
            </button>
            <video controls className='video-controls'>
              <source src={Video} type="video/mp4" />
              Tu navegador no soporta el tag de video.
            </video>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default ProfileBarberTutorial;

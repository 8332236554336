import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import { Shop, CalendarCheck } from "react-bootstrap-icons";
import "../../styles/BannerStyle.css";
import Image1 from "../../assets/img/img1.webp";
import Image2 from "../../assets/img/img2.webp";
import Image3 from "../../assets/img/img3.webp";
// import { Link } from "react-router-dom";

const imageList = [Image1, Image2, Image3];
const transitionDuration = 10000;

const IndexBanner = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [nextImageIndex, setNextImageIndex] = useState(1); // Index of the next image

  useEffect(() => {
    const interval = setInterval(() => {
      setNextImageIndex((currentImageIndex + 1) % imageList.length);
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageList.length);
    }, transitionDuration);

    return () => {
      clearInterval(interval);
    };
  }, [currentImageIndex]);

  // const handleConnectClick = () => {
  //   const connectSection = document.getElementById("connect");
  //   if (connectSection) {
  //     connectSection.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  return (
    <section className="banner" id="home">
      <Container className="noselect">
        <Row>
          <Col className="container-banner">
            <h1>
              <span className="tagline">
                AUTOMATIZA Y GESTIONA TU BARBERÍA HOY CON BARBERMAP
              </span>
            </h1>
            <h2>Llevá un control TOTAL de tu Barbería</h2>
            <p>
              Agendamiento automático, facturación y finanzas al detalle,
              catálogo y stock de productos
            </p>
            {/* <div className="d-flex ml-5">
              <Link to="/login">
                <button
                  className="button-connect me-3"
                  onClick={handleConnectClick}
                >
                  Quiero prestar servicios <Shop size={25} />
                </button>
              </Link>
              <Link to="/login">
                <button className="button-connect" onClick={handleConnectClick}>
                  Quiero sacar un turno <CalendarCheck size={25} />
                </button>
              </Link>
            </div> */}
          </Col>
        </Row>
      </Container>

      <div className="image-transition-container">
        <div className="background-gradient"></div>
        <div className="image-wrapper">
          {imageList.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Image ${index}`}
              className="transition-image"
              style={{
                opacity:
                  index === currentImageIndex || index === nextImageIndex
                    ? 1
                    : 0,
              }}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default IndexBanner;

import React, { useEffect, useState } from "react";
import SidebarOwner from "../../components/barber-owner/SidebarOwner";
import { useNavigate } from "react-router-dom";
import { validateRole } from "../../controllers/controllers";
import instance from "../../connection_api";
import { useSelector } from "react-redux";
import BarberCardAdmin from "../../components/barber-owner/BarberCardAdmin";
import {
  swalAlertDanger,
  swalAlertInfo,
  swalAlertSuccess,
  swalConfirmationAlert,
} from "../../components/common/Alerts";
import BarberAddModal from "../../components/barber-owner/BarberAddModal";
import useSessionCheck from "../../customHooks/useSessionCheck";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import ModalWindow from "../../components/common/ModalWindow";
import Logo4 from "../../assets/img/Logo4.png";

const BarberOwnerStaff = () => {
  useSessionCheck();
  const dataUser = useSelector((state) => state.user.dataUser);
  const [dataBarberShop, setDataBarberShop] = useState([]);
  const [dataBarbers, setDataBarbers] = useState([]);
  const [cities, setCities] = useState([]);
  const allowedRoles = [1];
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    instance
      .get("/getCities")
      .then((res) => {
        if (res.status === 200) {
          setCities(res.data.cities);
        }
      })
      .catch((error) => {
        swalAlertDanger("ERROR AL OBTENER LOS DATOS", "", () => {
          navigate("/barbermap-barbershop-owner");
        });
      });

    instance
      .get(`/data_barbershop_owner/${dataUser.id_barbershop_owner}`)
      .then((response) => {
        setDataBarberShop(response.data.dataBarberShop);
        setDataBarbers(response.data.dataBarbers);
      })
      .catch((error) => {
        swalAlertDanger("ERROR AL OBTENER LOS DATOS", "", () => {
          navigate("/barbermap-barbershop-owner");
        });
      });

    setIsLoading(true);
  }, [dataUser, navigate]);

  // Función para abrir el modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Función para cerrar el modal :3
  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  // Función para manejar el envío del formulario
  const handleFormSubmit = (data) => {
    instance
      .post("/barbermap_register_barber", { data })
      .then((res) => {
        if (res.status === 200) {
          swalAlertSuccess(
            "BARBERO REGISTRADO",
            "¡Ya puede comenzar a trabajar!",
            () => {
              navigate("/barbermap-barbershop-owner");
            }
          );
        } else {
          swalAlertInfo(
            "NO SE PUDO REALIZAR EL REGISTRO",
            "Ha alcanzado el límite de barberos para su plan. Comuníquese con soporte técnico para obtener un plan que se adapte a sus necesidades",
            () => {
              navigate("/barbermap-barbershop-owner");
            }
          );
        }
      })
      .catch((error) => {
        swalAlertDanger("ERROR", "Intentelo nuevamente más tarde", () => {
          navigate("/barbermap-barbershop-owner");
        });
      });
  };

  const handleDelete = async (data) => {
    swalConfirmationAlert(
      "¿DESEA ELIMINAR ESTE BARBERO DE LA BARBERIA?",
      "No podrá deshacer esta acción",
      () => {
        instance
          .post("/barbermap_delete_barber", { data })
          .then((res) => {
            if (res.status === 200) {
              swalAlertSuccess("BARBERO ELIMINADO", "", () => {
                navigate("/barbermap-barbershop-owner");
              });
            } else {
              swalAlertDanger("ERROR AL ELIMINAR EL BARBERO", "", () => {
                navigate("/barbermap-barbershop-owner");
              });
            }
          })
          .catch((error) => {
            swalAlertDanger("ERROR AL ELIMINAR EL BARBERO", "", () => {
              navigate("/barbermap-barbershop-owner");
            });
          });
      }
    );
  };

  if (!isLoading) {
    return (
      <>
        <div className="container bg-light text-center p-4 mt-5 rounded">
          <div className="loading-container">
            <img src={Logo4} alt="Loading" className="loading-image" />
          </div>
        </div>
      </>
    );
  }

  if (dataUser) {
    if (!validateRole(dataUser.user_rol, allowedRoles)) {
      return null;
    } else {
      return (
        <div>
          <div>
            <SidebarOwner dataBarberShop={dataBarberShop} />
          </div>
          <div className="card-padre mt-5 col-lg-2 col-sm-12">
            <button
              className="btn btn-primary btn-add-barber"
              onClick={openModal}
            >
              <PersonAddAltIcon /> AÑADIR BARBERO
            </button>
          </div>
          <div className="card-padre">
            {dataBarbers && dataBarbers.length > 0 ? (
              <BarberCardAdmin
                barbers={dataBarbers}
                handleDelete={handleDelete}
              />
            ) : (
              "No hay barberos disponibles"
            )}
          </div>
          <ModalWindow
            title={"Añadir barbero"}
            body={
              <BarberAddModal
                handleFormSubmit={handleFormSubmit}
                cities={cities}
                dataBarberShop={dataBarberShop}
              />
            }
            setModalOpen={setIsModalOpen}
            modalOpen={isModalOpen}
            footer={"Los clientes podrán solicitar turnos con el barbero."}
          />
        </div>
      );
    }
  }
};

export default BarberOwnerStaff;

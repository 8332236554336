import React, { useState } from "react";

const FormAddService = ({ handleAddService }) => {
  const [formData, setFormData] = useState({
    service_description: "",
    service_price: "",
    service_time: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Llama a la función handleAddService para enviar los datos del formulario
    handleAddService(formData);
    // Limpia los campos del formulario
    setFormData({
      service_description: "",
      service_price: "",
      service_time: "",
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-6">
          <label htmlFor="service_price">Precio del servicio:</label>
          <input
            className="form-control"
            type="number"
            id="service_price"
            name="service_price"
            placeholder="$"
            value={formData.service_price}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-6">
          <label htmlFor="service_time">Duración del servicio (minutos):</label>
          <input
            className="form-control"
            type="number"
            id="service_time"
            name="service_time"
            value={formData.service_time}
            onChange={handleInputChange}
          />
        </div>
        <div className="mt-3 col-12">
          <label htmlFor="service_description">Descripción del servicio:</label>
          <input
            className="form-control"
            type="text"
            id="service_description"
            name="service_description"
            value={formData.service_description}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <button className="btn btn-success col-12 mt-4" type="submit">
        Agregar servicio
      </button>
    </form>
  );
};

export default FormAddService;

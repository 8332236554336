import React, { useState, useEffect } from "react";
import PodiumPopularBarbershops from "../../components/admin/components/reports/PodiumPopularBarbershops";
import { ProgressBar } from "react-bootstrap";
import { swalAlertDanger } from "../../components/common/Alerts";
import instance from "../../connection_api";
import "../../styles/Ranking.css";
import NavTop from "../../components/nav-bottom/NavTop";
import FlechaAtras from "../../components/common/ArrowBack";

const Ranking = () => {
  const [mostPopularBarbershop, setMostPopularBarbershop] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseBS = await instance.get(
          "/barbermap/barbershops/most_popular"
        );
        setMostPopularBarbershop(responseBS.data.barbershops);

        setIsLoading(false);
      } catch (error) {
        swalAlertDanger("Error al obtener los datos", "", () => {});
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return (
      <>
        <div className="container bg-light p-4 mt-5 rounded">
          <div className="">Cargando...</div>
          <ProgressBar animated now={100} />
        </div>
      </>
    );
  }

  return (
    <div className="container-ranking">
      <NavTop />
      <FlechaAtras />
      <h3 className="h2-tittle-white">RANKING BARBERIAS POPULARES</h3>
      <PodiumPopularBarbershops podium={mostPopularBarbershop} />
    </div>
  );
};

export default Ranking;

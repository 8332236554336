import React, { useState } from "react";
import ModalWindow from "../../../../common/ModalWindow";
import instance from "../../../../../connection_api";
import FormEditPlan from "./FormEditPlan";
import { swalAlertDanger, swalAlertSuccess } from "../../../../common/Alerts";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FormCreatePlan from "./FormCreatePlan";

const PlansBarbershop = ({ plans, handleDelete }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [showModalCreate, setShowModalCreate] = useState(false);
  const navigate = useNavigate();

  const openModal = (plan) => {
    setSelectedPlan(plan);
    setIsModalOpen(true);
  };

  const handleSubmit = (selectedPlan) => {
    instance
      .post("/barbermap_admin_update_plans", { selectedPlan })
      .then((res) => {
        if (res.status === 200) {
          swalAlertSuccess("PLAN ACTUALIZADO", "", () => {
            navigate("/barbermap-admin-cba-administrator");
          });
        } else {
          swalAlertDanger("ERROR AL ACTUALIZAR LOS DATOS", "", () => {
            navigate("/barbermap-admin-cba-administrator");
          });
        }
      });
  };

  const handleCreate = async (data) => {
    instance
      .post("/barbermap/admin/create_plan", { data: data })
      .then((res) => {
        if (res.status === 200) {
          swalAlertSuccess("Plan creado correctamente", "", () => {
            window.location.reload();
          });
        }
      })
      .catch((error) => {
        console.log(error);
        swalAlertDanger("Error al generar el plan", "", () => {
          window.location.reload();
        });
      });
  };

  return (
    <>
      <div className="container background-sidebar">
        <div className="card-sidebar3 pb-5 pt-5 pt-sm-2 text-center">
          <h1 className="title-users">PLANES BARBERMAP</h1>
          <div className="container-table">
            <table className="table table-striped table-secondary">
              <thead>
                <tr>
                  <th className="date-users date-none-table2">ID PLAN</th>
                  <th className="date-users">DESCRIPCION</th>
                  <th className="date-users date-none-table-plans">
                    MAX. BARBEROS
                  </th>
                  <th className="date-users">COSTO MENSUAL</th>
                  <th className="date-users">EDITAR</th>
                  <th className="date-users">ELIMINAR</th>
                </tr>
              </thead>
              <tbody>
                {plans.map((plan) => (
                  <tr key={plan.id_plan}>
                    <td className="date-none-table2">{plan.id_plan}</td>
                    <td>{plan.plan_description}</td>
                    <td className="date-none-table-plans">
                      {plan.max_quantity_barbers}
                    </td>
                    <td>${plan.plan_price} ARS</td>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => openModal(plan)}
                      >
                        <EditIcon />
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDelete(plan.id_plan)}
                      >
                        <DeleteIcon />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="container row">
        <div className="col-12">
          <button
            className="btn btn-success"
            onClick={() => {
              setShowModalCreate(true);
            }}
          >
            <AddIcon /> GENERAR PLAN NUEVO
          </button>
        </div>
      </div>
      {selectedPlan && (
        <ModalWindow
          title="Editar plan existente"
          body={<FormEditPlan plan={selectedPlan} changeData={handleSubmit} />}
          setModalOpen={setIsModalOpen}
          modalOpen={isModalOpen}
          footer={"Administrador Barbermap"}
        />
      )}
      <ModalWindow
        title={"Generar un plan para barberías"}
        body={<FormCreatePlan handleCreate={handleCreate} />}
        setModalOpen={setShowModalCreate}
        modalOpen={showModalCreate}
        footer={"Administrador Barbermap"}
      />
    </>
  );
};

export default PlansBarbershop;

import React, { useState, useEffect } from "react";
import SidebarOwner from "../../components/barber-owner/SidebarOwner";
import instance from "../../connection_api";
import { useSelector } from "react-redux";
import FormSearchDate from "../../components/commonForms/FormSearchDate";
import { useNavigate } from "react-router-dom";
import {
  swalAlertDanger,
  swalAlertSuccess,
  swalConfirmationAlert,
} from "../../components/common/Alerts";
import AcordeonBarberShifts from "../../components/barber-owner/AcordeonBarbersShifts";
import useSessionCheck from "../../customHooks/useSessionCheck";

const BarberAdminHistory = () => {
  useSessionCheck();
  const dataUser = useSelector((state) => state.user.dataUser);
  const [dataBarberShop, setDataBarberShop] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [services, setServices] = useState([]);
  const [isOpenShifts, setOpenShifts] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    instance
      .get(`/data_barbershop_owner/${dataUser.id_barbershop_owner}`)
      .then((response) => {
        setDataBarberShop(response.data.dataBarberShop);
      })
      .catch((error) => {
        swalAlertDanger(
          "ERROR AL OBTENER LOS DATOS",
          "Intente nuevamente más tarde",
          () => {}
        );
      });

    instance
      .post("/barbermap/admin_metacode/get_services_by_barbershop", {
        data: dataUser.id_barbershop_owner,
      })
      .then((res) => {
        setServices(res.data.services);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dataUser]);

  const handleSearch = (date) => {
    const data = {
      date,
      id_barbershop: dataUser.id_barbershop_owner,
    };
    instance
      .post("/barbermap_barbershop_history", { data: data })
      .then((res) => {
        if (res.status === 200) {
          setShifts(res.data.shiftHistory);
          setOpenShifts(true);
        } else if (res.status === 203) {
          setShifts([]);
          setOpenShifts(true);
        }
      })
      .catch((error) => {
        swalAlertDanger(
          "Error al obtener los turnos",
          "Intentelo nuevamente más tarde",
          () => {
            navigate("/");
          }
        );
      });
  };

  const handleDeleteSelectedShifts = (data) => {
    swalConfirmationAlert(
      "¿DESEA ELIMINAR LOS TURNOS SELECCIONADOS?",
      "Solo se eliminarán los turnos en estado disponible o reservado. Si el turno estaba reservado se le notificará al cliente la cancelación del mismo",
      () => {
        instance
          .post("/barbermap_barbershop_delete_shifts", { data: data })
          .then((res) => {
            if (res.status === 200) {
              swalAlertSuccess("TURNOS ELIMINADOS", "", () => {
                navigate("/barbermap-barbershop-owner");
              });
            } else {
              swalAlertDanger(
                "ERROR AL ELIMINAR LOS TURNOS SELECCIONADOS",
                "Intente nuevamente más tarde",
                () => {
                  navigate("/barbermap-barbershop-owner");
                }
              );
            }
          });
      }
    );
  };

  const handleCreate = async (data) => {
    instance
      .post("/barbermap_barbershop_shift_unregistered_customer", {
        data: data,
      })
      .then((res) => {
        if (res.status === 200) {
          swalAlertSuccess("Turno registrado", "", () => {
            window.location.reload();
          });
        }
      })
      .catch((error) => {
        swalAlertDanger(
          "Error al registrar el turno",
          "Intente nuevamente más tarde",
          () => {}
        );
      });
  };

  return (
    <div>
      <SidebarOwner dataBarberShop={dataBarberShop} />
      <div className="mt-5 ms-2">
        <FormSearchDate onSearch={handleSearch} />
        <div className="container animated-wipe-down list-group">
          {isOpenShifts ? (
            <>
              {shifts && shifts.length > 0 ? (
                shifts.map((group) => (
                  <AcordeonBarberShifts
                    key={group.id_barber}
                    group={group}
                    handleDeleteSelectedShifts={handleDeleteSelectedShifts}
                    services={services}
                    handleCreate={handleCreate}
                  />
                ))
              ) : (
                <div className="card card-body text-info mt-4">
                  No se encontraron turnos en el historial para la fecha
                  seleccionada
                </div>
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default BarberAdminHistory;

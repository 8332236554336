import React from "react";
import Image from "../../assets/img/barber3.png";
import '../../styles/ManagementHome.css';
import { Link } from "react-router-dom";

const CardTutorial = () => {
  return (
    <div className="container noselect">
      <div className="row">
        {/* Tarjeta 1 */}
        <div className="col-lg-4 col-md-6 mb-4">
          <Link to="/salon-management-software-tutorial-barberprofile" className="no-style " >
          <article className="card card-tutorial">
            <div className='card-background-tutorial'>
              <img src={Image} alt="background" className="card-img-tutorial"/>
            </div>
            <div className='content-card-tutorial'>
              <h1>Perfil de la barbería</h1>
              <h5 className="banner-text-p">
                En esta sección podrás ver cómo modificar los horarios de apertura y cierre, cómo crear nuevos usuarios, configurar los días cerrados y más
              </h5>
            </div>
          </article>
          </Link>
        </div>

        {/* Tarjeta 2 */}
        <div className="col-lg-4 col-md-6 mb-4">
        <Link to="/salon-management-software-tutorial-reservations" className="no-style">
          <article className="card card-tutorial">
            <div className='card-background-tutorial'>
              <img src={Image} alt="background" className="card-img-tutorial"/>
            </div>
            <div className='content-card-tutorial'>
              <h1>Reservas</h1>
              <h5 className="banner-text-p">
              Aquí encontrarás cómo crear reservas, editarlas, modificar horarios
              y todo lo que necesitas saber para poder gestionar tus turnos.
              </h5>
            </div>
          </article>
          </Link>
        </div>

        {/* Tarjeta 3 */}
        <div className="col-lg-4 col-md-6 mb-4">
        <Link to="/salon-management-software-tutorial-turns" className="no-style">
          <article className="card card-tutorial">
            <div className='card-background-tutorial'>
              <img src={Image} alt="background" className="card-img-tutorial"/>
            </div>
            <div className='content-card-tutorial'>
              <h1>Turnos</h1>
              <h5 className="banner-text-p">
                Accede a todos tus turnos del dia!
              </h5>
            </div>
          </article>
          </Link>

        </div>
        <div className="col-lg-6 col-md-6 mb-4">
        <Link to="/salon-management-software-tutorial-administrator" className="no-style">
          <article className="card card-tutorial">
            <div className='card-background-tutorial'>
              <img src={Image} alt="background" className="card-img-tutorial"/>
            </div>
            <div className='content-card-tutorial'>
              <h1>Panel de Administrador</h1>
              <h5 className="banner-text-p">
                Administra tu barberia, todo desde aquí!
              </h5>
            </div>
          </article>
          </Link>
        </div>

        <div className="col-lg-6 col-md-6 mb-4">
        <Link to="/salon-management-software-tutorial-reports" className="no-style">
          <article className="card card-tutorial">
            <div className='card-background-tutorial'>
              <img src={Image} alt="background" className="card-img-tutorial"/>
            </div>
            <div className='content-card-tutorial'>
              <h1>Servicios y Barberos</h1>
              <h5 className="banner-text-p">
                Carga y gestiona tus barberos y servicios
              </h5>
            </div>
          </article>
          </Link>
        </div>

      </div>
    </div>
  );
}

export default CardTutorial;

import { DateTime } from 'luxon';

export const formatDate = (dateString) => {
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const formattedDate = new Date(dateString).toLocaleDateString(
    undefined,
    options
  );
  return formattedDate;
};

export const formatTime = (timeString) => {
  const options = { hour: "2-digit", minute: "2-digit", hour12: false };
  const formattedTime = new Date(`1970-01-01T${timeString}`).toLocaleTimeString(
    undefined,
    options
  );
  return formattedTime;
};


export const formatDateDMY = (date) => {
  const formatDate = DateTime.fromISO(date)
    .setZone("UTC")
    .toFormat("dd-MM-yyyy");
  return formatDate;
};

import React, { useState } from "react";
import { useForm } from "react-hook-form";

const FormCatalogCreateProduct = ({ categories, sendProduct }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (e) => {
    const files = e.target.files;
    setSelectedFiles(files);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();

    // Agregar campos individuales a formData
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("price", data.price);
    formData.append("category", data.category);
    formData.append("quantity", data.quantity);

    // Agregar archivo de imagen
    if (selectedFiles.length > 0) {
      formData.append("photo", selectedFiles[0]);
    }

    // Enviar formData a la función sendProduct
    await sendProduct(formData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
      <div className="mb-3">
        <label htmlFor="title" className="form-label">
          Título del producto
        </label>
        <input
          {...register("title", { required: true })}
          type="text"
          className={`form-control ${errors.title ? "is-invalid" : ""}`}
          id="title"
        />
        {errors.title && (
          <div className="invalid-feedback">Este campo es requerido</div>
        )}
      </div>
      <div className="mb-3">
        <label htmlFor="description" className="form-label">
          Descripción del producto
        </label>
        <textarea
          {...register("description", { required: true })}
          className={`form-control ${errors.description ? "is-invalid" : ""}`}
          id="description"
        />
        {errors.description && (
          <div className="invalid-feedback">Este campo es requerido</div>
        )}
      </div>
      <div className="mb-3">
        <label htmlFor="price" className="form-label">
          Precio
        </label>
        <input
          {...register("price", { required: true })}
          type="float"
          placeholder="$"
          className={`form-control ${errors.price ? "is-invalid" : ""}`}
          id="price"
        />
        {errors.price && (
          <div className="invalid-feedback">Este campo es requerido</div>
        )}
      </div>
      <div className="mb-3">
        <label htmlFor="quantity" className="form-label">
          Cantidad de productos (stock)
        </label>
        <input
          {...register("quantity", { required: true })}
          type="number"
          className={`form-control ${errors.quantity ? "is-invalid" : ""}`}
          id="quantity"
        />
        {errors.quantity && (
          <div className="invalid-feedback">Este campo es requerido</div>
        )}
      </div>
      <div className="mb-3">
        <label htmlFor="category" className="form-label">
          Categoria
        </label>
        <select
          {...register("category", { required: true })}
          className={`form-select ${errors.category ? "is-invalid" : ""}`}
          id="category"
        >
          <option value="">Seleccionar categoría</option>
          {categories.map((cat) => (
            <option key={cat.id_category} value={cat.id_category}>
              {cat.category_description}
            </option>
          ))}
        </select>
        {errors.category && (
          <div className="invalid-feedback">Este campo es requerido</div>
        )}
      </div>
      <div className="mb-3">
        <label htmlFor="photo" className="form-label">
          Imagen del producto
        </label>
        <input
          type="file"
          className="form-control"
          id="photo"
          name="photo"
          required
          onChange={handleFileChange}
        />
      </div>
      <button type="submit" className="btn btn-success mt-3">
        Cargar producto
      </button>
    </form>
  );
};

export default FormCatalogCreateProduct;

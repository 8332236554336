import React, { useEffect, useState } from "react";
import { formatDate, formatTime } from "../controllers/datetime";
import { useNavigate } from "react-router-dom";
import instance from "../connection_api";
import {
  swalAlertDanger,
  swalAlertSuccess,
  swalAlertTimer,
  swalConfirmationAlert,
} from "../components/common/Alerts";
import "../styles/CardTurnos.css";
import useSessionCheck from "../customHooks/useSessionCheck";
import ModalWindow from "../components/common/ModalWindow";
import FormShiftDone from "../components/barber/FormShiftDone";

const TableShifts = ({ nextShifts }) => {
  useSessionCheck();

  const [acordeones, setAcordeones] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedShift, setSelectedShift] = useState(null);
  const [modalDone, setModalDone] = useState(false);
  const navigate = useNavigate();

  const serviceStatus = ({ shift }) => {
    if (shift.status_shift === 2) {
      return "Reservado";
    } else {
      if (shift.status_shift === 4) {
        return "Realizado";
      } else {
        if (shift.status_shift === 3) {
          return "Cancelado";
        }
      }
    }
  };

  useEffect(() => {
    const agruparTurnosEnAcordeones = () => {
      const turnosAgrupados = nextShifts.reduce((acc, shift) => {
        const fechaShift = new Date(shift.date_shift).toLocaleDateString();

        if (!acc[fechaShift]) {
          acc[fechaShift] = [];
        }

        acc[fechaShift].push(shift);
        return acc;
      }, {});

      const acordeonesGenerados = Object.entries(turnosAgrupados).map(
        ([fecha, turnos]) => ({
          fecha,
          turnos,
        })
      );
      setAcordeones(acordeonesGenerados);
    };

    agruparTurnosEnAcordeones();

    const fetchData = async () => {
      instance
        .get("/barbermap/barbershop/get_payments_methods")
        .then((res) => {
          if (res.status === 200) {
            setPaymentMethods(res.data.methods);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchData();
  }, [nextShifts]);

  const handleRealizadoClick = (data) => {
    swalConfirmationAlert(
      "¿DESEA MARCAR EL TURNO COMO REALIZADO?",
      "No podrá deshacer esta acción",
      () => {
        instance
          .post("/barbermap_barber_complete_shift", { data })
          .then((res) => {
            if (res.status === 200) {
              swalAlertSuccess(`${res.data.msg}`, "", () => {
                window.location.reload();
              });
            } else {
              swalAlertDanger(
                "ERROR AL FINALIZAR EL TURNO",
                "Intentelo nuevamente más tarde",
                () => {
                  navigate("/barbermap-barber");
                }
              );
            }
          })
          .catch((error) => {
            swalAlertTimer(
              "SESION EXPIRADA, POR FAVOR VUELVA A INICIAR SESION",
              3000
            );
            navigate("/login");
          });
      }
    );
  };

  return (
    <div>
      {acordeones.map(({ fecha, turnos }, index) => (
        <div className="accordion" key={index}>
          <div className="accordion-item">
            <h2 className="accordion-header" id={`heading${index}`}>
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${index}`}
                aria-expanded="true"
                aria-controls={`collapse${index}`}
              >
                {fecha}
              </button>
            </h2>
            <div
              id={`collapse${index}`}
              className="accordion-collapse collapse show"
              aria-labelledby={`heading${index}`}
            >
              <div className="accordion-body">
                {turnos.map((shift) => (
                  <div className="next-shift-card" key={shift.id_shift}>
                    <div className="div-card-user">
                      <div className="ul-card-turno">
                        <p className="parrafo-data">
                          <strong>Día:</strong> {formatDate(shift.date_shift)} -{" "}
                          <strong>Hora:</strong> {formatTime(shift.time_shift)}{" "}
                        </p>
                        <p className="parrafo-data">
                          <strong>Cliente:</strong> {shift.name}{" "}
                          {shift.lastname}
                        </p>
                        <p className="parrafo-data">
                          <strong>Servicio:</strong> {shift.service_description}
                        </p>
                        <p className="parrafo-estado">
                          <strong>Estado:</strong>{" "}
                          <p className={`${serviceStatus({ shift })}`}>
                            {serviceStatus({ shift })}
                          </p>
                        </p>
                        <div className="div-button-user">
                          {shift.status_shift === 2 && (
                            <button
                              className="btn btn-on"
                              onClick={() => {
                                setSelectedShift(shift.id_shift);
                                setModalDone(true);
                              }}
                            >
                              REALIZADO
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
      {acordeones &&  acordeones.length === 0 && (
        <div className="card card-body text-info">
          No hay turnos registrados para los próximos 5 días
        </div>
      )}
      {modalDone && (
        <ModalWindow
          title={"Registrar servicio finalizado"}
          setModalOpen={setModalDone}
          modalOpen={modalDone}
          body={
            <FormShiftDone
              shift={selectedShift}
              paymentMethods={paymentMethods}
              handleDone={handleRealizadoClick}
            />
          }
          footer={
            "Quedará registro del servicio realizado, podrás visualizarlo en tu historial y estadísticas."
          }
        />
      )}
    </div>
  );
};

export default TableShifts;

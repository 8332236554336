import { swalAlertDanger } from "../components/common/Alerts";

export const validateRole = (user_rol, allowedRoles) => {
  const invalidRol = !allowedRoles.includes(user_rol);
  if (invalidRol) {
    swalAlertDanger(
      "ERROR!!! Usted no cuenta con las credenciales para ingresar a este sitio",
      "Por favor vuelva a ingresar a la sección correspondiente",
      () => {
        window.location.href = "/login";
      }
    );
    return false;
  }
  return true;
};

export const verifyRoutes = (user) => {
  switch (user.user_rol) {
    case 0:
      return "/barbermap-admin-cba-administrator";
    case 1:
      return `/barbermap-barbershop-owner`; //Ruta para administradores de barberías
    case 2:
      return "/barbermap-barber"; //Ruta para barberos
    case 3:
      return "/user-management"; //Ruta para clientes
  }
};

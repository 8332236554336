import React from "react";
import Sidebar from "../../components/admin/global/Sidebar";
import ConsultFee from "../../components/admin/components/pages/ConsultFee/ConsultFee";
import useSessionCheck from "../../customHooks/useSessionCheck";

const ConsultFeePage = () => {
  useSessionCheck();
  return (
    <div>
        <Sidebar />
      <div className="load-barber-shop">
        <ConsultFee />
      </div>
    </div>
  );
};

export default ConsultFeePage;

import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../redux/userActions"; // Ajusta la ruta correcta
import LogoMain from "../../assets/img/Logo3.png";
import "../../styles/MainPage.css";
import Logo4 from "../../assets/img/Logo4.png";
import { useSelector } from "react-redux";

const NavbarIndex = ({ logout }) => {
  const [clicked, setClicked] = useState(false);
  const dataUser = useSelector((state) => state.user.dataUser);

  const handleClick = () => {
    setClicked(!clicked);
  };

  return (
    <nav className="NavbarItems-index">
      <div className="logo-index">
        <Link to="/user-management">
          <img src={LogoMain} alt="BARBER-MAP" className="image-logo-index" />
        </Link>
      </div>
      <div className="menu-icons-index" onClick={handleClick}>
        <i className={clicked ? "fas fa-times" : "fas fa-bars"} />
      </div>

      <ul className={clicked ? "nav-menu active" : "nav-menu"}>
        <li className="item-navbar-index">
          <Link className="nav-links-barber" to="/user-management">
            <i className="fas fa-cut icon-navbar-index"></i>Barberias
          </Link>
        </li>
        <li className="item-navbar-index">
          <Link className="nav-links-barber" to="/barber-shops">
            <i className="fas fa-cut icon-navbar-index"></i>Mapa
          </Link>
        </li>
        <li className="item-navbar-index">
          <Link className="nav-links-barber" to="/calendar">
            <i className="fas fa-cut icon-navbar-index"></i>Mis turnos
          </Link>
        </li>
        <li className="item-navbar-index">
          <Link className="nav-links-barber" to="/favorites">
            <i className="fas fa-cut icon-navbar-index"></i>Favoritos
          </Link>
        </li>
        <li className="item-navbar-index">
          <Link className="nav-links-barber" to="/profile">
            <i className="fas fa-cut icon-navbar-index"></i>Perfil
          </Link>
        </li>
        <li className="item-navbar-index">
          <Link className="nav-links-barber" to="/ranking-barbershop">
            <i className="fas fa-cut icon-navbar-index"></i>Ranking
          </Link>
        </li>
        <li className="item-navbar-index">
          <Link
            className="nav-links-barber"
            to="/login"
            onClick={dataUser ? logout : null}
            style={{
              backgroundColor: dataUser ? "#ff0000" : "#0000ff",
              borderRadius: "10px",
            }}
          >
            {dataUser ? (
              <i className="fas fa-sign-out-alt icon-navbar-index"></i>
            ) : (
              <i className="fas icon-navbar-index"></i>
            )}
            {dataUser ? "Cerrar Sesión" : "Iniciar Sesión"}
          </Link>
        </li>
        {/* Otros elementos codificados manualmente */}
        <hr className="divider" />
        <img src={Logo4} className="logo-bottom-sidebar" alt="Logo4" />
      </ul>
    </nav>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(null, mapDispatchToProps)(NavbarIndex);

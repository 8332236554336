import React from "react";
import "../../styles/ManagementHome.css";
import { FaCheck, FaCalendarCheck, FaHeadset } from "react-icons/fa";
import "../../styles/PlansCard.css";
const PricesCard = ({ prices }) => {
  return (
    <div>
      {prices.map((plan, index) => {
        // Calcula el precio anual con descuento del 20%
        const precioAnualConDescuento = (plan.plan_price * 12 * 0.8).toFixed(2);

        return (
          <div
            key={index}
            className="container prices-container prices-card-container noselect mt-5"
            id="prices-card"
          >
            <div className="card card-prices mt-5">
              <h2>{plan.plan_description}</h2>
              <div className="var-prices">
                <abbr>$</abbr>
                {plan.plan_price}
                <small>/mes</small>
              </div>
              <ul>
                <div className="p-prices">
                  <p>
                    <FaCheck /> {plan.plan_description}
                  </p>
                  <p>
                    <FaCalendarCheck /> Agenda para {plan.max_quantity_barbers}{" "}
                    barbero/s
                  </p>
                  <p>- Agenda Online</p>
                  <p>- Catálogo de venta de productos para cada barbería</p>
                  <p>- Posicionamiento en el mapa de la app</p>
                  <p>- Sistema de rankings</p>
                  <p>- QR y link para agenda directa</p>
                  <p>- Galeria de fotos para cada barbero</p>
                  <p>
                    <FaHeadset /> Asistencia personalizada
                  </p>
                </div>
              </ul>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  const message = encodeURIComponent(
                    `Hola, quiero contactarme por el plan: ${plan.plan_description}
                    ¡Gracias!`
                  );
                  window.open(
                    `https://api.whatsapp.com/send?phone=3512035657&text=${message}`,
                    "_blank"
                  );
                }}
              >
                <button className="button-prices">Contactate</button>
              </a>
              <hr />
              <small>
                Pago anual con descuento: {precioAnualConDescuento}$
              </small>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PricesCard;

import React, { useState, useEffect } from "react";
import instance from "../../connection_api";
import useSessionCheck from "../../customHooks/useSessionCheck";
import SidebarOwner from "../../components/barber-owner/SidebarOwner";
import { useSelector } from "react-redux";
import { validateRole } from "../../controllers/controllers";
import BarberShopServicesAdmin from "../../components/barber-owner/BarberShopServicesAdmin";
import {
  swalAlertDanger,
  swalAlertSuccess,
} from "../../components/common/Alerts";
import { useNavigate } from "react-router-dom";

const BarberShopServices = () => {
  useSessionCheck();
  const dataUser = useSelector((state) => state.user.dataUser);
  const [dataBarberShop, setDataBarberShop] = useState([]);
  const [services, setServices] = useState([]);
  const [error, setError] = useState(null);
  const allowedRoles = [1];
  const navigate = useNavigate();

  useEffect(() => {
    if (dataUser) {
      instance
        .get(`/data_barbershop_owner/${dataUser.id_barbershop_owner}`)
        .then((response) => {
          setDataBarberShop(response.data.dataBarberShop);
          setError(null);
        })
        .catch((error) => {
          setError(error.message || "Hubo un error en la solicitud.");
        });

      instance
        .get("/barbershop_get_services")
        .then((res) => {
          setServices(res.data.services);
        })
        .catch((error) => {
          setError(error.message || "Hubo un error en la solicitud.");
        });
    }
  }, [dataUser]);

  const handleAddService = async (data) => {
    instance
      .post("/barbermap_barbershop_add_service", { data })
      .then((res) => {
        if (res.status === 200) {
          swalAlertSuccess(
            "Servicio creado con éxito",
            "Los clientes ya pueden solicitar turnos para este servicio",
            () => {
              window.location.reload();
            }
          );
        } else {
          swalAlertDanger(
            "Error al generar el servicio",
            "Intentelo nuevamente más tarde",
            () => {
              navigate("/barbermap-barbershop-owner");
            }
          );
        }
      })
      .catch((error) => {
        swalAlertDanger(
          "ERROR AL OBTENER LOS DATOS",
          "Intente nuevamente más tarde",
          () => {}
        );
      });
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (dataUser) {
    if (!validateRole(dataUser.user_rol, allowedRoles)) {
      return null;
    } else {
      return (
        <div className="">
          <SidebarOwner dataBarberShop={dataBarberShop} />
          <div className="mt-3">
            <BarberShopServicesAdmin
              services={services}
              handleAddService={handleAddService}
            />
          </div>
        </div>
      );
    }
  }
  return null;
};

export default BarberShopServices;

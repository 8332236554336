import IndexBanner from "../components/baner/IndexBanner";
import Navbar from "../components/navbar/NavbarMain";
import BannerGrid from "../components/baner/BannerGrid";
// import BanerBenefits from "../components/baner/BanerBenefits";
// import BannerGridSesion from "../components/baner/BannerGridSesion";
import BannerFunctions from "../components/companies/BannerFunctions"
import Faq from "../components/baner/Faq";
import Footer from "../components/footer/Footer";
import ActionButtonMap from "../components/baner/ActionButtonMap";

const Inicio = () => {
  return (
    <div className="container-index">
      <Navbar />
      <IndexBanner />
      <ActionButtonMap />
      <BannerGrid />
      <BannerFunctions/>
      {/* <BanerBenefits /> */}
      {/* <BannerGridSesion /> */}
      <Faq />
      <Footer />
    </div>
  );
};

export default Inicio;

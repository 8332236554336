import React, { useState } from "react";

const FormCreatePlan = ({ handleCreate }) => {
  const [formData, setFormData] = useState({
    plan_description: "",
    max_quantity_barbers: "",
    plan_price: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleCreate(formData);
  };

  return (
    <div className="container mt-5">
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="plan_description" className="form-label">
            Nombre del plan
          </label>
          <input
            type="text"
            className="form-control"
            id="plan_description"
            name="plan_description"
            value={formData.plan_description}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="max_quantity_barbers" className="form-label">
            Cantidad máxima de barberos
          </label>
          <input
            type="number"
            className="form-control"
            id="max_quantity_barbers"
            name="max_quantity_barbers"
            value={formData.max_quantity_barbers}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="plan_price" className="form-label">
            Precio del plan
          </label>
          <input
            type="number"
            step="0.01"
            className="form-control"
            id="plan_price"
            name="plan_price"
            value={formData.plan_price}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-success col-12 mt-3">
          Crear plan
        </button>
      </form>
    </div>
  );
};

export default FormCreatePlan;

import React, { useState } from "react";
import { connect } from "react-redux";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import HomeIcon from "@mui/icons-material/Home";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ScheduleIcon from "@mui/icons-material/Schedule";
import StoreIcon from "@mui/icons-material/Store";
import { logout } from "../redux/userActions";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LogoutIcon from "@mui/icons-material/Logout";
import "../../styles/NavbarOwnerBarber.css";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import PriceCheck from "@mui/icons-material/PriceCheck";
import CreditScore from "@mui/icons-material/CreditScore";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";

const SidebarOwner = ({ dataBarberShop, logout }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <div className="background-sidebar-admin">
      <Navbar expand="lg" className="menu-owner">
        <Container>
          <Navbar.Brand>
            <div className="div-sidebar">
              <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                onClick={() => setIsCollapsed(!isCollapsed)}
              >
                <MenuOutlinedIcon />
              </Navbar.Toggle>
            </div>
          </Navbar.Brand>

          <Navbar.Collapse id="responsive-navbar-nav">
            <div className="container-nameshop">
              <div className="div-name text-center">
                Administrador
                <h3>
                  {dataBarberShop && dataBarberShop.length > 0
                    ? dataBarberShop[0].name
                    : ""}
                </h3>
              </div>
              <div className="img-logo-barber">
                {dataBarberShop && dataBarberShop.length > 0 ? (
                  <img
                    width="100px"
                    height="100px"
                    src={`https://apiserver.barbermap.com.ar/logo_barbershops/${dataBarberShop[0].path_logo}`}
                    style={{ borderRadius: "50%" }}
                    alt="Barber Shop Logo"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <Nav className="ms-auto">
              <Nav.Link className="item-navbar-owner">
                <Link
                  to="/barbermap-barbershop-owner"
                  className="nav-link navtext"
                >
                  <HomeIcon className="icon-svg" />
                  Inicio
                </Link>
              </Nav.Link>

              <Nav.Link className="item-navbar-owner">
                <Link
                  to="/barbermap-barber-owner-shifts"
                  className="nav-link navtext"
                >
                  <CalendarMonthIcon className="icon-svg" />
                  Mis turnos
                </Link>
              </Nav.Link>

              <NavDropdown
                title="Funciones"
                id="basic-nav-dropdown"
                className="nav-link item-navbar-owner-2"
              >
                <NavDropdown.Item>
                  <Link
                    to="/barbermap-barbershop-owner-barbers"
                    className="navtext-2 nav-link"
                  >
                    <ManageAccountsIcon className="icon-svg" />
                    Administrar barberos
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    to="/barbermap-barbershop-services"
                    className="navtext-2 nav-link"
                  >
                    <ContentCutIcon className="icon-svg" />
                    Administrar servicios
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    to="/barbermap-barbershop-catalog-administrator"
                    className="navtext-2 nav-link"
                  >
                    <AddBusinessIcon className="icon-svg" />
                    Administrar catálogo
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="Reportes"
                id="reportes-dropdown"
                className="nav-link item-navbar-owner-2"
              >
                <NavDropdown.Item>
                  <Link
                    to="/barbermap-barbershop-box"
                    className="navtext-2 nav-link"
                  >
                    <CreditScore className="icon-svg" />
                    Facturación productos
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    to="/barbermap-barbershop-billing-details"
                    className="navtext-2 nav-link"
                  >
                    <PriceCheck className="icon-svg" />
                    Facturación barbería
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    to="/barbermap-barbershop-history"
                    className="navtext-2 nav-link"
                  >
                    <ScheduleIcon className="icon-svg" />
                    Historial de turnos
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    to="/barbermap-barbershop-history-shift-fast"
                    className="navtext-2 nav-link"
                  >
                    <ElectricBoltIcon className="icon-svg" />
                    Historial de turnos rápidos
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="Perfiles"
                id="basic-nav-dropdown"
                className="nav-link item-navbar-owner-2"
              >
                <NavDropdown.Item>
                  <Link to="/profile" className="navtext-2 nav-link">
                    <AccountBoxIcon className="icon-svg navtext-2" />
                    Mi perfil
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    to="/barbermap-barbershop-profile"
                    className="nav-link navtext-2"
                  >
                    <StoreIcon className="icon-svg navtext-2" />
                    Perfil de barbería
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link className="item-navbar-owner">
                <Link to="/ranking-barbershop" className="nav-link navtext">
                  <StarHalfIcon className="icon-svg" />
                  Ranking
                </Link>
              </Nav.Link>

              <Nav.Link className="item-navbar-owner-logout">
                <Link to="/login" onClick={logout} className="nav-link navtext">
                  <LogoutIcon className="icon-svg" />
                </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(null, mapDispatchToProps)(SidebarOwner);

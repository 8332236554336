// actions.js

export const SET_DATA_USER = "SET_DATA_USER";
export const RESET_DATA_USER = "RESET_DATA_USER";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const LOGOUT = "LOGOUT";  // Nueva acción para cerrar sesión

export const setUserData = (userData) => ({
  type: SET_DATA_USER,
  payload: userData,
});

export const resetUserState = () => ({
  type: RESET_DATA_USER,
});

export const updateUserProfile = (userData) => ({
  type: UPDATE_USER_PROFILE,
  payload: userData,
});

export const logout = () => ({
  type: LOGOUT,
});

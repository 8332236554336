import React, { useState } from "react";
import ModalUser from "./ModalUser";
import "../../../../../styles/SidebarAdmin.css";
import Pagination from "react-responsive-pagination";

const ConsultUsers = ({ users, roles, cities, handleChangeDataUser }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProvince, setSelectedProvince] = useState("");
  const [searchName, setSearchName] = useState("");
  const usersPerPage = 10;

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const filteredUsers = users
    .filter((user) => {
      return !selectedProvince || user.city === parseInt(selectedProvince);
    })
    .filter(
      (user) =>
        !searchName ||
        `${user.name} ${user.lastname}`
          .toLowerCase()
          .includes(searchName.toLowerCase())
    );
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handleEdit = (user) => {
    setSelectedUser(user);
  };

  const handleProvinceChange = (event) => {
    setSelectedProvince(event.target.value);
    setCurrentPage(1);
  };

  const handleNameChange = (event) => {
    setSearchName(event.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <div className="card card-sidebar2">
        <h1 className="title-users">USUARIOS BARBER MAP</h1>
        <div className="search-filters row">
          <hr className="hr-white"/>
          <div className="col-8">
            <input
              type="text"
              className="form-control-text"
              value={searchName}
              onChange={handleNameChange}
              placeholder="Buscar por nombre"
            />
          </div>
          <div className="col-4 select-admin">
            <select
              value={selectedProvince}
              onChange={handleProvinceChange}
              className="form-style"
            >
              <option value="">Todas las provincias</option>
              {cities.map((city) => (
                <option key={city.id_city} value={city.id_city}>
                  {city.city_description}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="container-table">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="date-users date-none-table">ID</th>
                <th className="date-users">Nombre</th>
                <th className="date-users date-none-table">Apellido</th>
                <th className="date-users">Email</th>
                <th className="date-none-table date-users">Celular</th>
                <th className="date-users date-none-table">Rol</th>
                <th className="date-users date-none-table">Ciudad</th>
                <th className="date-users">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.map((user) => (
                <tr key={user.id_user}>
                  <td className="date-none-table">{user.id_user}</td>
                  <td>{user.name}</td>
                  <td className="date-none-table">{user.lastname}</td>
                  <td>{user.email}</td>
                  <td className="date-none-table">{user.cellphone}</td>
                  <td className="date-none-table">{user.rol_description}</td>
                  <td className="date-none-table">{user.city_description}</td>
                  <td>
                    <button
                      className="btn btn-primary2"
                      onClick={() => handleEdit(user)}
                    >
                      Editar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {selectedUser && (
            <ModalUser
              user={selectedUser}
              roles={roles}
              cities={cities}
              onSave={(editedUser) => {
                handleChangeDataUser(editedUser);
              }}
              onClose={() => {
                setSelectedUser(null);
              }}
            />
          )}
          <Pagination
            total={filteredUsers.length}
            current={currentPage}
            onPageChange={handlePageChange}
          />

        </div>
      </div>
    </div>
  );
};

export default ConsultUsers;

import React from "react";
import PaymentsIcon from "@mui/icons-material/Payments";
import { formatDate } from "../../controllers/datetime";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";

const CardStatsCatalog = ({ Transaction, DataCatalog, TotalCatalog }) => {
  const transactionsList = Transaction.map((transaction, index) => (
    <div key={index} className="text-white">
      <p>
        Producto: {transaction.title_product}
        <br />
        Precio de venta: ${transaction.price_transaction}
        <br />
        Cantidad: {transaction.quantity_product}
        <br />
        Método de Pago: {transaction.payment_method}
        <br />
        Fecha: {formatDate(transaction.inserted_date)}
        <hr />
      </p>
    </div>
  ));
  return (
    <div>
      <div className="container-card-stats">
        <div className="card card-chart-admin">
          <div className="container-inside-chart-admin d-grid">
            <h3>Total facturado</h3>
            <p className="p-text-white">$ {TotalCatalog}</p>
            <PaymentsIcon className="icon-chart-admin" />
          </div>
          <div className="inside-bot-chart-admin">
            <p>Total Facturado</p>
          </div>
        </div>
      </div>
      <div className="container-card-stats">
        <div className="card card-chart-admin">
          <div className="container-inside-chart-admin d-grid text-white">
            <h3 className="text-white">Producto más popular</h3>
            <p>
              Producto: {DataCatalog.title}
              <br />
              Total facturado: ${DataCatalog.total}
              <br />
              Cantidad: {DataCatalog.quantity}
              <hr />
            </p>
            <ContentCutIcon className="icon-chart-admin" />
          </div>
          <div className="inside-bot-chart-admin">
            <p>Producto más popular</p>
          </div>
        </div>
      </div>
      <div className="container-card-stats">
        <div className="card card-chart-admin">
          <div className="container-inside-chart-admin d-grid">
            <h3>Detalles de venta de productos</h3>
            {transactionsList}
            <SignalCellularAltIcon className="icon-chart-admin" />
          </div>
          <div className="inside-bot-chart-admin">
            <p>Detalle de ventas</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardStatsCatalog;

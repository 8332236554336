import React from 'react';
import "../../styles/Buttons.css"

const FlechaAtras = () => {
  const handleGoBack = () => {
    window.history.back(); // Redirige a la página anterior en la historia del navegador
  };

  return (
    <div className='content'>
      <div className='arrow-back'>
      <button onClick={handleGoBack}>
        {'\u2190'}
      </button>
      </div>
    </div>
  );
};

export default FlechaAtras;
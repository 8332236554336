import React from "react";
import { useForm } from "react-hook-form";

const FormFilterDate = ({ handleInfo }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    handleInfo(data);
  };

  return (
    <>
      <div className="container bg-white rounded p-4 pt-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row d-flex">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="start_date">FECHA DESDE</label>
              <input
                type="date"
                name="date_from"
                id="date_from"
                className="form-control"
                {...register("date_from", {
                  required: true,
                  message: "Campo obligatorio",
                })}
              />
              {errors.date_from && (
                <p className="text-danger">{errors.date_from.message}</p>
              )}
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="end_date">FECHA HASTA</label>
              <input
                type="date"
                name="date_to"
                id="date_to"
                className="form-control"
                {...register("date_to", {
                  required: true,
                  message: "Campo obligatorio",
                })}
              />
              {errors.date_to && (
                <p className="text-danger">{errors.date_to.message}</p>
              )}
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
              <div className="row ps-3 pe-3">
                <button className="btn btn-primary mt-4">BUSCAR</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default FormFilterDate;

import React from "react";
import { useForm } from "react-hook-form";
import "../../styles/Forms.css"

const FormSearchDate = ({ onSearch }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    onSearch(data);
  };

  return (
    <>
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row d-flex">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <label htmlFor="date" className="label-text-white">SELECCIONAR FECHA</label>
              <input
                type="date"
                name="date"
                id="date"
                className="form-control form-date"
                {...register("date", {
                  required: true,
                  message: "Campo obligatorio",
                })}
              />
              {errors.date && (
                <p className="text-danger">{errors.date.message}</p>
              )}
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <div className="row ps-3 pe-3">
                <button className=" mt-4 d-flex justify-content-center align-items-center btn-date">
                  BUSCAR TURNOS
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default FormSearchDate;

import React, { useState, useEffect } from "react";
import useSessionCheck from "../../customHooks/useSessionCheck";
import { useSelector } from "react-redux";
import instance from "../../connection_api";
import { useNavigate } from "react-router-dom";
import SidebarOwner from "../../components/barber-owner/SidebarOwner";
import { swalAlertDanger } from "../../components/common/Alerts";
import FormFilterDate from "../../components/commonForms/FormFilterDate";
import CardStatsCatalog from "../../components/barber-owner/CardStatsCatalog";

const BarberAdminBox = () => {
  useSessionCheck();
  const dataUser = useSelector((state) => state.user.dataUser);
  const [dataBarberShop, setDataBarberShop] = useState([]);
  const navigate = useNavigate();
  // Content Cards
  const [Transaction, setTransaction] = useState([]);
  const [DataCatalog, setDataCatalog] = useState([]);
  const [TotalCatalog, setTotalCatalog] = useState([]);

  //SelectDate
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [showTitleDate, setShowTitleDate] = useState(false);

  useEffect(() => {
    instance
      .get(`/data_barbershop_owner/${dataUser.id_barbershop_owner}`)
      .then((response) => {
        setDataBarberShop(response.data.dataBarberShop);
      })
      .catch((error) => {
        swalAlertDanger(
          "ERROR AL OBTENER LOS DATOS",
          "Intente nuevamente más tarde",
          () => {}
        );
      });
  }, [dataUser]);

  const handleInfo = async (date) => {
    setSelectedDateFrom(date.date_from);
    setSelectedDateTo(date.date_to);
    setShowTitleDate(true);
    const data = {
      date_from: date.date_from,
      date_to: date.date_to,
    };

    instance
      .post("/barbermpa/barbershop/get_transactions_products_barbershop", {
        data: data,
      })
      .then((res) => {
        setTransaction(res.data.transactions);
        setDataCatalog(res.data.mostSoldProduct)
        setTotalCatalog(res.data.totalBilling)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <div>
        <SidebarOwner dataBarberShop={dataBarberShop} />
      </div>
      <div className="mt-5 container">
        <FormFilterDate handleInfo={handleInfo} />
        <div>
          {showTitleDate ? (
            <h3 className="h2-tittle-white mt-3">
              Facturación desde {selectedDateFrom} hasta {selectedDateTo}
            </h3>
          ) : (
            ""
          )}
        </div>
        <div>
          {Transaction && Transaction.length > 0 ? (
            <CardStatsCatalog 
            Transaction={Transaction}
            DataCatalog={DataCatalog}
            TotalCatalog={TotalCatalog} />
          ) : (
            <>
              <h3 className="h2-tittle-white mt-3">NO SE ENCONTRARON DATOS</h3>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BarberAdminBox;

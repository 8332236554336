import axios from "axios";

//Se debe llamar a esta instancia en cada peticion a la api, solamente invocando a la ruta relativa de la misma
//E.g => instance.get('/ruta_api')

const instance = axios.create({ 
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_URL
})

export default instance
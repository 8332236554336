import React, { useState, useEffect } from "react";
import Baner from "../../assets/img/BanerGrid.svg";
import "../../styles/BanerGrid.css";

const BannerGrid = () => {
  const phrases = [
    "Tu agenda se llena 24/7",
    "Ahorrá más de 120 horas al año con BarberMap",
    "Tu facturación aumenta gracias a la automatización y control",
    "Evita perdidas con el control de finanzas de BarberMap",
  ];

  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        setFade(true);
      }, 1000);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="container-banner-grid noselect">
      <div className="banner-grid">
        <img src={Baner} alt="Banner" />
        <div className={`banner-text ${fade ? "fade-in" : "fade-out"}`}>
          <p>{phrases[currentPhraseIndex]}</p>
        </div>
      </div>
    </div>
  );
};

export default BannerGrid;

import React from "react";
import Navbar from "../../components/navbar/NavbarManagement";
import BannerFunctions from "../../components/companies/BannerFunctions";
import Footer from "../../components/footer/Footer";

const Functions = () => {
  return (
    <>
      <Navbar />
      <BannerFunctions />
      <Footer />
    </>
  );
};

export default Functions;

import React, { useEffect, useState } from "react";
import instance from "../../connection_api";
import {
  swalAlertDanger,
  swalAlertSuccess,
  swalAlertTimer,
} from "../common/Alerts";
import useSessionCheck from "../../customHooks/useSessionCheck";
import { useSelector } from "react-redux";

const FormFastShift = ({ id_barbershop, services, paymentMethods }) => {
  useSessionCheck();
  const dataUser = useSelector((state) => state.user.dataUser);

  const [formData, setFormData] = useState({
    id_service: "",
    id_payment_method: "",
    id_barber: "",
  });
  const [barbers, setBarbers] = useState([]);

  useEffect(() => {
    instance
      .get("/barbershop_get_barbers")
      .then((res) => {
        setBarbers(res.data.barbers);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [services, paymentMethods]);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let data;
    if (formData.id_barber !== "") {
      data = {
        id_barbershop,
        id_barber: formData.id_barber,
        id_service: formData.id_service,
        id_payment_method: formData.id_payment_method,
      };
    } else {
      data = {
        id_barbershop,
        id_barber: dataUser.id_user,
        id_service: formData.id_service,
        id_payment_method: formData.id_payment_method,
      };
    }

    await instance
      .post("/barbermap/barber/create_fast_shift", data)
      .then((res) => {
        if (res.status === 200) {
          swalAlertSuccess("Turno rápido registrado exitosamente", "", () => {
            window.location.reload();
          });
        }
      })
      .catch((error) => {
        swalAlertDanger(
          "Error al guardar el turno",
          "Si el problema persiste, comuníquese con un administrador",
          () => {
            window.location.reload();
          }
        );
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {dataUser.user_rol === 1 ? (
          <div className="row">
            <div className="col-12 mt-4">
              <label htmlFor="id_barber">Barbero</label>
              <select
                id="id_barber"
                name="id_barber"
                className="form-control"
                required
                onChange={handleInputChange}
                value={formData.id_barber}
              >
                <option value="">Selecciona barbero</option>
                {barbers.map((barber) => (
                  <option key={barber.id_user} value={barber.id_user}>
                    {barber.name} {barber.lastname}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="row">
          <div className="mt-2 col-6">
            <label htmlFor="id_payment_method">Método de pago</label>
            <select
              id="id_payment_method"
              name="id_payment_method"
              className="form-control"
              required
              onChange={handleInputChange}
              value={formData.id_payment_method}
            >
              <option value="">Selecciona método de pago utilizado</option>
              {paymentMethods.map((method) => (
                <option
                  key={method.id_payment_method}
                  value={method.id_payment_method}
                >
                  {method.payment_method_description}
                </option>
              ))}
            </select>
          </div>
          <div className="mt-2 col-6">
            <label htmlFor="id_service">Servicio realizado</label>
            <select
              id="id_service"
              name="id_service"
              className="form-control"
              required
              onChange={handleInputChange}
              value={formData.id_service}
            >
              <option value="">Selecciona un servicio</option>
              {services.map((service) => (
                <option key={service.id_service} value={service.id_service}>
                  {service.service_description} (${service.service_price})
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row mt-5">
          <button type="submit" className="btn btn-success">
            GUARDAR TURNO
          </button>
        </div>
      </form>
    </>
  );
};

export default FormFastShift;

import React, { useState } from "react";
import "../../../../../styles/SidebarAdmin.css"


const RegisterBarber = ({ barberShops, cities, sendData }) => {
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedBarbershop, setSelectedBarbershop] = useState("");
  const [registerValues, setRegisterValues] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    registerEmail: "",
    registerPassword: "",
  });

  const handleChangeRegister = (event) => {
    const { name, value } = event.target;
    setRegisterValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmitRegister = (event) => {
    event.preventDefault();
    const registerData = {
      name: registerValues.firstName,
      lastname: registerValues.lastName,
      cellphone: registerValues.phone,
      email: registerValues.registerEmail,
      password: registerValues.registerPassword,
      city: selectedCity,
      id_barbershop: selectedBarbershop,
    };
    sendData(registerData);
  };

  return (
    <div className="container background-sidebar">
      <div className="card card-sidebar pb-5 pt-5 pt-sm-2 text-center">
        <div className="title-register mt-5 mb-3 pb-3">REGISTRAR BARBERO</div>
        <div className="form-group-sidebar">
          <input
            type="text"
            className="form-style-sidebar"
            placeholder="Nombre"
            name="firstName"
            onChange={handleChangeRegister}
          />
        </div>
        <div className="form-group-sidebar mt-2">
          <input
            type="text"
            className="form-style-sidebar"
            placeholder="Apellido"
            name="lastName"
            onChange={handleChangeRegister}
          />
        </div>
        <div className="form-group-sidebar mt-2">
          <input
            type="text"
            className="form-style-sidebar"
            placeholder="Teléfono"
            name="phone"
            onChange={handleChangeRegister}
          />
        </div>

        <div className="form-group-sidebar mt-2">
          <select
            className="form-style-sidebar"
            name="id_barbershop"
            id="id_barbershop"
            required
            onChange={(event) => setSelectedBarbershop(event.target.value)}
          >
            <option value="">Seleccionar barbería</option>
            {barberShops.map((barberShop) => (
              <option
                key={barberShop.id_barbershop}
                value={barberShop.id_barbershop}
              >
                {barberShop.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group-sidebar mt-2">
          <select
            className="form-style-sidebar"
            name="city"
            value={selectedCity}
            onChange={(event) => setSelectedCity(event.target.value)}
          >
            <option value="" disabled>
              Seleccionar ciudad
            </option>
            {cities.map((city) => (
              <option key={city.id_city} value={city.id_city}>
                {city.city_description}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group-sidebar mt-2">
          <input
            type="email"
            className="form-style-sidebar"
            placeholder="Correo Electrónico"
            name="registerEmail"
            onChange={handleChangeRegister}
          />
        </div>

        <div className="form-group-sidebar mt-2">
          <input
            type="password"
            className="form-style-sidebar"
            placeholder="Contraseña"
            name="registerPassword"
            onChange={handleChangeRegister}
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary2 mt-4"
          onClick={handleSubmitRegister}
        >
          REGISTRAR
        </button>
      </div>
    </div>
  );
};

export default RegisterBarber;

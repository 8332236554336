import React, { useEffect, useState } from "react";
import Logo4 from "../../assets/img/Logo4.png";
import instance from "../../connection_api";
import Favoritos from "../../components/nav-bottom/Favorites";
import NavbarTop from "../../components/nav-bottom/NavTop";
import "../../styles/MainPage.css";
import useSessionCheck from "../../customHooks/useSessionCheck";
import { useNavigate } from "react-router-dom";
import {
  swalAlertTimer,
  swalConfirmationAlert,
} from "../../components/common/Alerts";
import { useSelector } from "react-redux/es/hooks/useSelector";
const Favorites = () => {
  // useSessionCheck();
  const dataUser = useSelector((state) => state.user.dataUser);
  const [barberShops, setBarberShops] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userFavorites, setUserFavorites] = useState([]);
  // const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  if (!dataUser) {
    // swalAlertTimer("SESION EXPIRADA, POR FAVOR VUELVA A INICIAR SESION", 3000);
    // navigate("/login");
    swalConfirmationAlert(
      "Para visualizar tus barberías favoritas deberás iniciar sesión",
      "¿Desea iniciar sesión?",
      () => {
        navigate("/login");
      }
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get("/barbermap_get_barbershops");
        const responseFavorites = await instance.get(
          "/barbermap_get_favorites_user"
        );

        setBarberShops(response.data.barberShops);

        // Establece userFavorites una vez que los datos lleguen
        const favoritesData = responseFavorites.data;
        setUserFavorites(favoritesData);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    if (barberShops.length === 0) {
      fetchData();
    }
  }, [barberShops]);

  // Mapea todas las barberías y establece isFavorite en true
  const allBarberShopsWithFavorites = barberShops.map((barberShop) => ({
    ...barberShop,
    isFavorite: true,
  }));

  const filteredBarberShops = allBarberShopsWithFavorites.filter((barberShop) =>
    userFavorites.some(
      (favorite) => favorite.id_barbershop === barberShop.id_barbershop
    )
  );

  if (isLoading) {
    return (
      <>
        <div className="container bg-light text-center p-4 mt-5 rounded">
          <div className="loading-container">
            <img src={Logo4} alt="Loading" className="loading-image" />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="container Navtop">
        <NavbarTop />
      </div>
      <div className="">
        <Favoritos barbershops={filteredBarberShops} />
      </div>
    </>
  );
};

export default Favorites;

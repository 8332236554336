import React, { useState } from "react";
import { useForm } from "react-hook-form";

const FormRegistrationBarbershop = ({ handleCreate, cities }) => {
  const [logo, setLogo] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    setLogo(file);
  };

  const onSubmit = (data) => {
    handleCreate(data, logo);
  };

  return (
    <>
      <div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="row"
          enctype="multipart/form-data"
        >
          <h5 className="text-center">REGISTRO DEL ADMINISTRADOR</h5>
          <div className="col-6">
            <label htmlFor="firstName" className="form-label">
              Nombres
            </label>
            <input
              type="text"
              name="firstName"
              id="firstName"
              className={
                errors?.firstName ? "is-invalid form-control" : "form-control form-register"
              }
              {...register("firstName", {
                required: "Campo requerido",
              })}
            />
            <p className="text-danger">{errors?.firstName?.message}</p>
          </div>
          <div className="col-6">
            <label htmlFor="lastName" className="form-label">
              Apellido
            </label>
            <input
              type="text"
              name="lastName"
              id="lastName"
              className={
                errors?.lastName ? "is-invalid form-control" : "form-control form-register"
              }
              {...register("lastName", {
                required: "Campo requerido",
              })}
            />
            <p className="text-danger">{errors?.lastName?.message}</p>
          </div>
          <div className="col-6">
            <label htmlFor="cellphone" className="form-label">
              Celular
            </label>
            <input
              type="text"
              name="cellphone"
              id="cellphone"
              className={
                errors?.cellphone ? "is-invalid form-control" : "form-control form-register"
              }
              {...register("cellphone", {
                required: "Campo requerido",
              })}
            />
            <p className="text-danger">{errors?.cellphone?.message}</p>
          </div>

          <div className="col-6">
            <label htmlFor="cellphone" className="form-label">
              Provincia
            </label>
            <select
              name="city"
              className={
                errors?.city ? "is-invalid form-control" : "form-control form-register"
              }
              {...register("city", {
                required: "Campo requerido",
              })}
            >
              <option value="" disabled>
                Seleccionar ciudad
              </option>
              {cities.map((city) => (
                <option key={city.id_city} value={city.id_city}>
                  {city.city_description}
                </option>
              ))}
            </select>
          </div>

          <div className="col-6">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className={
                errors?.email ? "is-invalid form-control" : "form-control form-register"
              }
              {...register("email", {
                required: "Campo requerido",
              })}
            />
            <p className="text-danger">{errors?.email?.message}</p>
          </div>

          <div className="col-6">
            <label htmlFor="password" className="form-label">
              Contraseña
            </label>
            <input
              type="password"
              name="password"
              id="password"
              className={
                errors?.password ? "is-invalid form-control" : "form-control form-register"
              }
              {...register("password", {
                required: "Campo requerido",
              })}
            />
            <p className="text-danger">{errors?.password?.message}</p>
          </div>
          <h5 className="text-center mt-5">CREACIÓN DE LA BARBERÍA</h5>
          <div className="col-6">
            <label htmlFor="name" className="form-label">
              Nombre de la barbería
            </label>
            <input
              type="text"
              name="name"
              id="name"
              className={
                errors?.name ? "is-invalid form-control" : "form-control form-register"
              }
              {...register("name", {
                required: "Campo requerido",
              })}
            />
            <p className="text-danger">{errors?.name?.message}</p>
          </div>
          <div className="col-6">
            <label htmlFor="address" className="form-label">
              Dirección
            </label>
            <input
              type="text"
              name="address"
              id="address"
              className={
                errors?.address ? "is-invalid form-control" : "form-control form-register"
              }
              {...register("address", {
                required: "Campo requerido",
              })}
            />
            <p className="text-danger">{errors?.address?.message}</p>
          </div>
          <div className="col-6">
            <label htmlFor="instagram" className="form-label">
              Instagram
            </label>
            <input
              type="text"
              name="instagram"
              id="instagram"
              className={
                errors?.instagram ? "is-invalid form-control" : "form-control form-register"
              }
              {...register("instagram", {
                required: "Campo requerido",
              })}
            />
            <p className="text-danger">{errors?.instagram?.message}</p>
          </div>
          <div className="col-6">
            <label htmlFor="phone" className="form-label">
              Teléfono
            </label>
            <input
              type="text"
              name="phone"
              id="phone"
              className={
                errors?.phone ? "is-invalid form-control" : "form-control form-register"
              }
              {...register("phone", {
                required: "Campo requerido",
              })}
            />
            <p className="text-danger">{errors?.phone?.message}</p>
          </div>
          <div className="col-6">
            <label htmlFor="description" className="form-label">
              Descripción (opcional)
            </label>
            <input

              type="text"
              name="description"
              id="description"
              className={
                errors?.description ? "is-invalid form-control" : "form-control form-register"
              }
              {...register("description", {
                required: false,
              })}
            />
            <p className="text-danger">{errors?.description?.message}</p>
          </div>
          <div className="col-6 container logo-input-container text-center">
            <label htmlFor="logo" className="form-label mt-5">
              Logo (opcional)
            </label>
            <input
              type="file"
              name="file"
              id="file"
              onChange={handleLogoChange}
              className="form-control form-register logo-input"
              accept=".jpg, .jpeg, .png"
            />
            <p className="text-danger">{errors?.logo?.message}</p>
          </div>

          <button className="btn btn-success mt-4 col-12 mb-5">REGISTRAR BARBERÍA</button>
        </form>
      </div>
    </>
  );
};

export default FormRegistrationBarbershop;

import React, { useState } from "react";

const FormEditCoordinates = ({ barbershop, changeData }) => {
  const [editedBarbershop, setEditedBarbershop] = useState({ ...barbershop });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedBarbershop({ ...editedBarbershop, [name]: value });
  };

  return (
    <div>
      <div className="form-group">
        <label>Barberia</label>
        <input
          className="form-control-admin"
          type="text"
          name="name"
          defaultValue={editedBarbershop.name}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label>Mapa: Latitud</label>
        <input
          className="form-control-admin"
          type="text"
          name="latitude"
          defaultValue={editedBarbershop.latitude}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label>Mapa: Longitud</label>
        <input
          className="form-control-admin"
          type="text"
          name="longitude"
          defaultValue={editedBarbershop.longitude}
          onChange={handleInputChange}
        />
      </div>
      <button
        className="btn btn-success mt-4 col-12"
        onClick={() => {
          changeData(editedBarbershop);
        }}
      >
        Guardar cambios
      </button>
    </div>
  );
};

export default FormEditCoordinates;

import React from "react";
import "../../styles/Buttons.css";
import { ArrowRightCircle } from "react-bootstrap-icons";
const ActionButton = () => {
  return (
    <div>
      <section className="d-flex justify-content-center background-body">
        <button className="btn-contact">
          ADQUIRIR SOFTWARE
          <ArrowRightCircle size={25} className="ms-4" />
        </button>
      </section>
    </div>
  );
};

export default ActionButton;

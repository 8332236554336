import React, { useState } from "react";

const FormGeneratePassword = ({ handleChangePass, email }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPassword === confirmPassword) {
      // Las contraseñas coinciden, enviar los datos al manejador
      handleChangePass(email, newPassword);
    } else {
      // Las contraseñas no coinciden, mostrar un mensaje de error
      setPasswordsMatch(false);
    }
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Email:</label>
          <input type="text" className="form-control" value={email} disabled />
        </div>
        <div className="form-group">
          <label>Ingresar nueva contraseña:</label>
          <input
            type="password"
            className="form-control"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Confirmar nueva contraseña:</label>
          <input
            type="password"
            className={`form-control ${!passwordsMatch ? "is-invalid" : ""}`}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          {!passwordsMatch && (
            <div className="invalid-feedback">
              Las contraseñas no coinciden.
            </div>
          )}
        </div>
        <div className="cont-btn-modals">
          <button type="submit" className="btn btn-primary btn-modal">
            Confirmar
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormGeneratePassword;

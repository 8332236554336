import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logo2 from "../../assets/img/Logo3.png";
import "../../styles/NavbarStyle.css";
import { MenuItems, MenuItemsMobil } from "./MenuItems";
import Logo4 from "../../assets/img/Logo4.png";

class NavbarMain extends Component {
  state = {
    clicked: false,
    windowWidth: window.innerWidth,
    scrolled: false,
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleWindowSizeChange = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  handleScroll = () => {
    const scrolled = window.scrollY > 0;
    if (scrolled !== this.state.scrolled) {
      this.setState({ scrolled });
    }
  };

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  render() {
    const { clicked, windowWidth, scrolled } = this.state;
    const isMobile = windowWidth <= 768;

    return (
      <nav className={`NavbarItems${scrolled ? " scrolled" : ""} noselect`}>
        <div className="logo">
          <Link to="/">
            <img src={Logo2} alt="" className="image-logo" />
          </Link>
        </div>
        <div className="menu-icons" onClick={this.handleClick}>
          <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
        <ul className={clicked ? "nav-menu active" : "nav-menu"}>
          {isMobile
            ? MenuItemsMobil.map((item, index) => (
                <li className="item-navbar" key={index}>
                  <Link className={item.cName} to={item.url}>
                    {item.title === "Inicio" && <i className="fas fa-home"></i>}
                    {item.title === "Contacto" && <i className="fas fa-envelope"></i>}
                    {item.title === "Guia de uso" && <i className="fas fa-book"></i>}
                    {item.title === "Software para barberías" && <i className="fas fa-cut"></i>}
                    {item.title}
                  </Link>
                </li>
              ))
            : MenuItems.map((item, index) => (
                <li className="item-navbar" key={index}>
                  <Link className={item.cName} to={item.url}>
                    {item.title === "Software para barberías" && <i className="fas fa-cut"></i>}
                    {item.title}
                  </Link>
                </li>
              ))}
          <hr className="divider" />
          <Link to="/login" className="iniciar-sesion">
            <button>
              <i className="fas fa-door-open"></i>
              <span>INICIAR SESION</span>
            </button>
            <img src={Logo4} className="logo-bottom-sidebar" alt="barber map"></img>
          </Link>
        </ul>
      </nav>
    );
  }
}

export default NavbarMain;

import React from "react";
import Logo from "../../assets/img/barber3.png";
import { useNavigate } from "react-router-dom";
import "../../styles/ViewShifts.css";
import CollectionsIcon from "@mui/icons-material/Collections";

const BarberCard = ({ barbers }) => {
  const navigate = useNavigate();

  const handleViewShiftBarber = (id_user, id_barbershop) => {
    navigate(`/barbershop/barber/${id_user}/${id_barbershop}`);
  };

  return (
    <div className="container barbershop-detail">
      {barbers.map((barber, index) => (
        <div className="card card-barber" key={index}>
          <div className="logo-and-details">
            <img className="logo-owner" src={Logo} alt="Logo" />
            <div className="barber-owner-details">
              <h2>Profesional:</h2>
              <h2>
                {barber.name} {barber.lastname}
              </h2>
            </div>
          </div>
          <nav className="buttons-avaible">
            <button
              className="view-turns"
              onClick={() =>
                handleViewShiftBarber(barber.id_user, barber.id_barbershop)
              }
            >
              Ver turnos
            </button>
            <button
              className="btn btn-dark ms-2"
              onClick={() => {
                navigate(
                  `/barbermap/barbershop/gallery/barber/${barber.id_user}`
                );
              }}
            >
              <CollectionsIcon />
            </button>
          </nav>
        </div>
      ))}
    </div>
  );
};

export default BarberCard;

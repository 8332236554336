import React, { useState, useEffect } from "react";
import useSessionCheck from "../../customHooks/useSessionCheck";
import { useSelector } from "react-redux";
import instance from "../../connection_api";
import { useNavigate } from "react-router-dom";
import SidebarOwner from "../../components/barber-owner/SidebarOwner";
// import FormSearchDate from "../../components/commonForms/FormSearchDate";
// import { formatDate, formatTime } from "../../controllers/datetime";
import {
  swalAlertDanger,
} from "../../components/common/Alerts";
import TableShifts from "../TableShifts";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import ModalWindow from "../../components/common/ModalWindow";
import FormFastShift from "../../components/barber/FormFastShift";

const BarberOwnerMyShifts = () => {
  useSessionCheck();
  const dataUser = useSelector((state) => state.user.dataUser);
  const [dataBarberShop, setDataBarberShop] = useState([]);
  const [nextShifts, setNextShifts] = useState([]);
  const [services, setServices] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [barbershopMember, setBarbershopMember] = useState(null);
  const [modalFastShift, setModalFastShift] = useState(false);

  const [shifts, setShifts] = useState([]);
  const [isOpenShifts, setOpenShifts] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    instance
      .get(`/data_barbershop_owner/${dataUser.id_barbershop_owner}`)
      .then((response) => {
        setDataBarberShop(response.data.dataBarberShop);
      })
      .catch((error) => {
        swalAlertDanger(
          "ERROR AL OBTENER LOS DATOS",
          "Intente nuevamente más tarde",
          () => {}
        );
      });

    instance
      .get("/barbermap/barber/shiftBoard")
      .then((res) => {
        setNextShifts(res.data.nextShifts);
      })
      .catch((error) => {
        console.log(error);
        swalAlertDanger(
          "Error al obtener los datos",
          "Si el problema persiste, comuníquese con soporte técnico",
          () => {
            navigate("/login");
          }
        );
      });

    instance
      .get("/barbermap/barber/shiftBoard")
      .then((res) => {
        if (res.status === 200) {
          setNextShifts(res.data.nextShifts);
        }
      })
      .catch((error) => {
        console.log(error);
        swalAlertDanger(
          "Error al obtener los datos",
          "Si el problema persiste, comuníquese con soporte técnico",
          () => {
            navigate("/login");
          }
        );
      });
    instance
      .get("/barbermap/barber/barbershop_member")
      .then((res) => {
        if (res.status === 200) {
          setBarbershopMember(res.data.id_barbershop[0].id_barbershop);
        }
      })
      .catch((error) => {
        swalAlertDanger(
          "Error al obtener los datos",
          "Si el problema persiste, comuníquese con soporte técnico",
          () => {
            navigate("/login");
          }
        );
      });
    instance
      .get("/barbermap/barbershop/get_payments_methods")
      .then((res) => {
        if (res.status === 200) {
          setPaymentMethods(res.data.methods);
        }
      })
      .catch((error) => {
        console.log(error);
        swalAlertDanger(
          "Error al obtener los datos",
          "Si el problema persiste, comuníquese con soporte técnico",
          () => {
            navigate("/login");
          }
        );
      });
  }, [dataUser]);

  useEffect(() => {
    if (barbershopMember !== null) {
      instance
        .post("/barbermap_barbershop_get_services_by_id", {
          data: barbershopMember,
        })
        .then((res) => {
          if (res.status === 200) {
            setServices(res.data.services);
          }
        })
        .catch((error) => {
          swalAlertDanger(
            "Error al obtener los datos",
            "Si el problema persiste, comuníquese con soporte técnico",
            () => {
              navigate("/login");
            }
          );
        });
    }
  }, [barbershopMember]);

  const handleSearch = (date) => {
    const data = {
      date,
      barber: dataUser.id_user,
    };
    instance
      .post("/get_barber_historial_shifts", { data: data })
      .then((res) => {
        if (res.status === 200) {
          setShifts(res.data.shifts);
          setOpenShifts(true);
        }
      })
      .catch((error) => {
        swalAlertDanger(
          "Error al obtener los turnos",
          "Intentelo nuevamente más tarde",
          () => {
            navigate("/user-management");
          }
        );
      });
  };

  return (
    <div>
      <div>
        <SidebarOwner dataBarberShop={dataBarberShop} />
      </div>
      <div className="container">
        <div className="mt-3 text-center">
          <div className="row">
            <div className="col-6">
              <h1>Turnos en los proximos 5 dias</h1>
            </div>
            <div className="col-6">
              <button
                className="btn btn-primary"
                onClick={() => {
                  setModalFastShift(true);
                }}
              >
                <ElectricBoltIcon /> TURNO RÁPIDO
              </button>
            </div>
          </div>
          <TableShifts nextShifts={nextShifts} />
        </div>
      </div>
      <ModalWindow
        title={"Carga de turno rápida"}
        body={
          <FormFastShift
            id_barbershop={barbershopMember}
            id_barber={dataUser.id_user}
            services={services}
            paymentMethods={paymentMethods}
          />
        }
        modalOpen={modalFastShift}
        setModalOpen={setModalFastShift}
        footer={
          "Se registrará el servicio realizado en el historial sin necesidad de reserva del cliente"
        }
      />
    </div>
  );
};

export default BarberOwnerMyShifts;

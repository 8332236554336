import React, { useState } from "react";
import ModalWindow from "../common/ModalWindow";
import FormEditProduct from "./FormEditProduct";
import ProductCard from "../catalog/ProductCard";

const TableProductsCatalog = ({
  products,
  handleEdit,
  handleDelete,
  categories,
}) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showModalEdit, setShowModalEdit] = useState(false);
  return (
    <>
      <div className="row product-container">
        {products.map((product) => (
          <ProductCard
            key={product.id_product}
            product={product}
            handleEdit={() => {
              setSelectedProduct(product);
              setShowModalEdit(true);
            }}
            handleDelete={handleDelete}
          />
        ))}
      </div>
      <ModalWindow
        title={"Edición de producto"}
        body={
          <FormEditProduct
            product={selectedProduct}
            categories={categories}
            handleEdit={handleEdit}
          />
        }
        modalOpen={showModalEdit}
        setModalOpen={setShowModalEdit}
        footer={"Los cambios impactarán de manera inmediata en el catálogo."}
      />
    </>
  );
};

export default TableProductsCatalog;

import React from "react";
import { formatDate } from "../../controllers/datetime";

const BarberShopComments = ({ comments }) => {
  if (comments.length === 0) {
    return (
      <div className="card">
        <div className="card-body">
          <p className="card-text">
            La barbería no tiene comentarios por el momento... ¡sé el primero en comentar!
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <h4 className="title-comment-h4">Ultimos comentarios</h4>
      {comments.map((comment, index) => (
        <div className="card card-comments" key={index}>
          <div className="card-body">
            <div className="comment-header">
              <h5>{comment.name} {comment.lastname}</h5>
              <h6>{formatDate(comment.inserted_date)}</h6>
              <div>{comment.type === 1 ? "👍" : "👎"}</div>
            </div>
            <p className="card-text-comment">{comment.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BarberShopComments;

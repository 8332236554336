import React from "react";
import { Modal } from "react-bootstrap";

const ModalWindow = ({ body, title, setModalOpen, modalOpen, footer, size }) => {
  return (
    <Modal
      show={modalOpen}
      onHide={() => setModalOpen(false)}
      centered
      aria-labelledby="contained-modal-title-vcenter"
      size={size ? size : "lg"}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>{footer}</Modal.Footer>
    </Modal>
  );
};

export default ModalWindow;
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ArrowDownRightCircle } from "react-bootstrap-icons";
import "../../styles/BannerStyle.css";
import Image1 from "../../assets/img/gest1.jpg";
import Image2 from "../../assets/img/gest2.jpg";
import Image3 from "../../assets/img/gest3.jpg";

const imageList = [Image1, Image2, Image3];
const transitionDuration = 3000;
const ManagementBanner = ({ plansData }) => {
  
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [nextImageIndex, setNextImageIndex] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setNextImageIndex((currentImageIndex + 1) % imageList.length);
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageList.length);
    }, transitionDuration);

    return () => {
      clearInterval(interval);
    };
  }, [currentImageIndex]);

  const handleConnectClick = () => {
    const connectSection = document.getElementById("banner-gestion");
    if (connectSection) {
      connectSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  // Calcula el precio más bajo
  const lowestPrice = Math.min(...plansData.map((plan) => plan.plan_price));

  return (
    <section className="banner" id="home">
      <Container className="container-banner noselect">
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <span className="tagline">Barberías y turnos en tu bolsillo</span>
            <h1>
              {"Sistema de Gestión de Barberías"}
              <span className="wrap"></span>
            </h1>
            <p>Unite a barbermap desde</p>
            <div className="banner-h2">
              <h2>${lowestPrice}<small>/mes</small></h2>
            </div>
            <button className="button-connect" onClick={handleConnectClick}>
              Ver beneficios <ArrowDownRightCircle size={25} />
            </button>
          </Col>
        </Row>
      </Container>
      <div className="image-transition-container">
        <div className="background-gradient"></div>
        <div className="image-wrapper">
          {imageList.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Image ${index}`}
              className="transition-image"
              style={{
                opacity:
                  index === currentImageIndex || index === nextImageIndex
                    ? 1
                    : 0,
              }}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ManagementBanner;

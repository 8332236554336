// userReducer.js
import { SET_DATA_USER, RESET_DATA_USER, UPDATE_USER_PROFILE, LOGOUT } from "./userActions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  dataUser: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA_USER:
      return {
        ...state,
        dataUser: action.payload,
      };
    case RESET_DATA_USER:
      return initialState;
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        dataUser: action.payload,
      };
    case LOGOUT:
      return initialState; // Limpiar los datos del usuario al cerrar sesión
    default:
      return state;
  }
};

const persistConfig = {
  key: "user",
  storage,
};

export default persistReducer(persistConfig, userReducer);
import React, { useEffect, useState } from "react";
import FormRegistrationBarbershop from "../../components/admin/components/pages/Barbershops/FormRegistrationBarbershop";
import instance from "../../connection_api";
import {
  swalAlertSuccess,
  swalAlertInfo,
} from "../../components/common/Alerts";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import NavbarMain from "../../components/navbar/NavbarMain";
import Footer from "../../components/footer/Footer"

const BarbershopRegistration = () => {
  const [cities, setCities] = useState([]);
  const [latitude, setLatitud] = useState("");
  const [longitude, setLongitud] = useState("");

  const apiUrl = `https://nominatim.openstreetmap.org/search`;

  useEffect(() => {
    const fetchData = async () => {
      await instance
        .get("/getCities")
        .then((res) => {
          setCities(res.data.cities);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchData();
  }, []);

  const navigate = useNavigate();

  const handleCreate = async (data, logo) => {
    let lat = 0;
    let lon = 0;

    const params = {
      q: data.address,
      format: "json",
    };
    const response = await axios.get(apiUrl, { params });
    if (response.data && response.data[0]) {
      const result = response.data[0];
      lat = parseFloat(result.lat);
      lon = parseFloat(result.lon);

      setLatitud(lat);
      setLongitud(lon);
    } else {
      swalAlertInfo(
        "No se encontraron coordenadas para la dirección indicada",
        "Verifique la dirección ingresada",
        () => {}
      );
    }

    data.latitude = latitude;
    data.longitude = longitude;

    await instance
      .post(
        "/api/barbermap/barbershop_registration_free_trial",
        { logo, data },
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.userExists);
          if (res.data.userExists) {
            swalAlertSuccess(
              "Barbería creada con éxito",
              "Se detectó un usuario registrado con el email ingresado. Inicie sesión con sus credenciales para iniciar su prueba gratuita",
              () => {
                navigate("/login");
              }
            );
          } else {
            swalAlertSuccess(
              "Barbería creada con éxito",
              "Inicie sesión con sus credenciales para iniciar su prueba gratuita de 30 días.",
              () => {
                navigate("/login");
              }
            );
          }
        } else {
          if (res.status === 203) {
            swalAlertInfo(
              "Atención",
              "Este usuario ya realizó su período de prueba gratuito. Nos pondremos en contacto con usted para gestionar su plan de Barbermap.",
              () => {}
            );
          }
        }
      });
  };

  return (
    <>
      <NavbarMain />
      <>
        <div className="container card  card-register justify-cotent-center d-flex text-light mt-5 mb-5">
        <section className="mt-5">
          <h2 className="text-center mt-5">
            REALIZA TU PRUEBA GRATUITA DURANTE 30 DIAS
          </h2>
          <h5 className="text-center mt-3">
            ¡Tu barbería online a tan solo un click!
          </h5>
          <div className="mt-5">
            <FormRegistrationBarbershop
              handleCreate={handleCreate}
              cities={cities}
            />
          </div>
        </section>
        </div>
        <Footer/>
      </>
    </>
  );
};

export default BarbershopRegistration;

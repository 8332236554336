import React, { useState } from "react";

const FormEditPlan = ({ plan, changeData }) => {
  const [editedPlan, setEditedPlan] = useState({ ...plan });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedPlan({ ...editedPlan, [name]: value });
  };
  return (
    <div>
      <div className="form-group">
        <label>Plan</label>
        <input
          className="form-control"
          type="text"
          name="plan_description"
          defaultValue={editedPlan.plan_description}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label>Cantidad máxima de barberos</label>
        <input
          className="form-control"
          type="number"
          name="max_quantity_barbers"
          defaultValue={editedPlan.max_quantity_barbers}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label>Costo mensual</label>
        <input
          className="form-control"
          type="float"
          name="plan_price"
          defaultValue={editedPlan.plan_price}
          onChange={handleInputChange}
        />
      </div>
      <button
        className="btn btn-success mt-4 col-12"
        onClick={() => {
          changeData(editedPlan);
        }}
      >
        Guardar cambios
      </button>
    </div>
  );
};

export default FormEditPlan;

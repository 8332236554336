import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes, HashRouter as BrowserRouter } from "react-router-dom";
import UserProvider from "./components/context/UserProvider";

// Rutas Generales
import Login from "./pages/Login";
import Inicio from "./pages/Inicio";
import Gestion from "./pages/ManagementPage";
import Prices from "./pages/management/Prices";
import Functions from "./pages/management/Functions";
import Tutorial from "./pages/management/Tutorial";
import ProfileBarberTutorial from "./pages/management/ProfileBarberTutorial";
import ReservationsBarberTutorial from "./pages/management/ReservationsBarberTutorial";
import TurnsBarberTutorial from "./pages/management/TurnsBarberTutorial";
import AdministratorBarberTutorial from "./pages/management/AdministratorBarberTutorial";
import ReportsBarberTutorial from "./pages/management/ReportsBarberTutorial";
import TutorialUser from "./pages/TutorialUser";
import Privacypolicy from "./pages/user/Privacypolicy";
import Ranking from "./pages/user/Ranking";
// Rutas Administrador
import LoadBarberPage from "./pages/admin/LoadBarberPage";
import Dashboard from "./pages/admin/Dashboard";
import RegisterBarberPage from "./pages/admin/RegisterBarberPage";
import ConsultPayPage from "./pages/admin/ConsultPayPage";
import ConsultFeePage from "./pages/admin/ConsultFeePage";
import ExpirationsPage from "./pages/admin/ExpirationsPage";
import ConsultUserPage from "./pages/admin/ConsultUserPage";

// Rutas BarberShop
import DashboardBarberOwner from "./pages/barber-owner/DashboardBarberOwner";
import BarberOwnerStaff from "./pages/barber-owner/BarberOwnerStaff";
import BarberShop from "./pages/barber-owner/BarberShop";
import BarberShopProfile from "./pages/barber-owner/BarberShopProfile";
import BarberAdminBox from "./pages/barber-owner/BarberAdminBox";

// Rutas Customer
import Favorites from "./pages/user/Favorites";
import Calendar from "./pages/user/CalendarUser";
import BarbersUser from "./pages/user/BarbersUser";
import MapUser from "./pages/user/MapUser";
import Profile from "./pages/Profile";
import BarberAdminShift from "./pages/barber-owner/BarberAdminShift";
import BarberShifts from "./pages/barber/BarberShifts";
import DashboardBarber from "./pages/barber/DashboardBarber";
import BarberMyShifts from "./pages/barber/BarberMyShifts";
import BarberAdminHistory from "./pages/barber-owner/BarberAdminHistory";
import BarberHistory from "./pages/barber/BarberHistory";
import ConsultPlans from "./pages/admin/ConsultPlans";
import EditBarberPage from "./pages/admin/EditBarberPage";
import ForgotPassword from "./pages/ForgotPassword";
import BarberShopServices from "./pages/barber-owner/BarberShopServices";
import BarberOwnerMyShifts from "./pages/barber-owner/BarberOwnerMyShifts";
import AdminGalleryBarberView from "./pages/barber-owner/AdminGalleryBarberView";
import AdminCatalog from "./pages/barber-owner/AdminCatalog";
import UserCatalog from "./pages/catalog/UserCatalog";
import BarberAdminBilling from "./pages/barber-owner/BarberAdminBilling";
import BarberAdminShiftFastHistory from "./pages/barber-owner/BarberAdminShiftFastHistory";
import BarberShopGuest from "./pages/barber-owner/BarberShopGuest";
import BarbershopRegistration from "./pages/admin/BarbershopRegistration";

const App = () => {
  return (
    <div className="App">
      <UserProvider>
        <BrowserRouter>
          <Routes>
            /* Rutas Generales */
            {/* Testeando login directo a barbería */}
            <Route path="/login/barbershop/:id/:name" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Inicio />} />
            {/* Agregar * pagina no encontrada NotFound */}
            <Route path="/salon-management-software" element={<Gestion />} />
            <Route path="/user-guide" element={<TutorialUser />} />
            <Route
              path="/salon-management-software-prices"
              element={<Prices />}
            />
            <Route
              path="/salon-management-software-functions"
              element={<Functions />}
            />
            <Route
              path="/salon-management-software-tutorial"
              element={<Tutorial />}
            />
            <Route
              path="/salon-management-software-tutorial-barberprofile"
              element={<ProfileBarberTutorial />}
            />
            <Route
              path="/salon-management-software-tutorial-reservations"
              element={<ReservationsBarberTutorial />}
            />
            <Route
              path="/salon-management-software-tutorial-turns"
              element={<TurnsBarberTutorial />}
            />
            <Route
              path="/salon-management-software-tutorial-administrator"
              element={<AdministratorBarberTutorial />}
            />
            <Route
              path="/salon-management-software-tutorial-reports"
              element={<ReportsBarberTutorial />}
            />
            <Route path="/privacy-policy" element={<Privacypolicy />} />
            /* Rutas recuperar contraseña */
            <Route path="/forgot-password" element={<ForgotPassword />} />
            /* Rutas Administrador */
            <Route
              path="/barbermap-admin-cba-administrator"
              element={<Dashboard />}
            />
            <Route
              path="/barbermap-admin-cba-load-business-shop"
              element={<LoadBarberPage />}
            />
            <Route
              path="/barbermap-admin-cba-consult-business"
              element={<EditBarberPage />}
            />
            <Route
              path="/barbermap-admin-cba-register-business"
              element={<RegisterBarberPage />}
            />
            <Route
              path="/barbermap-admin-cba-consult-payments"
              element={<ConsultPayPage />}
            />
            <Route
              path="/barbermap-admin-cba-consult-fee"
              element={<ConsultFeePage />}
            />
            <Route
              path="/barbermap-admin-cba-consult-maturities"
              element={<ExpirationsPage />}
            />
            <Route
              path="/barbermap-admin-cba-consult-users"
              element={<ConsultUserPage />}
            />
            <Route
              path="/barbermap-admin-cba-consult-plans"
              element={<ConsultPlans />}
            />
            <Route path="/ranking-barbershop" element={<Ranking />} />
            /* Rutas BarberShop */
            <Route path="/barbershop/:id/:name" element={<BarberShop />} />
            <Route
              path="/barbermap-barbershop-owner"
              element={<DashboardBarberOwner />}
            />
            <Route
              path="/barbermap-barber-owner-shifts"
              element={<BarberOwnerMyShifts />}
            />
            <Route
              path="/barbermap-barbershop-profile"
              element={<BarberShopProfile />}
            />
            <Route
              path="/barbermap-barbershop-owner-barbers"
              element={<BarberOwnerStaff />}
            />
            <Route
              path="/barbermap-barbershop/barber/:id_user"
              element={<BarberAdminShift />}
            />
            <Route
              path="/barbermap-barbershop-history"
              element={<BarberAdminHistory />}
            />
            <Route
              path="/barbermap-barbershop-services"
              element={<BarberShopServices />}
            />
            <Route
              path="/barbermap/barbershop/gallery/barber/:id_user"
              element={<AdminGalleryBarberView />}
            />
            <Route
              path="/barbermap-barbershop-catalog-administrator"
              element={<AdminCatalog />}
            />
            <Route
              path="/barbermap-barbershop-box"
              element={<BarberAdminBox />}
            />
            <Route
              path="/barbermap-barbershop-billing-details"
              element={<BarberAdminBilling />}
            />
            <Route
              path="/barbermap-barbershop-history-shift-fast"
              element={<BarberAdminShiftFastHistory />}
            />
            /* Rutas Barbers */
            <Route path="/barbermap-barber" element={<DashboardBarber />} />
            <Route
              path="/barbermap-barber-shifts"
              element={<BarberMyShifts />}
            />
            <Route
              path="/barbershop/barber/:id_user/:id_barbershop"
              element={<BarberShifts />}
            />
            <Route
              path="/barbermap-barber-history"
              element={<BarberHistory />}
            />
            /* Rutas Customer */
            <Route path="/user-management" element={<BarbersUser />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/favorites" element={<Favorites />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/barber-shops" element={<MapUser />} />
            <Route
              path="/barbershop/guest/:id/:name"
              element={<BarberShopGuest />}
            />
            <Route
              path="/catalog-barbershop/:id_barbershop/:name_barbershop"
              element={<UserCatalog />}
            />
            {/* Rutas para registro automatico cliente */}
            <Route
              path="/barbershop-registration"
              element={<BarbershopRegistration />}
            />
          </Routes>
        </BrowserRouter>
      </UserProvider>
    </div>
  );
};

export default App;

import React from "react";
import { Link } from "react-router-dom";
import NavbarMain from "../components/navbar/NavbarMain";
import "../styles/TutorialPage.css";
import Image from "../assets/img/barber10.png";
import Image2 from "../assets/img/barber11.png";
import Image3 from "../assets/img/barber12.png";
import Image4 from "../assets/img/barber13.png";
import Image5 from "../assets/img/barber14.png";

function TutorialUser() {
  const linkStyle = {
    color: "white",
  };
  return (
    <div className="noselect">
      <NavbarMain />
      <div className=" d-grid container-tutorial">
        <h1 className="mt-4">¿Cómo agendar un turno?</h1>
        <p>
          1. Primero deberás{" "}
          <Link to="/login" style={linkStyle}>
            iniciar sesión
          </Link>
          <img src={Image} alt="Como agendar un turno"></img>
        </p>

        <p>
          2. Busca tu barbería en el mapa o en el apartado de 'Barberías'.
          <img src={Image2} alt="Barberia en el mapa"></img>
        </p>
        <p>
          3. Dirigete a 'Ver turnos' y selecciona tu barbero de preferencia.
          <img src={Image3} alt="Mejores barberos"></img>
        </p>
        <p>
          4. Elije la fecha y horario que quieras
          <img src={Image4} alt="Como agendar un turno"></img>
        </p>
        <p>
          5. Haz click en reservar y listo!
          <img src={Image5} alt="Como agendar un turno online"></img>
        </p>
      </div>
    </div>
  );
}

export default TutorialUser;

import React, { useState } from "react";
import Button from "@mui/material/Button";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import { swalAlertInfo } from "../common/Alerts";

const FromBarbershopComments = ({ sendComment }) => {
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(""); // Almacenar la selección de positivo/negativo

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleRatingChange = (value) => {
    setRating(value);
  };

  const handleSubmit = () => {
    if (comment.trim() !== "" && comment.length <= 250 && rating !== "") {
      sendComment({ comment, rating });
      setComment("");
      setRating("");
    } else {
      swalAlertInfo(
        "COMPLETE LOS DATOS SOLICITADOS",
        "Por favor, califique y deje un comentario a la barbería.",
        () => {}
      );
    }
  };

  return (
    <div className="container card card-body">
      <div>
        <Button
          variant={rating === "1" ? "contained" : "outlined"}
          color="primary"
          startIcon={<ThumbUpIcon />}
          onClick={() => handleRatingChange("1")}
        >
        </Button>
        <Button
          variant={rating === "2" ? "contained" : "outlined"}
          color="error"
          startIcon={<ThumbDownIcon />}
          onClick={() => handleRatingChange("2")}
        >
        </Button>
      </div>
      <InputGroup className="mb-3 mt-3">
        <FormControl
          className="form-control form-comment"
          rows={4}
          placeholder="Agrega un comentario..."
          maxLength={250}
          value={comment}
          onChange={handleCommentChange}
        />
      </InputGroup>

      <Button
        className="mt-3 button-comment"
        variant="contained"
        color="success"
        onClick={handleSubmit}
      >
        Comentar
      </Button>
    </div>
  );
};

export default FromBarbershopComments;

import React, { useEffect, useState } from "react";
import SidebarOwner from "../../components/barber-owner/SidebarOwner";
import { validateRole } from "../../controllers/controllers";
import instance from "../../connection_api";
import { useSelector } from "react-redux";
import FormEditProfile from "../../components/barber-owner/FormEditProfile";
import {
  swalAlertDanger,
  swalAlertInfo,
  swalAlertSuccess,
  swalConfirmationAlert,
} from "../../components/common/Alerts";
import { useNavigate } from "react-router-dom";
import useSessionCheck from "../../customHooks/useSessionCheck";

const BarberShopProfile = () => {
  useSessionCheck();
  const [dataBarberShop, setDataBarberShop] = useState([]);
  const [dataBarbers, setDataBarbers] = useState([]);
  const dataUser = useSelector((state) => state.user.dataUser);
  const allowedRoles = [1];
  const navigate = useNavigate();

  useEffect(() => {
    if (dataUser) {
      instance
        .get(`/data_barbershop_owner/${dataUser.id_barbershop_owner}`)
        .then((response) => {
          setDataBarberShop(response.data.dataBarberShop);
          setDataBarbers(response.data.dataBarbers);
        })
        .catch((error) => {
          swalAlertDanger(
            "ERROR AL OBTENER LOS DATOS",
            "Intente nuevamente más tarde",
            () => {}
          );
        });
    }
  }, [dataUser]);

  const handleSave = (data) => {
    swalConfirmationAlert("¿Confirmar edición de datos?", "", () => {
      instance
        .post(
          "/barbermap_barbershop_edit_profile",
          { data },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            swalAlertSuccess(`${res.data.msg}`, "", () => {
              navigate("/barbermap-barbershop-owner");
            });
          } else {
            swalAlertInfo(`${res.data.msg}`);
          }
        })
        .catch((error) => {
          swalAlertDanger(`ERROR`, "Se produjo un error al cargar", () => {
            navigate("/barbermap-barbershop-owner");
          });
        });
    });
  };

  if (dataUser) {
    if (!validateRole(dataUser.user_rol, allowedRoles)) {
      return null;
    } else {
      return (
        <div>
          <SidebarOwner dataBarberShop={dataBarberShop} />
          <div className="mt-5 ms-5">
            {dataBarberShop && dataBarberShop.length > 0 ? (
              <FormEditProfile
                dataBarberShop={dataBarberShop}
                onSave={handleSave}
              />
            ) : (
              "Error al obtener los datos de la barbería"
            )}
          </div>
        </div>
      );
    }
  }
  return null;
};

export default BarberShopProfile;

import React, { useState } from "react";

const FormEditBarbershop = ({ barbershop, changeData }) => {
  const [editedBarbershop, setEditedBarbershop] = useState({ ...barbershop });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedBarbershop({ ...editedBarbershop, [name]: value });
  };

  return (
    <div>
      <div className="form-group">
        <label>Barberia</label>
        <input
          className="form-control-admin"
          type="text"
          name="name"
          defaultValue={editedBarbershop.name}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label>Dirección</label>
        <input
          className="form-control-admin"
          type="text"
          name="address"
          defaultValue={editedBarbershop.address}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label>Descripción</label>
        <input
          className="form-control-admin"
          type="text"
          name="description"
          defaultValue={editedBarbershop.description}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label>Celular</label>
        <input
          className="form-control-admin"
          type="text"
          name="cellphone"
          defaultValue={editedBarbershop.cellphone}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label>Instagram</label>
        <input
          className="form-control-admin"
          type="text"
          name="instagram"
          defaultValue={editedBarbershop.instagram}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label>Estado </label>
        <select
          name="active"
          id="active"
          className="form-control-admin"
          defaultValue={editedBarbershop.active}
          onChange={handleInputChange}
        >
          <option value={1}>ACTIVO</option>
          <option value={0}>BAJA</option>
        </select>
      </div>
      <div className="form-group">
        <label>Plan</label>
        <input
          className="form-control-admin"
          type="text"
          name="plan"
          defaultValue={editedBarbershop.plan}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label>Mapa: Latitud</label>
        <input
          className="form-control-admin"
          type="text"
          name="latitude"
          defaultValue={editedBarbershop.latitude}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label>Mapa: Longitud</label>
        <input
          className="form-control-admin"
          type="text"
          name="longitude"
          defaultValue={editedBarbershop.longitude}
          onChange={handleInputChange}
        />
      </div>
      <button
        className="btn btn-success mt-4 col-12"
        onClick={() => {
          changeData(editedBarbershop);
        }}
      >
        Guardar cambios
      </button>
    </div>
  );
};

export default FormEditBarbershop;

export const MenuItems = [

    {
        title : "Software para barberías",
        url : "/salon-management-software",
        cName : "nav-links",
    },
]

export const MenuItemsMobil = [
    {
        title : "Inicio",
        url : "/",
        cName : "nav-links-mobile",
    },
    {
        title : "Contacto",
        url : "mailto:servicebarbermap@gmail.com",
        cName : "nav-links-mobile",
    },
    {
        title : "Guia de uso",
        url : "/user-guide",
        cName : "nav-links-mobile",
    },
    {
        title : "Software para barberías",
        url : "/salon-management-software",
        cName : "nav-links-mobile",
    },
    
]
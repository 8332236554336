import React, { useState, useEffect } from "react";
import useSessionCheck from "../../customHooks/useSessionCheck";

const FormAddPhotosBarber = ({ barber, handleAddPhotos }) => {
  useSessionCheck();
  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    // This will log the updated state whenever selectedFiles changes
  }, [selectedFiles]);

  const handleFileChange = (e) => {
    const files = e.target.files;
    setSelectedFiles(files);
  };

  const handleSubmit = async () => {
    if (selectedFiles && selectedFiles.length > 0) {
      const formData = new FormData();
      formData.append("barber", barber);
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("photos", selectedFiles[i]);
      }
      try {
        await handleAddPhotos(formData);
        setSelectedFiles([]);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div className="">
      <form encType="multipart/form-data">
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="form-control"
          required
        />
        <button
          type="button"
          className="btn btn-success mt-3"
          onClick={handleSubmit}
        >
          Cargar Fotos
        </button>
      </form>
    </div>
  );
};

export default FormAddPhotosBarber;

import React, { useState } from "react";
import { swalAlertInfo } from "../common/Alerts";

const FormCatalogRegisterTransaction = ({
  products,
  handleRegisterTransaction,
  paymentMethods,
}) => {
  // Estado para almacenar la cantidad de productos vendidos
  const [productQuantities, setProductQuantities] = useState({});
  // Estado para almacenar el método de pago seleccionado
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");

  // Función para manejar el cambio en la cantidad de productos
  const handleQuantityChange = (productId, quantity) => {
    setProductQuantities({
      ...productQuantities,
      [productId]: quantity,
    });
  };

  // Función para manejar el cambio en el método de pago
  const handlePaymentMethodChange = (e) => {
    setSelectedPaymentMethod(e.target.value);
  };

  // Función para realizar el registro
  const handleRegister = () => {
    // Verificar si al menos un producto tiene cantidad mayor a 0
    const hasSelectedProducts = products.some(
      (product) => productQuantities[product.id_product] > 0
    );

    if (!hasSelectedProducts) {
      swalAlertInfo("Debe seleccionar al menos un producto", "", () => {});
      return;
    }

    // Filtrar productos con cantidad mayor a 0
    const selectedProducts = products.filter(
      (product) => productQuantities[product.id_product] > 0
    );

    // Obtener id_product, precio y cantidad de los productos seleccionados
    const productsToRegister = selectedProducts.map((product) => ({
      id_product: product.id_product,
      price_product: product.price_product,
      quantity: productQuantities[product.id_product] || 0,
    }));

    // Verificar si se seleccionó un método de pago
    if (!selectedPaymentMethod) {
      swalAlertInfo("Debe seleccionar el método de pago", "", () => {});
      return;
    }
    handleRegisterTransaction(productsToRegister, selectedPaymentMethod);
  };

  return (
    <>
      {products.map((product) => (
        <div key={product.id_product} className="row">
          <div className="col-3 text-center">
            <span className="ms-3">{product.title_product}</span>
          </div>
          <div className="col-2">
            <span className="ms-3">${product.price_product}</span>
          </div>
          <div className="col-2 col-4-sm">
            <button
              className="btn btn-danger ms-4 col-12"
              onClick={() =>
                handleQuantityChange(
                  product.id_product,
                  Math.max((productQuantities[product.id_product] || 0) - 1, 0)
                )
              }
            >
              -
            </button>
          </div>
          <div className="col-2">
            <button
              className="btn btn-success ms-2 col-12"
              onClick={() =>
                handleQuantityChange(
                  product.id_product,
                  (productQuantities[product.id_product] || 0) + 1
                )
              }
            >
              +
            </button>
          </div>
          <div className="col-3">
            <input
              type="number"
              name="quantity"
              id="quantity"
              className="col-12"
              value={productQuantities[product.id_product] || 0}
              onChange={(e) =>
                handleQuantityChange(product.id_product, e.target.value)
              }
            />
          </div>
          <hr />
        </div>
      ))}
      <div className="mt-5">
        <select
          name="paymentMethod"
          id="paymentMethod"
          className="form-control"
          value={selectedPaymentMethod}
          onChange={handlePaymentMethodChange}
        >
          <option value="" disabled>
            Seleccione un método de pago
          </option>
          {paymentMethods.map((method) => (
            <option
              key={method.id_payment_method}
              value={method.id_payment_method}
            >
              {method.payment_method_description}
            </option>
          ))}
        </select>
      </div>
      <button className="btn btn-success col-12 mt-5" onClick={handleRegister}>
        REGISTRAR
      </button>
    </>
  );
};

export default FormCatalogRegisterTransaction;

import React from "react";

const ConsultExpirations = () => {
  return (
    <div className="container">
      <h1>APARTADO CONSULTAR VENCIMIENTOS</h1>
    </div>
  );
};

export default ConsultExpirations;

import React, { useState } from "react";
import "../../styles/FormEditProfile.css"
const FormEditProfile = ({ dataBarberShop, onSave }) => {
  const [formData, setFormData] = useState({
    id_barbershop: dataBarberShop[0].id_barbershop,
    name: dataBarberShop[0].name,
    address: dataBarberShop[0].address,
    description: dataBarberShop[0].description,
    cellphone: dataBarberShop[0].cellphone,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <div className="card card-owner-barber container">
    <form onSubmit={handleSubmit} className="form-owner-barber">
      <div>
        
        <h1 className="titles-form-profileb">Perfil de la Barbería</h1>
        <label htmlFor="name" className="titles-form-profileb">Nombre:</label>
        <input
          className="form-control"
          type="text"
          id="name"
          name="name"
          defaultValue={formData.name}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="address" className="titles-form-profileb">Dirección:</label>
        <input
          className="form-control"
          type="text"
          id="address"
          name="address"
          defaultValue={formData.address}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="description" className="titles-form-profileb">Descripción:</label>
        <textarea
          className="form-control"
          id="description"
          name="description"
          defaultValue={formData.description}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="cellphone" className="titles-form-profileb">Celular:</label>
        <input
          className="form-control"
          type="text"
          id="cellphone"
          name="cellphone"
          defaultValue={formData.cellphone}
          onChange={handleChange}
        />
      </div>
      <button type="submit" className="btn btn-success btn-barber-owner">
        Guardar cambios
      </button>
    </form>
    </div>
  );
};

export default FormEditProfile;

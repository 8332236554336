import React, { useState } from "react";
import "../../../../../styles/SidebarAdmin.css";
import { FaMapMarkerAlt } from "react-icons/fa";
import axios from "axios";
import instance from "../../../../../connection_api";
import { useNavigate } from "react-router-dom";
import {
  swalAlertDanger,
  swalAlertInfo,
  swalAlertSuccess,
} from "../../../../common/Alerts";
import RegisterBarber from "../RegisterBarber/RegisterBarber";

const LoadBarberShop = ({ cities, plans }) => {
  const [logo, setLogo] = useState(null);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [cellphone, setPhone] = useState("");
  const [instagram, setInstagram] = useState("");
  const [latitude, setLatitud] = useState("");
  const [longitude, setLongitud] = useState("");

  const [selectedProvince, setSelectedProvince] = useState("");
  const [authentication, setAuthentication] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");

  const [registerValues, setRegisterValues] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    registerEmail: "",
    registerPassword: "",
  });

  const handleChangeRegister = (event) => {
    const { name, value } = event.target;
    setRegisterValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const apiUrl = `https://nominatim.openstreetmap.org/search`;
  const navigate = useNavigate();

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    setLogo(file);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleInstagramChange = (event) => {
    setInstagram(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let lat = 0;
    let lon = 0;

    try {
      const params = {
        q: address,
        format: "json",
      };
      const response = await axios.get(apiUrl, { params });
      if (response.data && response.data[0]) {
        const result = response.data[0];
        lat = parseFloat(result.lat);
        lon = parseFloat(result.lon);

        setLatitud(lat);
        setLongitud(lon);
      } else {
        swalAlertInfo(
          "No se encontraron coordenadas para la dirección indicada",
          "Verifique la dirección ingresada",
          () => {}
        );
      }
    } catch (error) {
      console.error(
        "Error al obtener las coordenadas o enviar la solicitud:",
        error
      );
      swalAlertDanger(
        "ERROR AL CARGAR",
        "Verificar el estado de la solicitud",
        () => {}
      );
    }
    // Una vez que las coordenadas se han actualizado, procede a enviar el formulario
    const dataOwner = {
      name: registerValues.firstName,
      lastname: registerValues.lastName,
      cellphone: registerValues.phone,
      email: registerValues.registerEmail,
      password: registerValues.registerPassword,
      city: selectedCity,
    };
    const formData = {
      logo,
      name,
      address,
      description,
      cellphone,
      instagram,
      latitude: lat,
      longitude: lon,
      selectedProvince,
      authentication,
      selectedPlan,
      dataOwner: dataOwner,
    };

    try {
      const apiResponse = await instance.post(
        "/send_data_bussiness",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (apiResponse.status === 200) {
        swalAlertSuccess("BARBERÍA CREADA", "¡Bienvenido a BarberMap!", () => {
          navigate("/barbermap-admin-cba-administrator");
        });
      } else {
        console.error(
          "La solicitud no fue exitosa. Código de estado:",
          apiResponse.status
        );
        swalAlertDanger(
          "ERROR AL CARGAR",
          "Verificar el estado de la solicitud",
          () => {}
        );
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
      swalAlertDanger(
        "ERROR AL CARGAR",
        "Verificar el estado de la solicitud",
        () => {}
      );
    }
  };
  return (
    <div className="container sidebar-container background-sidebar">
      <div className="text-center">
        <div>
          <br />
          <form
            className="carry-box my-form mb-5"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <br />
            <br />
            <h2>REGISTRAR BARBERIA</h2>
            <FaMapMarkerAlt
              className="icon-svg-nav"
              style={{ color: "black" }}
            />
            <div className="row mt-5 ">
              <div>
                <div className="name-evento">
                  <span>Logo de la Barberia</span>
                </div>
                <div className="form-control-input mb-3 mt-2">
                  <input
                    type="file"
                    name="file"
                    id="file"
                    className="form-control-admin"
                    onChange={handleLogoChange}
                    placeholder="Logo"
                    accept=".jpg, .jpeg, .png"
                    required
                  />
                </div>
              </div>
              <div className="">
                <div className="name-evento mt-3">
                  <span>Nombre de la barberia</span>
                </div>
                <div className="mb-3 mt-2">
                  <input
                    id="name"
                    type="text"
                    className="form-control-text"
                    value={name}
                    onChange={handleNameChange}
                    placeholder="Nombre de la barbería"
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="name-evento">
                  <span>Dirección</span>
                </div>
                <div className="mb-3 mt-2">
                  <input
                    type="text"
                    className="form-control-text"
                    value={address}
                    onChange={handleAddressChange}
                    placeholder="Dirección"
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="name-evento">
                  <span>Descripción</span>
                </div>
                <div className="mb-3 mt-2">
                  <textarea
                    className="form-control-textarea"
                    value={description}
                    onChange={handleDescriptionChange}
                    placeholder="Descripción"
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="name-evento">
                  <span>Teléfono</span>
                </div>
                <div className="mb-3 mt-2">
                  <input
                    type="text"
                    className="form-control-text"
                    value={cellphone}
                    onChange={handlePhoneChange}
                    placeholder="Celular"
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="name-evento">
                  <span>Instagram</span>
                </div>
                <div className="mb-3 mt-2">
                  <input
                    type="text"
                    className="form-control-text"
                    value={instagram}
                    onChange={handleInstagramChange}
                    placeholder="Instagram"
                    required
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="name-evento">
                  <span>Provincia</span>
                </div>
                <select
                  value={selectedProvince}
                  onChange={(event) => setSelectedProvince(event.target.value)}
                  className="form-style-sidebar"
                >
                  <option value="">Seleccionar provincia</option>
                  {cities.map((city) => (
                    <option key={city.id_city} value={city.id_city}>
                      {city.city_description}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6">
                <div className="name-evento">
                  <span>Autenticación</span>
                </div>
                <div className="mb-3 mt-2">
                  <select
                    className="form-control-text"
                    value={authentication}
                    onChange={(event) => setAuthentication(event.target.value)}
                    required
                  >
                    <option value="1">SI</option>
                    <option value="0">NO</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="form-group-sidebar mt-2">
              <div className="name-evento">
                <span>Plan</span>
              </div>
              <select
                className="form-style-sidebar"
                name="city"
                value={selectedPlan}
                onChange={(event) => setSelectedPlan(event.target.value)}
              >
                <option value="" disabled>
                  Seleccionar plan
                </option>
                {plans.map((plan) => (
                  <option key={plan.id_plan} value={plan.id_plan}>
                    {plan.plan_description} (MAX: {plan.max_quantity_barbers}{" "}
                    BARBEROS)
                  </option>
                ))}
              </select>
            </div>
            <hr />

            <div className="">
              <div className="name-evento">
                <span>Datos administrador de barbería</span>
              </div>

              <div className="form-group-sidebar mt-3">
                <input
                  type="text"
                  className="form-style-sidebar"
                  placeholder="Nombre"
                  name="firstName"
                  onChange={handleChangeRegister}
                />
              </div>
              <div className="form-group-sidebar mt-2">
                <input
                  type="text"
                  className="form-style-sidebar"
                  placeholder="Apellido"
                  name="lastName"
                  onChange={handleChangeRegister}
                />
              </div>
              <div className="form-group-sidebar mt-2">
                <input
                  type="text"
                  className="form-style-sidebar"
                  placeholder="Teléfono"
                  name="phone"
                  onChange={handleChangeRegister}
                />
              </div>

              <div className="form-group-sidebar mt-2">
                <select
                  className="form-style-sidebar"
                  name="city"
                  value={selectedCity}
                  onChange={(event) => setSelectedCity(event.target.value)}
                >
                  <option value="" disabled>
                    Seleccionar ciudad
                  </option>
                  {cities.map((city) => (
                    <option key={city.id_city} value={city.id_city}>
                      {city.city_description}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group-sidebar mt-2">
                <input
                  type="email"
                  className="form-style-sidebar"
                  placeholder="Correo Electrónico"
                  name="registerEmail"
                  onChange={handleChangeRegister}
                />
              </div>

              <div className="form-group-sidebar mt-2">
                <input
                  type="password"
                  className="form-style-sidebar"
                  placeholder="Contraseña"
                  name="registerPassword"
                  onChange={handleChangeRegister}
                />
              </div>
            </div>

            <button type="submit" className="btn btn-primary2 mt-4 mb-3">
              CARGAR BARBERIA
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoadBarberShop;

import React, { useEffect, useState } from "react";
import Sidebar from "../../components/admin/global/Sidebar";
import useSessionCheck from "../../customHooks/useSessionCheck";
import EditBarberShop from "../../components/admin/components/pages/Barbershops/EditBarberShop";
import instance from "../../connection_api";
import Logo4 from "../../assets/img/Logo4.png";
import { swalAlertDanger } from "../../components/common/Alerts";
import axios from "axios";

const EditBarberPage = () => {
  useSessionCheck();
  const [barbershops, setBarbershops] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    axios
      .all([instance.get("/get_all_barbershops"), instance.get("/getCities")])
      .then(
        axios.spread((barbershopsResponse, citiesResponse) => {
          setBarbershops(barbershopsResponse.data.barbershops);
          setCities(citiesResponse.data.cities);
          setIsLoading(false);
        })
      )
      .catch((error) => {
        swalAlertDanger(
          "ERROR AL OBTENER LOS DATOS",
          "Intente nuevamente más tarde",
          () => {}
        );
      });
  }, []);

  if (isLoading) {
    return (
      <>
        <div className="container bg-light text-center p-4 mt-5 rounded">
          <div className="loading-container">
            <img src={Logo4} alt="Loading" className="loading-image" />
          </div>
        </div>
      </>
    );
  }
  return (
    <div>
      <Sidebar />
      <div>
        <EditBarberShop barbershops={barbershops} cities={cities} />
      </div>
    </div>
  );
};

export default EditBarberPage;

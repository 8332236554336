import React, { useEffect, useState } from "react";
import SidebarBarber from "../../components/barber/SidebarBarber";
import "../../styles/SidebarBarberOwner.css";
import { validateRole } from "../../controllers/controllers";
import instance from "../../connection_api";
import { useSelector } from "react-redux";
import useSessionCheck from "../../customHooks/useSessionCheck";
// import CardTurnos from "../CardTurnos";
import TableShifts from "../TableShifts"; // Import the new component
import { swalAlertDanger, swalAlertInfo } from "../../components/common/Alerts";
import { useNavigate } from "react-router-dom";

const DashboardBarber = () => {
  useSessionCheck();
  const dataUser = useSelector((state) => state.user.dataUser);
  const [nameBarber, setNameBarber] = useState();
  const allowedRoles = [2];
  const navigate = useNavigate();

  const [shiftsUser, setShiftsUser] = useState([]);
  const [nextShifts, setNextShifts] = useState([]);

  useEffect(() => {
    if (dataUser) {
      instance
        .get(`/barbershop_databarber_by_id/${dataUser.id_user}`)
        .then((res) => {
          setNameBarber(res.data[0]);
        })
        .catch((error) => {
          swalAlertDanger(
            "Error al obtener los turnos",
            "Intentelo nuevamente más tarde",
            () => {
              navigate("/barbermap-barber");
            }
          );
        });

      instance
        .get(`/barbershop_barber_shifts_day`)
        .then((res) => {
          if (res.status === 200) {
            setShiftsUser(res.data.shiftsDay);
          } else if (res.status === 203) {
            swalAlertInfo(
              "No tienes turnos registrados para hoy",
              "¡Parece que puedes tomarte un descanso!",
              () => {}
            );
          }
        })
        .catch((error) => {
          swalAlertDanger(
            "Error en la obtención de datos",
            "Intentelo nuevamente más tarde",
            () => {}
          );
        });

      instance
        .get("/barbermap/barber/shiftBoard")
        .then((res) => {
          if (res.status === 200) {
            setNextShifts(res.data.nextShifts);
          }
        })
        .catch((error) => {
          swalAlertDanger(
            "Error al obtener los próximos turnos",
            "Intentelo nuevamente más tarde",
            () => {}
          );
        });
    }
  }, [dataUser, navigate]);

  if (dataUser) {
    if (!validateRole(dataUser.user_rol, allowedRoles)) {
      return null;
    } else {
      return (
        <div className="">
          <SidebarBarber barber={nameBarber} />
          {/* <CardTurnos reservedShifts={shiftsUser} /> */}
          <div className="container">
          <TableShifts nextShifts={nextShifts} />
          </div>
        </div>
      );
    }
  }
  return null;
};

export default DashboardBarber;

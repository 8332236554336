import React, { useEffect, useState } from "react";
import instance from "../../../connection_api";
import ChartBarbershopsByCity from "../../../components/admin/components/reports/ChartBarbershopsByCity";
import { ProgressBar } from "react-bootstrap";
import { swalAlertDanger } from "../../../components/common/Alerts";
import PodiumPopularBarbershops from "../../../components/admin/components/reports/PodiumPopularBarbershops";
import ChartUsers from "./ChartUsers";

const BarbershopsPerCity = () => {
  const [barbershopsByCity, setBarbershopByCity] = useState([]);
  const [mostPopularBarbershop, setMostPopularBarbershop] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get("/barbermap/barbershops_per_city");
        setBarbershopByCity(response.data.barbershops);

        const responseBS = await instance.get(
          "/barbermap/barbershops/most_popular"
        );
        setMostPopularBarbershop(responseBS.data.barbershops);

        setIsLoading(false);
      } catch (error) {
        swalAlertDanger("Error al obtener los datos", "", () => {});
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return (
      <>
        <div className="container bg-light p-4 mt-5 rounded">
          <div className="">Cargando...</div>
          <ProgressBar animated now={100} />
        </div>
      </>
    );
  }

  return (
    <div className="container background-sidebar">
      <div className="">
        <ChartUsers />
      </div>
      <div className="row container-city-podium">
        <div className="barber-by-city">
          <ChartBarbershopsByCity data={barbershopsByCity} />
        </div>
        <div className=" text-center podium-container">
          <h3 className="h2-tittle-white">RANKING BARBERIAS POPULARES</h3>
          <PodiumPopularBarbershops podium={mostPopularBarbershop} />
        </div>
      </div>
    </div>
  );
};

export default BarbershopsPerCity;

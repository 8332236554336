import React, { useState, useEffect } from "react";
import instance from "../../../connection_api";
import { ProgressBar } from "react-bootstrap";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import "../../../styles/Cards.css"
import StoreIcon from '@mui/icons-material/Store';
const ChartUsers = () => {
  const [totalUsers, setTotalUsers] = useState(null);
  const [totalBarbershops, setTotalBarbershops] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const users = await instance.get("/barbermap/users/get_total_users");
      setTotalUsers(users.data.users.totalUsers);

      const barbershops = await instance.get(
        "/barbermap/users/get_total_barbershops"
      );
      setTotalBarbershops(barbershops.data.barbershops.totalBarbershops);

      setIsLoading(false);
    };
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <>
        <div className="container bg-light p-4 mt-5 rounded">
          <div className="">Cargando...</div>
          <ProgressBar animated now={100} />
        </div>
      </>
    );
  }

  return (
    <div className="container container-card-chart">

      <div className="card card-chart text-white">
        <div className="container-inside-chart">
          <h3>{totalUsers}</h3>
          <PeopleAltIcon className="icon-chart" />
        </div>
        <div className="container-inside-bot-chart">
          <p>Usuarios activos</p>
        </div>
      </div>
      
      <div className="card card-chart text-white">
        <div className="container-inside-chart">
          <h3>{totalBarbershops}</h3>
          <StoreIcon className="icon-chart"/>
        </div>
        <div className="container-inside-bot-chart">
          <p>Barberias activas</p>
        </div>
      </div>
      {/* <div className="card-chart">a</div> */}
    </div>
  );
};

export default ChartUsers;

import React, { useState } from "react";
import ModalWindow from "../../../../common/ModalWindow";
import FormEditBarbershop from "./FormEditBarbershop";
import instance from "../../../../../connection_api";
import { swalAlertDanger, swalAlertSuccess } from "../../../../common/Alerts";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import FormEditCoordinates from "./FormEditCoordinates";
import Pagination from "react-responsive-pagination";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import EditIcon from "@mui/icons-material/Edit";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import FormEditServices from "./FormEditServices";

const BarberShopTable = ({ barbershops, cities }) => {
  const [selectedBarbershop, setSelectedBarbershop] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalEditCoordinates, setModalEditCoordinates] = useState(false);
  const [modalServices, setModalServices] = useState(false);

  const navigate = useNavigate();
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");

  const handleProvinceChange = (event) => {
    setSelectedProvince(event.target.value);
    setCurrentPage(1);
  };

  const openModal = (barbershop) => {
    setSelectedBarbershop(barbershop);
    setIsModalOpen(true);
  };

  const openCoordinatesModal = (barbershop) => {
    setSelectedBarbershop(barbershop);
    setModalEditCoordinates(true);
  };

  const handleSubmit = async (editedBarbershop) => {
    const apiUrl = `https://nominatim.openstreetmap.org/search`;
    let lat = 0;
    let lon = 0;

    const params = {
      q: editedBarbershop.address,
      format: "json",
    };

    const response = await axios.get(apiUrl, { params });
    if (response.data && response.data[0]) {
      const result = response.data[0];
      lat = parseFloat(result.lat);
      lon = parseFloat(result.lon);
    }

    if (lat !== 0 && lon !== 0) {
      editedBarbershop.latitude = lat;
      editedBarbershop.longitude = lon;
    }

    instance
      .post("/barbermap_admin_update_barbershop", editedBarbershop)
      .then((res) => {
        if (res.status === 200) {
          swalAlertSuccess("DATOS MODIFICADOS CORRECTAMENTE", "", () => {
            navigate("/barbermap-admin-cba-administrator");
          });
        } else {
          swalAlertDanger("ERROR AL MODIFICAR LOS DATOS", "", () => {
            navigate("/barbermap-admin-cba-administrator");
          });
        }
      })
      .catch((error) => {
        swalAlertDanger("ERROR AL MODIFICAR LOS DATOS", "", () => {
          navigate("/barbermap-admin-cba-administrator");
        });
      });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const filteredBarbershops = barbershops.filter((barbershop) => {
    const isProvinceMatch =
      !selectedProvince || barbershop.city === parseInt(selectedProvince, 10);

    const isNameMatch =
      !searchTerm ||
      (barbershop.name &&
        barbershop.name.toLowerCase().includes(searchTerm.toLowerCase()));

    return isProvinceMatch && isNameMatch;
  });
  const currentBarbershops = filteredBarbershops.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handleEditCoordinates = async (editedBarbershop) => {
    try {
      const data = {
        id_barbershop: editedBarbershop.id_barbershop,
        latitude: editedBarbershop.latitude,
        longitude: editedBarbershop.longitude,
      };

      instance
        .post("/barbermap_admin_update_coordinates_barbershop", data)
        .then((res) => {
          if (res.status === 200) {
            swalAlertSuccess("DATOS MODIFICADOS CORRECTAMENTE", "", () => {
              navigate("/barbermap-admin-cba-administrator");
            });
          }
        })
        .catch((error) => {
          swalAlertDanger("ERROR AL MODIFICAR LOS DATOS", "", () => {
            navigate("/barbermap-admin-cba-administrator");
          });
        });
    } catch (error) {
      swalAlertDanger("ERROR AL MODIFICAR LOS DATOS", "", () => {
        navigate("/barbermap-admin-cba-administrator");
      });
    }
  };

  return (
    <>
      <div className="container background-sidebar">
        <div className="card card-sidebar4 pb-5 pt-5 pt-sm-2 text-center">
          <h1 className="title-users">NUESTROS CLIENTES</h1>
          <br />
          <div className="row">
            <select
              value={selectedProvince}
              onChange={handleProvinceChange}
              className="col-5 ms-5"
            >
              <option value="">Todas las provincias</option>
              {cities.map((city) => (
                <option key={city.id_city} value={city.id_city}>
                  {city.city_description}
                </option>
              ))}
            </select>
            <br />
            <input
              type="text"
              placeholder="Buscar barberia por nombre"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="col-5 ms-5"
            />
          </div>

          <div className="container-table">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="date-users date-none-table2">ID</th>
                  <th className="date-users">Barbería</th>
                  <th className="date-users date-none-table-barbershop">
                    Dirección
                  </th>
                  <th className="date-users date-none-table2">Descripción</th>
                  <th className="date-users date-none-table-barbershop">
                    Celular
                  </th>
                  <th className="date-users date-none-table-barbershop">
                    Instagram
                  </th>
                  <th className="date-users date-none-table-barbershop">
                    Estado
                  </th>
                  <th className="date-users date-none-table-barbershop">
                    Plan
                  </th>
                  <th className="date-users date-none-table2">Latitud</th>
                  <th className="date-users date-none-table2">Longitud</th>
                  <th className="date-users">Editar</th>
                  <th className="date-users">Coordenadas</th>
                  <th className="date-users">Servicios</th>
                </tr>
              </thead>
              <tbody>
                {currentBarbershops.map((barbershop) => (
                  <tr key={barbershop.id_barbershop}>
                    <td className="date-none-table2">
                      {barbershop.id_barbershop}
                    </td>
                    <td>{barbershop.name}</td>
                    <td className="date-none-table-barbershop">
                      {barbershop.address}
                    </td>
                    <td className="date-none-table2">
                      {barbershop.description}
                    </td>
                    <td className="date-none-table-barbershop">
                      {barbershop.cellphone}
                    </td>
                    <td className="date-none-table-barbershop">
                      {barbershop.instagram}
                    </td>
                    <td className="date-none-table-barbershop">
                      {barbershop.active === 1 ? "ACTIVO" : "BAJA"}
                    </td>{" "}
                    <td className="date-none-table-barbershop">
                      {barbershop.plan}
                    </td>
                    <td className="date-none-table2">{barbershop.latitude}</td>
                    <td className="date-none-table2">{barbershop.longitude}</td>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => openModal(barbershop)}
                      >
                        <EditIcon />
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => openCoordinatesModal(barbershop)}
                      >
                        <EditLocationIcon />
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          setModalServices(true);
                          setSelectedBarbershop(barbershop);
                        }}
                      >
                        <ContentCutIcon />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              total={barbershops.length}
              current={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>

      {selectedBarbershop && (
        <ModalWindow
          title="EDITAR BARBERIA"
          body={
            <FormEditBarbershop
              barbershop={selectedBarbershop}
              changeData={handleSubmit}
            />
          }
          setModalOpen={setIsModalOpen}
          modalOpen={isModalOpen}
          footer={"ADMINISTRADOR BARBER MAP"}
        />
      )}

      {selectedBarbershop && (
        <ModalWindow
          title="EDITAR COORDENADAS"
          body={
            <FormEditCoordinates
              barbershop={selectedBarbershop}
              changeData={handleEditCoordinates}
            />
          }
          setModalOpen={setModalEditCoordinates}
          modalOpen={modalEditCoordinates}
          footer={"ADMINISTRADOR BARBER MAP"}
        />
      )}

      {selectedBarbershop && (
        <ModalWindow
          title="EDITAR SERVICIOS"
          body={<FormEditServices barbershop={selectedBarbershop} />}
          setModalOpen={setModalServices}
          modalOpen={modalServices}
          footer={"ADMINISTRADOR BARBER MAP"}
        />
      )}
    </>
  );
};

export default BarberShopTable;

import React, { useEffect, useState } from "react";
import instance from "../../connection_api";
import BarberShopCardTest from "../../components/map/BarberShopCardTest";
import Logo4 from "../../assets/img/Logo4.png";
import Navbar from "../../components/nav-bottom/NavTop";
import {
  swalAlertDanger,
  swalAlertInfoOrange,
  swalAlertSuccess,

} from "../../components/common/Alerts";
// import useSessionCheck from "../../customHooks/useSessionCheck";
// import { useSelector } from "react-redux/es/hooks/useSelector";
// import { useNavigate } from "react-router-dom";

const BarbersUser = () => {
  // useSessionCheck();
  // const dataUser = useSelector((state) => state.user.dataUser);
  const [barberShops, setBarberShops] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [userFavorites, setUserFavorites] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState("");
  // const navigate = useNavigate();

  const [cities, setCities] = useState([]);

  // if (!dataUser) {
  //   swalAlertTimer("SESION EXPIRADA, POR FAVOR VUELVA A INICIAR SESION", 3000);
  //   navigate("/login");
  // }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get("/barbermap_get_barbershops");
        setBarberShops(response.data.barberShops);

        const responseFavorites = await instance.get(
          "/barbermap_get_favorites_user"
        );
        const favoritesData = responseFavorites.data;
        setUserFavorites(favoritesData);
        const responseCities = await instance.get("/getCities");
        setIsLoading(false);
        setCities(responseCities.data.cities);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setIsLoading(false);
      }
    };

    if (barberShops.length === 0) {
      fetchData();
    }
  }, [barberShops]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFavorite = (data) => {
    instance
      .post(`/user_set_favorite_barbershop`, { data: data })
      .then((res) => {
        if (res.status === 200) {
          if (data.status) {
            swalAlertSuccess("BARBERIA AÑADIDA A FAVORITOS", "", () => {});
          } else {
            swalAlertInfoOrange(
              "BARBERIA ELIMINADA DE FAVORITOS",
              "",
              () => {}
            );
          }
        } else {
          swalAlertDanger("Error al procesar la solicitud", "", () => {});
        }
      })
      .catch((error) => {
        swalAlertDanger("Error al procesar la solicitud", "", () => {});
      });
  };

  const handleProvinceChange = (event) => {
    setSelectedProvince(event.target.value);
    setSearchTerm(""); // Clear the name search when changing province
  };

  const filteredBarberShops = barberShops
    .map((barberShop) => {
      const isFavorite = userFavorites.some(
        (favorite) => favorite.id_barbershop === barberShop.id_barbershop
      );

      return {
        ...barberShop,
        isFavorite,
      };
    })
    .filter(
      (barberShop) =>
        (barberShop.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          barberShop.address
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) &&
        (selectedProvince === "" ||
          barberShop.city === parseInt(selectedProvince))
    );

  filteredBarberShops.sort((a, b) => {
    if (a.isFavorite && !b.isFavorite) {
      return -1;
    } else if (!a.isFavorite && b.isFavorite) {
      return 1;
    } else {
      return 0;
    }
  });

  if (isLoading) {
    return (
      <>
        <div className="container bg-light text-center p-4 mt-5 rounded">
          <div className="loading-container">
            <img src={Logo4} alt="Loading" className="loading-image" />
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="barber-shop-details-page">
      <div className="container-navbar">
        <Navbar />
      </div>
      <div className="mt-3">
        <div className="mt-3 nav-city bg-dark">
          <nav className="navbar navbar-dark bg-dark">
            <form className="form-inline">
              <div className="row">
                <div className="col-md-6 background-text-search-bar">
                  <input
                    type="text"
                    className="text-search-bar"
                    placeholder="Buscar barberia..."
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </div>
              </div>
            </form>
          </nav>
          <select
            value={selectedProvince}
            onChange={handleProvinceChange}
            className="form-city"
          >
            <option value="">Seleccionar provincia</option>
            {cities.map((city) => (
              <option key={city.id_city} value={city.id_city}>
                {city.city_description}
              </option>
            ))}
          </select>
        </div>

        {filteredBarberShops.map((barberShop) => (
          <BarberShopCardTest
            handleFavorite={handleFavorite}
            key={barberShop.id}
            barberShop={barberShop}
          />
        ))}
      </div>
    </div>
  );
};

export default BarbersUser;

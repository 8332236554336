import Navbar from "../../components/navbar/NavbarMain";
import Privacy from "../../components/footer/Privacy";

const Privacypolicy = () => {
  return (
    <div className="container-index">
      <Navbar />
      <br />
      <br />
      <Privacy />
    </div>
  );
};

export default Privacypolicy;

import React from "react";
import BarberShopCard from "../map/BarberShopCard";
import BarberShopCardTest from "../map/BarberShopCardTest";
import {
  swalAlertDanger,
  swalAlertInfoOrange,
  swalAlertSuccess,
} from "../common/Alerts";
import instance from "../../connection_api";

const Favorites = ({ barbershops }) => {
  const handleFavorite = (data) => {
    instance
      .post(`/user_set_favorite_barbershop`, { data: data })
      .then((res) => {
        if (res.status === 200) {
          if (data.status) {
            swalAlertSuccess("BARBERIA AÑADIDA A FAVORITOS", "", () => {
              window.location.reload();
            });
          } else {
            swalAlertInfoOrange("BARBERIA ELIMINADA DE FAVORITOS", "", () => {
              window.location.reload();
            });
          }
        } else {
          swalAlertDanger("Error al procesar la solicitud", "", () => {});
        }
      })
      .catch((error) => {
        swalAlertDanger("Error al procesar la solicitud", "", () => {});
      });
  };
  return (
    <div className="favorites-page">
      <div className="mt-5">
        {barbershops.length === 0 ? (
          <div className="card card-favorite favorites">
            Todavía no tienes barberías favoritas.
          </div>
        ) : (
          barbershops.map((barberShop) => (
            <BarberShopCardTest
              handleFavorite={handleFavorite}
              key={barberShop.id}
              barberShop={barberShop}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default Favorites;

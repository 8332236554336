import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../styles/BannerManagement.css";
import Image1 from "../../assets/img/Function6.webp";
import Image2 from "../../assets/img/Function.webp";
import Image3 from "../../assets/img/Function7.webp";

const BannerInicio = () => {
  return (
    <section
      className="banner-inicio background-banner-functions noselect mt-5"
      id="banner-gestion"
    >
      <Container className="container-banner-image mt-5">
        <Row className="align-items-center col-banner-img">
          <Col>
            <span className="tagline mt-5">
              ¿Cómo funciona el software de Barber Map?
            </span>
            <h1>
              {"Automatiza tu negocio de forma 100% online"}
              <span className="wrap"></span>
            </h1>
            <p>
              Recibe reservas a través de nuestra plataforma. Carga tus turnos y
              servicios y deja que tus clientes agenden por la web
            </p>
            <p>
              Administra todos los aspectos de tu negocio como la agenda,
              finanzas y productos!
            </p>
            <p>
              Evita perder reservas por falta de respuesta o atención
              telefónica.
            </p>
          </Col>
          <Col>
            <img src={Image1} alt="GESTION BARBER" className="banner-img" />
          </Col>
        </Row>
      </Container>
      <hr className="hr-line container" />

      <Container className="container-banner-image">
        <Row className="align-items-center col-banner-img">
          <Col className="col-banner-img">
            <img src={Image2} alt="GESTION BARBER" className="banner-img" />
          </Col>
          <Col>
            <h2>
              <span className="tagline">UN SITIO WEB PARA TU PELUQUERIA</span>
            </h2>
            <h1>
              {"Reservas Online"}
              <span className="wrap"></span>
            </h1>
            <p>
              Cada negocio tiene su propio sitio web donde puede recibir
              reservas online en menos de un minuto, 24/7. De esta manera, el
              negocio se encuentra siempre disponible para los clientes evitando
              perder reservas por falta de respuesta o atención telefónica.
            </p>
          </Col>
        </Row>
      </Container>
      <hr className="hr-line container" />
      <Container className="container-banner-image">
        <Row className="align-items-center col-banner-img">
          <Col>
            <h2>
              <span className="tagline">SOFTWARE ONLINE</span>
            </h2>
            <h1>
              {"Controla todos los aspectos de tu barberia de manera remota"}
              <span className="wrap"></span>
            </h1>
            <p>
              ¡Administra tu negocio desde cualquier lugar! Puedes <span className="destacado">gestionar reservas</span>, consultar turnos, visualizar estadísticas <span className="destacado">financieras</span> y
              de barberos, resolver la superposición de reservas con esta
              plataforma multiusuario, administrar barberos, controlar <span className="destacado">catálogo de productos</span>, stock y mucho más! desde cualquier dispositivo.
            </p>
          </Col>
          <Col className="col-banner-img">
            <img src={Image3} alt="GESTION BARBER" className="banner-img" />
          </Col>
        </Row>
      </Container>
      <hr className="hr-line container" />
    </section>
  );
};

export default BannerInicio;

import React, { useState, useEffect } from "react";
import instance from "../../connection_api";
import useSessionCheck from "../../customHooks/useSessionCheck";
import SidebarOwner from "../../components/barber-owner/SidebarOwner";
import { useSelector } from "react-redux";
import FormFilterDate from "../../components/commonForms/FormFilterDate";
import {
  swalAlertDanger,
  swalAlertSuccess,
  swalConfirmationAlert,
} from "../../components/common/Alerts";
import { formatDate } from "../../controllers/datetime";
import DeleteIcon from "@mui/icons-material/Delete";

const BarberAdminShiftFastHistory = () => {
  useSessionCheck();
  const dataUser = useSelector((state) => state.user.dataUser);
  const [dataBarberShop, setDataBarberShop] = useState([]);
  const [dataShifts, setDataShifts] = useState([]);
  const [showShifts, setShowShifts] = useState(false);

  useEffect(() => {
    instance
      .get(`/data_barbershop_owner/${dataUser.id_barbershop_owner}`)
      .then((response) => {
        setDataBarberShop(response.data.dataBarberShop);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dataUser.id_barbershop_owner]);

  const handleInfo = async (date) => {
    const shiftType = 2; // Tipo de turno rapido
    const data = {
      date_from: date.date_from,
      date_to: date.date_to,
    };
    instance
      .post(
        `/barbermap/barbershop/get_shift_type/${dataUser.id_barbershop_owner}/${shiftType}`,
        { data: data }
      )
      .then((res) => {
        setDataShifts(res.data.transactions);
        setShowShifts(true);
      })
      .catch((error) => {
        console.log(error);
        swalAlertDanger(
          "Ocurrio un error al obtener los datos",
          "Si el problema persiste, contacte a un administrador",
          () => {}
        );
      });
  };

  const handleDelete = async (data) => {
    swalConfirmationAlert(
      "¿Seguro desea eliminar el registro de este turno rápido?",
      "Esta acción es irreversible",
      () => {
        instance
          .post("/barbermap/barbershop/delete_fast_shift", { data: data })
          .then((res) => {
            if (res.status === 200) {
              swalAlertSuccess(
                "Registro eliminado de manera correcta",
                "",
                () => {
                  window.location.reload();
                }
              );
            }
          })
          .catch((error) => {
            console.log(error);
            swalAlertDanger(
              "Ocurrió un error al eliminar el registro",
              "Si el problema persiste, comuniquese con un administrador",
              () => {
                window.location.reload();
              }
            );
          });
      }
    );
  };

  return (
    <div>
      <div>
        <SidebarOwner dataBarberShop={dataBarberShop} />
      </div>
      <div className="container mt-4">
        <FormFilterDate handleInfo={handleInfo} />
      </div>
      {showShifts && (
        <div className="container mt-4">
          <table className="table table-secondary table-striped">
            <thead>
              <tr>
                <th>Barbero</th>
                <th>Servicio</th>
                <th>Monto</th>
                <th>Método de pago</th>
                <th>Fecha</th>
                <th>Eliminar</th>
              </tr>
            </thead>
            <tbody>
              {dataShifts.map((shift) => (
                <tr key={shift.id_transaction}>
                  <td>
                    {shift.name} {shift.lastname}
                  </td>
                  <td>{shift.service_description}</td>
                  <td>${shift.price_service}</td>
                  <td>{shift.payment_method_description}</td>
                  <td>{formatDate(shift.inserted_date)}</td>

                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        handleDelete(shift.id_transaction);
                      }}
                    >
                      <DeleteIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default BarberAdminShiftFastHistory;

import React from "react";
import "../../styles/MapCards.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const CardMap = ({ barberShop }) => {
  const dataUser = useSelector((state) => state.user.dataUser);

  const navigate = useNavigate();

  const handleViewShift = () => {
    if (!dataUser) {
      navigate(
        `/barbershop/guest/${barberShop.id_barbershop}/${barberShop.name}`
      );
    } else {
      navigate(`/barbershop/${barberShop.id_barbershop}/${barberShop.name}`);
    }
  };

  return (
    <div className="container card-map">
      <div className="card-image">
        <img
          className="img-logo-map"
          // src={`http://localhost:5001/logo_barbershops/${barberShop.path_logo}`}
          src={`https://apiserver.barbermap.com.ar/logo_barbershops/${barberShop.path_logo}`}
          alt="test"
        />{" "}
      </div>
      <div className="card-details">
        <h2 className="cardMap-title">{barberShop.name}</h2>
        <p className="cardMap-address">
          <strong>
            <FontAwesomeIcon icon={faMapMarker} /> {barberShop.address}
          </strong>
        </p>
        <button className="btncardMap" onClick={handleViewShift}>
          Ver turnos
        </button>
      </div>
    </div>
  );
};

export default CardMap;

/* Añadir Navbar UserIndex*/
import React from "react";
import ProfileUser from "../components/perfil/ProfileUser";
import useSessionCheck from "../customHooks/useSessionCheck";

const Profile = () => {
  // useSessionCheck();
  return (
    <div className="Container-Perfil-Page">
      <ProfileUser />
    </div>
  );
};

export default Profile;

import React, { useEffect, useState } from "react";
import FormSearchDate from "../../components/commonForms/FormSearchDate";
import { useNavigate } from "react-router-dom";
import SidebarBarber from "../../components/barber/SidebarBarber";
import { useSelector } from "react-redux";
import instance from "../../connection_api";
import {
  swalAlertDanger,
  swalAlertSuccess,
  swalConfirmationAlert,
  swalAlertInfo,
} from "../../components/common/Alerts";
import { formatDate, formatTime } from "../../controllers/datetime";
import useSessionCheck from "../../customHooks/useSessionCheck";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import UploadIcon from "@mui/icons-material/Upload";
import ModalWindow from "../../components/common/ModalWindow";
import FormFastShift from "../../components/barber/FormFastShift";
import FormBarberShiftModal from "../../components/barber/FormBarberShiftModal";
import FormShiftDone from "../../components/barber/FormShiftDone";

const BarberMyShifts = () => {
  useSessionCheck();
  const dataUser = useSelector((state) => state.user.dataUser);
  const navigate = useNavigate();

  const [shifts, setShifts] = useState([]);
  const [services, setServices] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [barbershopMember, setBarbershopMember] = useState(null);
  const [isOpenShifts, setOpenShifts] = useState(false);
  const [modalFastShift, setModalFastShift] = useState(false);
  const [selectedShift, setSelectedShift] = useState(null);
  const [modalDone, setModalDone] = useState(false);
  const [nameBarber, setNameBarber] = useState();
  //Generacion de turnos por parte del barbero.
  const [dataBarber, setDataBarber] = useState([]);
  const [modalGenerateShifts, setModalGenerateShifts] = useState(false);

  useEffect(() => {
    instance
      .get(`/barbershop_databarber_by_id/${dataUser.id_user}`)
      .then((res) => {
        setNameBarber(res.data[0]);
      })
      .catch((error) => {
        swalAlertDanger(
          "Error al obtener los turnos",
          "Intentelo nuevamente más tarde",
          () => {
            navigate("/user-management");
          }
        );
      });
    instance
      .get("/barbermap/barber/barbershop_member")
      .then((res) => {
        if (res.status === 200) {
          setBarbershopMember(res.data.id_barbershop[0].id_barbershop);
        }
      })
      .catch((error) => {
        swalAlertDanger(
          "Error al obtener los datos",
          "Si el problema persiste, comuníquese con soporte técnico",
          () => {
            navigate("/login");
          }
        );
      });
    instance
      .get("/barbermap/barbershop/get_payments_methods")
      .then((res) => {
        if (res.status === 200) {
          setPaymentMethods(res.data.methods);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    instance
      .get(`/barbershop/barber/${dataUser.id_user}`)
      .then((response) => {
        setDataBarber(response.data.dataBarber);
      })
      .catch((error) => {
        console.log(error);
        swalAlertDanger(
          "ERROR AL OBTENER LOS DATOS",
          "Intente nuevamente más tarde",
          () => {}
        );
      });
  }, [dataUser]);

  useEffect(() => {
    if (barbershopMember !== null) {
      instance
        .post("/barbermap_barbershop_get_services_by_id", {
          data: barbershopMember,
        })
        .then((res) => {
          if (res.status === 200) {
            setServices(res.data.services);
          }
        })
        .catch((error) => {
          swalAlertDanger(
            "Error al obtener los datos",
            "Si el problema persiste, comuníquese con soporte técnico",
            () => {
              navigate("/login");
            }
          );
        });
    }
  }, [barbershopMember]);

  const handleSearch = (date) => {
    const data = {
      date,
      barber: dataUser.id_user,
    };
    instance
      .post("/get_barber_historial_shifts", { data: data })
      .then((res) => {
        if (res.status === 200) {
          setShifts(res.data.shifts);
          setOpenShifts(true);
        }
      })
      .catch((error) => {
        swalAlertDanger(
          "Error al obtener los turnos",
          "Intentelo nuevamente más tarde",
          () => {
            navigate("/user-management");
          }
        );
      });
  };

  const handleRealizadoClick = (shift) => {
    swalConfirmationAlert(
      "¿DESEA MARCAR EL TURNO COMO REALIZADO?",
      "No podrá deshacer esta acción",
      () => {
        instance
          .post("/barbermap_barber_complete_shift", { data: shift })
          .then((res) => {
            if (res.status === 200) {
              swalAlertSuccess(`${res.data.msg}`, "", () => {
                window.location.reload();
              });
            } else {
              swalAlertDanger(
                "ERROR AL FINALIZAR EL TURNO",
                "Intentelo nuevamente más tarde",
                () => {
                  navigate("/barbermap-barber-shifts");
                }
              );
            }
          })
          .catch((error) => {
            swalAlertDanger(
              "ERROR EN EL SERVIDOR",
              "Intentelo nuevamente más tarde",
              () => {
                navigate("/barbermap-barber-shifts");
              }
            );
          });
      }
    );
  };

  const submitShift = (data) => {
    swalConfirmationAlert("¿Confirmar carga de turnos?", "", () => {
      instance
        .post(
          "/barbermap_barbershop_generate_shift_barber",
          { data },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            swalAlertSuccess(`${res.data.msg}`, `${res.data.submsg}`, () => {
              navigate(`/barbermap-barber`);
            });
          } else {
            swalAlertInfo(`${res.data.msg}`);
          }
        })
        .catch((error) => {
          swalAlertDanger(`ERROR`, "Se produjo un error al cargar", () => {
            navigate("/barbermap-barbershop-owner");
          });
        });
    });
  };

  return (
    <>
      <div>
        <SidebarBarber barber={nameBarber} />
        <div className="container">
          <div className="mt-5 text-center">
            <div className="row">
              <div className="col-6">
                <h3>BÚSQUEDA DE TURNOS</h3>
              </div>
              <div className="col-6">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setModalFastShift(true);
                  }}
                >
                  <ElectricBoltIcon /> TURNO RÁPIDO
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setModalGenerateShifts(true);
                  }}
                >
                  <UploadIcon /> CARGAR TURNOS
                </button>
              </div>
            </div>
            <div className="mt-4">
              <FormSearchDate onSearch={handleSearch} />
            </div>
            <div className="container mt-3 animated-wipe-down">
              {isOpenShifts ? (
                shifts.length > 0 ? (
                  <>
                    <p>RESERVAS DEL DÍA</p>
                    {shifts.map((shift) => (
                      <div className="mt-3" key={shift.id_shift}>
                        <div className="div-card-user">
                          <br />
                          <h5 className="card-title">
                            {formatDate(shift.date_shift)} HORA:{" "}
                            {formatTime(shift.time_shift)}
                          </h5>
                          <br />
                          <p className="card-text">
                            CLIENTE: {shift.name} {shift.lastname}
                          </p>
                          <p className="card-text">
                            ESTADO: {shift.status_description}
                          </p>
                          {shift.status_shift === 2 && (
                            <button
                              className="btn btn-on mb-4"
                              onClick={() => {
                                setSelectedShift(shift.id_shift);
                                setModalDone(true);
                              }}
                            >
                              REALIZADO
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="card card-body text-info">
                    No tienes turnos registrados en esta fecha
                  </div>
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalWindow
        title={"Carga de turno rápida"}
        body={
          <FormFastShift
            id_barbershop={barbershopMember}
            id_barber={dataUser.id_user}
            services={services}
            paymentMethods={paymentMethods}
          />
        }
        modalOpen={modalFastShift}
        setModalOpen={setModalFastShift}
        footer={
          "Se registrará el servicio realizado en el historial sin necesidad de reserva del cliente"
        }
      />
      <ModalWindow
        title={"Carga de turnos programados"}
        body={
          <FormBarberShiftModal
            onSubmit={submitShift}
            dataBarber={dataBarber}
          />
        }
        modalOpen={modalGenerateShifts}
        setModalOpen={setModalGenerateShifts}
        footer={"¡Los turnos generados podrán ser reservados por tus clientes!"}
      />
      {modalDone && (
        <ModalWindow
          title={"Registrar servicio finalizado"}
          setModalOpen={setModalDone}
          modalOpen={modalDone}
          body={
            <FormShiftDone
              shift={selectedShift}
              paymentMethods={paymentMethods}
              handleDone={handleRealizadoClick}
            />
          }
          footer={
            "Quedará registro del servicio realizado, podrás visualizarlo en tu historial y estadísticas."
          }
        />
      )}
    </>
  );
};

export default BarberMyShifts;

import React, { useEffect, useState } from "react";
import Calendar from "../../components/nav-bottom/CalendarShift";
import NavbarTop from "../../components/nav-bottom/NavTop";
import "../../styles/MainPage.css";
import { useSelector } from "react-redux";
import instance from "../../connection_api";
import {
  swalAlertDanger,
  swalAlertSuccess,
  swalConfirmationAlert,
} from "../../components/common/Alerts";
import { useNavigate } from "react-router-dom";
// import useSessionCheck from "../../customHooks/useSessionCheck";

const CalendarUser = () => {
  // useSessionCheck();
  const dataUser = useSelector((state) => state.user.dataUser);
  const navigate = useNavigate();
  const [shiftsUser, setShiftsUser] = useState([]);

  if (!dataUser) {
    // swalAlertTimer("SESION EXPIRADA, POR FAVOR VUELVA A INICIAR SESION", 3000);
    // navigate("/login");
    swalConfirmationAlert(
      "Para visualizar tus turnos deberás iniciar sesión",
      "¿Desea iniciar sesión?",
      () => {
        navigate("/login");
      }
    );
  }

  useEffect(() => {
    instance
      .get(`/user_data_shifts`)
      .then((res) => {
        if (res.status === 200) {
          setShiftsUser(res.data.shifts);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onCancel = (data) => {
    swalConfirmationAlert(
      "DESEA CANCELAR SU TURNO",
      "No podrá deshacer esta acción. Cancelar turnos repetitivamente lo inhabilitará a solicitar un turno temporalmente.",
      () => {
        instance
          .post(`user_cancel_shift`, { id_shift: data })
          .then((res) => {
            if (res.status === 200) {
              swalAlertSuccess(`${res.data.msg}`, "", () => {
                navigate("/user-management");
              });
            } else {
              swalAlertDanger(
                "Error en la cancelación",
                "Intentelo nuevamente más tarde",
                () => {
                  navigate("/user-management");
                }
              );
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );
  };

  return (
    <>
      <div className="container container-nav">
        <NavbarTop />
      </div>
      <div className="calendar">
        <Calendar shiftsUser={shiftsUser} onCancel={onCancel} />
      </div>
    </>
  );
};

export default CalendarUser;

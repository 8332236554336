import React, { useState, useEffect } from "react";
import FormFilterDate from "../../components/commonForms/FormFilterDate";
import SidebarOwner from "../../components/barber-owner/SidebarOwner";
import useSessionCheck from "../../customHooks/useSessionCheck";
import { useSelector } from "react-redux";
import instance from "../../connection_api";
import CardStats from "../../components/barber-owner/CardsStats";

const BarberAdminBilling = () => {
  useSessionCheck();
  const dataUser = useSelector((state) => state.user.dataUser);
  const [dataBarberShop, setDataBarberShop] = useState([]);
  // Content Cards
  const [billingByBarber, setbillingByBarber] = useState([]);
  const [billingByPaymentMethod, setbillingByPaymentMethod] = useState([]);
  const [servicesByBarber, setservicesByBarber] = useState([]);
  const [totalBilling, setTotalBilling] = useState([]);
  const [servicesByService, setservicesByService] = useState([]);
  //SelectDate
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [showTitleDate, setShowTitleDate] = useState(false);

  useEffect(() => {
    instance
      .get(`/data_barbershop_owner/${dataUser.id_barbershop_owner}`)
      .then((response) => {
        setDataBarberShop(response.data.dataBarberShop);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dataUser]);

  const handleInfo = async (date) => {
    setSelectedDateFrom(date.date_from);
    setSelectedDateTo(date.date_to);
    setShowTitleDate(true);
    const data = {
      date_from: date.date_from,
      date_to: date.date_to,
    };

    instance
      .post("/barbermap/barbershop/billing_by_date", {
        data: data,
      })
      .then((res) => {
        setTotalBilling(res.data.response.totalBilling);
        setbillingByPaymentMethod(res.data.response.billingByPaymentMethod);
        setservicesByBarber(res.data.response.servicesByBarber);
        setservicesByService(res.data.response.servicesByService);
        setbillingByBarber(res.data.response.billingByBarber);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div>
        <SidebarOwner dataBarberShop={dataBarberShop} />
      </div>
      <div className="container mt-3">
        <FormFilterDate handleInfo={handleInfo} />
        <div>
          {showTitleDate ? (
            <h3 className="h2-tittle-white mt-3">
              Facturación desde {selectedDateFrom} hasta {selectedDateTo}
            </h3>
          ) : (
            ""
          )}
        </div>
        <div>
          <CardStats
            totalBilling={totalBilling}
            billingByPaymentMethod={billingByPaymentMethod}
            totalBillingByBarber={billingByBarber}
            servicesByBarber={servicesByBarber}
            servicesByService={servicesByService}
          />
        </div>
      </div>
    </>
  );
};

export default BarberAdminBilling;

import React, { createContext, useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import { UserContext, UpdateDataUserContext } from "./UserContext";

export const UserProvider = ({ children }) => {
  const [dataUser, setDataUser] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState();

  const updateDataUser = (userData) => {
    setDataUser(userData);
  };

  useEffect(() => {
    // Cuando userData cambie, actualizar dataUser en el contexto
    setDataUser(userData);
  }, [userData]);

  // if (isLoading) {
  //   return (
  //     <>
  //       <div className="container bg-light p-4 mt-5 rounded">
  //         <div className="">Cargando...</div>
  //         <ProgressBar animated now={100} />
  //       </div>
  //     </>
  //   );
  // }

  return (
    <UserContext.Provider value={dataUser}>
      <UpdateDataUserContext.Provider value={updateDataUser}>
        {children}
      </UpdateDataUserContext.Provider>
    </UserContext.Provider>
  );
};

export default UserProvider;

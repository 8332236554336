import "../../styles/PerfilUser.css";
import NavbarIndex from "../nav-bottom/NavTop";
import instance from "../../connection_api";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  swalAlertDanger,
  swalAlertSuccess,
  swalAlertTimer,
} from "../common/Alerts";
import { FaUserTie } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { BsFillPhoneFill } from "react-icons/bs";
import FlechaAtras from "../common/ArrowBack";
import { useSelector, useDispatch } from "react-redux";
import { updateUserProfile } from "../redux/userActions";
import { ProgressBar } from "react-bootstrap";

const ProfileUser = () => {
  const dataUser = useSelector((state) => state.user.dataUser);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [formData, setFormData] = useState({
    id_user: dataUser?.id_user || null,
    name: dataUser?.name || "",
    lastname: dataUser?.lastname || "",
    email: dataUser?.email || "",
    cellphone: dataUser?.cellphone || "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (dataUser) {
      setIsLoading(false);
    } else {
      swalAlertTimer("Para visualizar su perfil, deberá iniciar sesión", 3000);
      navigate("/login");
    }
  }, [dataUser, navigate]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    instance
      .post("/updateProfileUser", formData)
      .then((response) => {
        if (response.status === 200) {
          dispatch(updateUserProfile(response.data.user)); // Actualiza el estado de Redux
          swalAlertSuccess("DATOS ACTUALIZADOS", "", () => {
            if (dataUser.user_rol === 2) {
              navigate("/barbermap-barber");
            } else {
              navigate("/user-management");
            }
          });
        } else {
          swalAlertDanger(
            "OCURRIO UN ERROR AL ACTUALIZAR LOS DATOS",
            "",
            () => {
              navigate("/profile");
            }
          );
        }
        setEditing(false);
      })
      .catch((error) => {
        swalAlertDanger("OCURRIO UN ERROR AL ACTUALIZAR LOS DATOS", "", () => {
          navigate("/");
        });
        console.error(error);
      });
  };

  if (isLoading) {
    return (
      <>
        <div className="container bg-light p-4 mt-5 rounded">
          <div className="">Cargando...</div>
          <ProgressBar animated now={100} />
        </div>
      </>
    );
  }

  return (
    <>
      {dataUser.user_rol === 3 ? (
        <NavbarIndex />
      ) : (
        <div className="mt-3 back-arrow-profile">
          <FlechaAtras />
        </div>
      )}

      <div className="card card-profile">
        <div className="row d-flex ">
          <div className="row d-flex div-cabecera">
            <FaUserTie className="icon-profile" style={{ color: "black" }} />
            <div className="text-center fw-bold h4">DATOS PERSONALES</div>
            <div className=" col-12 col-sm-12 col-md-12 col-lg-12">
              <hr className="hr-profile" />
            </div>
          </div>
          <div className="col-12 col-sm-10 col-md-8 col-lg-6 mt-3 div-info">
            <FaUserAlt className="icon-info" style={{ color: "black" }} />
            <b>NOMBRE:</b>
            <b className="inputs-profile" style={{ color: "black" }}>
              {dataUser.name} {dataUser.lastname}
            </b>
          </div>
          <div className="col-12 col-sm-10 col-md-8 col-lg-6 mt-3 div-info">
            <MdEmail className="icon-info" style={{ color: "black" }} />
            <b>CORREO ELECTRONICO:</b>
            {editing ? (
              <input
                className="form-control inputs-profile"
                type="email"
                name="email"
                defaultValue={formData.email}
                onChange={handleInputChange}
              />
            ) : (
              <b className="inputs-profile" style={{ color: "black" }}>
                {dataUser.email}
              </b>
            )}
          </div>
          <div className="col-12 col-sm-10 col-md-8 col-lg-6 mt-3 div-info">
            <BsFillPhoneFill className="icon-info" style={{ color: "black" }} />
            <b>TELEFONO:</b>
            {editing ? (
              <input
                className="form-control inputs-profile"
                type="tel"
                name="cellphone"
                defaultValue={formData.cellphone}
                onChange={handleInputChange}
              />
            ) : (
              <b className="inputs-profile" style={{ color: "black" }}>
                {dataUser.cellphone}
              </b>
            )}
          </div>
        </div>
        {editing && (
          <div className="row d-flex mt-5 px-3">
            <button
              onClick={handleSubmit}
              className="button-profile btn btn-primary mt-2 mb-3"
            >
              Guardar
            </button>
          </div>
        )}
        {!editing && (
          <div className="row d-flex mt-5 px-3">
            <button
              onClick={() => setEditing(true)}
              className="button-profile btn btn-primary button-perfil mt-2 mb-3"
            >
              Editar
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ProfileUser;

import React from "react";
import "../../styles/BannerStyle.css";
import "../../styles/ManagementHome.css";
import NavbarManagement from "../../components/navbar/NavbarManagement";
import CardTutorial from "../../components/companies/CardTutorial";
import BannerTutorial from "../../components/companies/BannerTutorial";
import Footer from "../../components/footer/Footer";
const Tutorial = () => {
  return (
    <>
      <NavbarManagement />
      <div className="my-4">
        <BannerTutorial />
      </div>
      <div className="my-4">
        <CardTutorial />
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default Tutorial;

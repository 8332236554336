import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logo2 from "../../assets/img/Logo3.png";
import "../../styles/NavbarGestion.css";
import { MenuItems, MenuItemsMobil } from "./MenuItemsGestion";
import Logo4 from "../../assets/img/Logo4.png";

class NavbarManagement extends Component {
  state = {
    clicked: false,
    windowWidth: window.innerWidth,
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  render() {
    const { clicked, windowWidth } = this.state;
    const isMobile = windowWidth <= 768;

    return (
      <nav className="NavbarItems-Gestion">
        <div className="logo">
          <Link to="/">
            <img src={Logo2} alt="BARBER-MAP" className="image-logo"></img>
          </Link>
        </div>
        <div className="menu-icons" onClick={this.handleClick}>
          <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
        <ul className={clicked ? "nav-menu active" : "nav-menu"}>
          {isMobile
            ? MenuItemsMobil.map((item, index) => (
                <li className="item-navbar" key={index}>
                  <Link className={item.cName} to={item.url}>
                    {item.title === "Inicio" && (
                      <i className="fas fa-home icon-navbar-gestion"></i>
                    )}
                    {item.title === "Precios" && (
                      <i className="fa-dollar-sign icon-navbar-gestion"></i>
                    )}
                    {item.title === "Funciones" && (
                      <i className="fas fa-cogs icon-navbar-gestion"></i>
                    )}
                    {item.title === "Tutoriales" && (
                      <i className="fas fa-graduation-cap icon-navbar-gestion"></i>
                    )}
                    {item.title}
                  </Link>
                </li>
              ))
            : MenuItems.map((item, index) => (
                <li className="item-navbar" key={index}>
                  <Link className={item.cName} to={item.url}>
                    {item.title === "Software para barberías" && (
                      <i className="fas fa-cut"></i>
                    )}
                    {item.title}
                  </Link>
                </li>
              ))}
          <hr className="divider" />
          <Link to="/login" className="iniciar-sesion">
            <button>
              <i className="fas fa-door-open"></i>
              <span>INICIAR SESION</span>
            </button>
            <img src={Logo4} className="logo-bottom-sidebar"></img>
          </Link>
        </ul>
      </nav>
    );
  }
}

export default NavbarManagement;

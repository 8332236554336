import React, { useState } from "react";
import "./BarberShopCardTest.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import Instagram from "../../assets/img/instagram.png";
import { useSelector } from "react-redux";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { yellow } from "@mui/material/colors";

const BarberShopCard = ({ handleFavorite, barberShop }) => {
  const dataUser = useSelector((state) => state.user.dataUser);
  const navigate = useNavigate();

  const [isFavorite, setIsFavorite] = useState(barberShop.isFavorite);
  const [isImageExpanded, setIsImageExpanded] = useState(false);

  const handleViewShift = () => {
    if (!dataUser) {
      navigate(
        `/barbershop/guest/${barberShop.id_barbershop}/${barberShop.name}`
      );
    } else {
      navigate(`/barbershop/${barberShop.id_barbershop}/${barberShop.name}`);
    }
  };

  const handleToggleFavorite = (id_barbershop) => () => {
    setIsFavorite(!isFavorite);

    const favorite = {
      status: !isFavorite,
      id_barbershop: id_barbershop,
      id_user: dataUser.id_user,
    };
    handleFavorite(favorite);
  };

  const handleImageClick = () => {
    setIsImageExpanded(!isImageExpanded);
  };

  const imgClassName = isImageExpanded ? "img-logo expanded" : "img-logo";

  return (
    <div className="barbercard-conteiner">
      <div className="barbercard-padre" key={barberShop.id_barbershop}>
        <div className="barbercard-img">
          <img
            className="img-card-barber"
            // src={`http://localhost:5001/logo_barbershops/${barberShop.path_logo}`}
            src={`https://apiserver.barbermap.com.ar/logo_barbershops/${barberShop.path_logo}`}
            alt="test"
            onClick={handleImageClick}
          />{" "}
        </div>

        <div className="barbercard-info">
          <div className="icons-2">
            <a href={`https://www.instagram.com/${barberShop.instagram}`}>
              <img
                className="logo-ig"
                src={Instagram}
                alt="Logo INSTRAGRAM BARBER"
              ></img>
            </a>
          </div>
          <div
            className="icons"
            onClick={handleToggleFavorite(barberShop.id_barbershop)}
          >
            <div>
              {isFavorite ? (
                <StarIcon className="test" />
              ) : (
                <StarBorderIcon className="test-favorite" />
              )}
            </div>
          </div>
          <h2 className="barbershop-name">{barberShop.name}</h2>
          <h3 className="barbershop-adress">
            <FontAwesomeIcon icon={faMapMarker} />
            {barberShop.address}
          </h3>
          <button className="btnbarber-card" onClick={handleViewShift}>
            Ver turnos
          </button>
        </div>
      </div>
    </div>
  );
};

export default BarberShopCard;

import React, { useState } from "react";

const ModalUser = ({ user, onSave, onClose, roles, cities }) => {
  const [editedUser, setEditedUser] = useState(user);

  const handleSave = () => {
    onSave(editedUser);
    onClose();
  };

  return (
    <div className="modal fade show" style={{ display: "block" }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Editar Usuario</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Cerrar"
              onClick={onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label>NOMBRE</label>
              <input
                type="text"
                className="form-control-admin"
                name="name"
                defaultValue={editedUser.name}
                onChange={(e) =>
                  setEditedUser({ ...editedUser, name: e.target.value })
                }
              />
            </div>
            <div className="form-group mt-4">
              <label>APELLIDO</label>
              <input
                type="text"
                name="lastname"
                className="form-control-admin"
                defaultValue={editedUser.lastname}
                onChange={(e) =>
                  setEditedUser({ ...editedUser, lastname: e.target.value })
                }
              />
            </div>
            <div className="form-group mt-4">
              <label>EMAIL</label>
              <input
                type="text"
                name="email"
                className="form-control-admin"
                defaultValue={editedUser.email}
                onChange={(e) =>
                  setEditedUser({ ...editedUser, email: e.target.value })
                }
              />
            </div>
            <div className="form-group mt-4">
              <label>CELULAR</label>
              <input
                type="text"
                name="cellphone"
                className="form-control-admin"
                defaultValue={editedUser.cellphone}
                onChange={(e) =>
                  setEditedUser({ ...editedUser, cellphone: e.target.value })
                }
              />
            </div>
            <div className="form-group mt-4">
              <span>ROL</span>
              <select
                className="form-style"
                name="user_rol"
                id="user_rol"
                required
                onChange={(e) =>
                  setEditedUser({
                    ...editedUser,
                    user_rol: e.target.value,
                  })
                }
              >
                <option value="">Modificar rol</option>
                {roles.map((rol) => (
                  <option key={rol.id_rol} value={rol.id_rol}>
                    {rol.rol_description}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group mt-4">
              <span>CIUDAD</span>
              <select
                className="form-style"
                name="city"
                id="city"
                required
                onChange={(e) =>
                  setEditedUser({
                    ...editedUser,
                    city: e.target.value,
                  })
                }
              >
                <option value="">Modificar ciudad</option>
                {cities.map((city) => (
                  <option key={city.id_city} value={city.id_city}>
                    {city.city_description}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Cerrar
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSave}
            >
              Guardar Cambios
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalUser;

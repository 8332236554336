export const MenuItems = [

    {
        title : "Inicio",
        url : "/salon-management-software",
        cName : "nav-links-gestion",
    },
    {
        title : "Precios",
        url : "/salon-management-software-prices",
        cName : "nav-links-gestion",
    },
    {
        title : "Funciones",
        url : "/salon-management-software-functions",
        cName : "nav-links-gestion",
    },
    {
        title : "Tutoriales",
        url : "/salon-management-software-tutorial",
        cName : "nav-links-gestion",
    },

]


export const MenuItemsMobil = [

    {
        title : "Inicio",
        url : "/salon-management-software",
        cName : "nav-links-mobile",
    },
    {
        title : "Precios",
        url : "/salon-management-software-prices",
        cName : "nav-links-mobile",
    },
    {
        title : "Funciones",
        url : "/salon-management-software-functions",
        cName : "nav-links-mobile",
    },
    {
        title : "Tutoriales",
        url : "/salon-management-software-tutorial",
        cName : "nav-links-mobile",
    },

]

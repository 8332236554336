import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/Text.css";
import "../../styles/Cards.css";
import instance from "../../connection_api";
import FormSearchDate from "../../components/commonForms/FormSearchDate";
import { useSelector } from "react-redux";
import {
  swalAlertDanger,
  swalAlertInfo,
  swalAlertSuccess,
  swalConfirmationAlert,
} from "../../components/common/Alerts";
import { formatTime } from "../../controllers/datetime";
import NavbarTop from "../../components/nav-bottom/NavTop";
// import useSessionCheck from "../../customHooks/useSessionCheck";
import ModalWindow from "../../components/common/ModalWindow";
import FormService from "../../components/barber/FormService";

const BarberShifts = () => {
  // useSessionCheck();
  const dataUser = useSelector((state) => state.user.dataUser);
  const { id_user, id_barbershop } = useParams();
  const navigate = useNavigate();

  const [shifts, setShifts] = useState([]);
  const [selectedShift, setSelectedShift] = useState(null);
  const [nameBarber, setNameBarber] = useState();
  const [isOpenShifts, setOpenShifts] = useState(false);
  const [services, setServices] = useState([]);
  const [modalShifts, setModalShifts] = useState(false);

  useEffect(() => {
    instance
      .get(`/barbershop_databarber_by_id/${id_user}`)
      .then((res) => {
        setNameBarber(res.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
    instance
      .post(`/barbermap_barbershop_get_services_by_id`, { data: id_barbershop })
      .then((res) => {
        setServices(res.data.services);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id_barbershop, id_user]);

  const handleSearch = (date) => {
    const data = {
      date,
      barber: id_user,
      id_barbershop: id_barbershop,
    };
    instance
      .post("/get_barber_shifts_filtered", { data: data })
      .then((res) => {
        if (res.status === 200) {
          setShifts(res.data.shifts);
          setOpenShifts(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleReserve = (idShift, service) => {
    if (!dataUser) {
      swalAlertInfo("Para reservar el turno deberás iniciar sesión", "", () => {
        window.history.back();
      });
    } else {
      const data = {
        id_user: dataUser.id_user,
        id_shift: idShift,
        service: service,
      };

      swalConfirmationAlert(
        "¿DESEA CONFIRMAR LA RESERVA DEL TURNO?",
        "",
        () => {
          instance
            .post("/barber_set_reserved_shift", { data: data })
            .then((res) => {
              if (res.status === 200) {
                swalAlertSuccess(`${res.data.msg}`, "", () => {
                  navigate("/calendar");
                });
              } else {
                if (res.status === 203) {
                  swalAlertInfo(
                    `${res.data.msg}`,
                    "Si desea puede cancelar alguno de los turnos y solicitar uno nuevo.",
                    () => {
                      navigate("/calendar");
                    }
                  );
                } else {
                  swalAlertDanger(
                    "Error al registrar",
                    "Intentelo nuevamente más tarde",
                    () => {
                      navigate("/user-management");
                    }
                  );
                }
              }
            })
            .catch((error) => {
              swalAlertDanger(
                "Error al registrar",
                "Intentelo nuevamente más tarde",
                () => {
                  navigate("/user-management");
                }
              );
            });
        }
      );
    }
  };

  return (
    <>
      <div className="container container-nav">
        <NavbarTop />
      </div>
      <div className="container mt-5">
        <div className="mt-3">
          {nameBarber ? (
            <h1 className="h1-tittle-white">
              Turnos disponibles de {nameBarber.name} {nameBarber.lastname}
            </h1>
          ) : (
            ""
          )}
        </div>

        <div className="mt-3">
          <FormSearchDate onSearch={handleSearch} />
        </div>
        <div className="container mt-3 animated-wipe-down list-group">
          {isOpenShifts ? (
            shifts.length > 0 ? (
              <>
                <p className="p-text">¡TURNOS ENCONTRADOS!</p>
                <div className="row">
                  {shifts.map((shift) => (
                    <div className="col-lg-6 col-sm-12" key={shift.id_shift}>
                      <div className="card mt-3">
                        <div className="card-content">
                          <h5 className="h5-head">
                            HORARIO DEL TURNO {formatTime(shift.time_shift)}
                            <div className="mt-3">
                              <button
                                className="btn btn-book"
                                onClick={() => {
                                  setSelectedShift(shift.id_shift);
                                  setModalShifts(true);
                                }}
                              >
                                RESERVAR
                              </button>
                            </div>
                          </h5>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="card card-body text-info">
                El barbero no cuenta con turnos disponibles para esta fecha
              </div>
            )
          ) : (
            ""
          )}
        </div>
      </div>
      {services && services.length > 0 ? (
        <ModalWindow
          title={"Selección de servicio"}
          body={
            <FormService
              services={services}
              shift={selectedShift}
              handleReserve={handleReserve}
            />
          }
          modalOpen={modalShifts}
          setModalOpen={setModalShifts}
          footer={
            "La disponibilidad de cada servicio está sujeta a la duración del mismo"
          }
        />
      ) : (
        ""
      )}
    </>
  );
};
export default BarberShifts;

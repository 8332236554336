import Swal from "sweetalert2";

export const swalAlertSuccess = (title, text, onConfirm) => {
  Swal.fire({
    title: title,
    text: text,
    icon: "success",
    confirmButtonText: "Aceptar",
  }).then(() => {
    onConfirm();
  });
};

export const swalAlertDanger = (title, text, onConfirm) => {
  Swal.fire({
    title: title,
    text: text,
    icon: "error",
    confirmButtonText: "Aceptar",
  }).then(() => {
    onConfirm();
  });
};

export const swalAlertInfo = (title, text, onConfirm) => {
  Swal.fire({
    title: title,
    text: text,
    icon: "info",
    confirmButtonText: "Aceptar",
  }).then(() => {
    onConfirm();
  });
};

export const swalAlertInfoOrange = (title, message, onConfirm) => {
  Swal.fire({
    title: title,
    message: message,
    icon: "info",
    iconColor: "orange",
    confirmButtonText: "Aceptar",
  }).then(() => {
    onConfirm();
  });
};

export function swalConfirmationAlert(title, message, onConfirm) {
  Swal.fire({
    title: title,
    html: message,
    icon: "question",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Confirmar",
    cancelButtonText: "Cancelar",
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm();
    }
  });
}

export const showLoadingSpinner = () => {
  Swal.fire({
    title: "CARGANDO SOLICITUD...",
    allowOutsideClick: false,
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  });
};

export const swalAlertTimer = (title, timerDuration) => {
  return new Promise((resolve) => {
    let timerInterval;
    Swal.fire({
      title: title,
      timer: timerDuration,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
        resolve(); // Resuelve la promesa cuando el temporizador termina
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
      }
    });
  });
};

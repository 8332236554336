import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import instance from "../../connection_api";
import FormBarberShift from "../../components/barber-owner/FormBarberShift";
import SidebarOwner from "../../components/barber-owner/SidebarOwner";
import { useSelector } from "react-redux";
import {
  swalAlertDanger,
  swalAlertInfo,
  swalAlertSuccess,
  swalConfirmationAlert,
} from "../../components/common/Alerts";
import useSessionCheck from "../../customHooks/useSessionCheck";

const BarberAdminShift = () => {
  useSessionCheck();
  const { id_user } = useParams();
  const dataUser = useSelector((state) => state.user.dataUser);
  const [dataBarber, setDataBarber] = useState([]);
  const [dataBarberShop, setDataBarberShop] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    instance
      .get(`/barbershop/barber/${id_user}`)
      .then((response) => {
        setDataBarber(response.data.dataBarber);
      })
      .catch((error) => {
        swalAlertDanger(
          "ERROR AL OBTENER LOS DATOS",
          "Intente nuevamente más tarde",
          () => {}
        );
      });

    instance
      .get(`/data_barbershop_owner/${dataUser.id_barbershop_owner}`)
      .then((response) => {
        setDataBarberShop(response.data.dataBarberShop);
      })
      .catch((error) => {
        swalAlertDanger(
          "ERROR AL OBTENER LOS DATOS",
          "Intente nuevamente más tarde",
          () => {}
        );
      });
  }, [dataUser, id_user]);

  const submitShift = (data) => {
    swalConfirmationAlert("¿Confirmar carga de turnos?", "", () => {
      instance
        .post(
          "/barbermap_barbershop_generate_shift_barber",
          { data },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            swalAlertSuccess(`${res.data.msg}`, `${res.data.submsg}`, () => {
              navigate(`/barbermap-barbershop-owner-barbers`);
            });
          } else {
            swalAlertInfo(`${res.data.msg}`);
          }
        })
        .catch((error) => {
          swalAlertDanger(`ERROR`, "Se produjo un error al cargar", () => {
            navigate("/barbermap-barbershop-owner");
          });
        });
    });
  };

  return (
    <div>
      <div>
        <SidebarOwner dataBarberShop={dataBarberShop} />
      </div>
      <div className="mt-5 container card-form-Barbershift">
        {dataBarber && dataBarber.length > 0 ? (
          <div>
            <h3 className="tittle-white h3">
              BARBERO {dataBarber[0].name} {dataBarber[0].lastname}
            </h3>
            <FormBarberShift onSubmit={submitShift} dataBarber={dataBarber} />
          </div>
        ) : (
          <p>Cargando datos...</p>
        )}
      </div>
    </div>
  );
};

export default BarberAdminShift;

import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/NavbarManagement";
import CardPrices from "../../components/companies/PricesCard";
import Footer from "../../components/footer/Footer";
import instance from "../../connection_api";
import BannerPrices from "../../components/companies/BannerPrices";
import { swalAlertDanger } from "../../components/common/Alerts";

const Prices = () => {
  const [plansData, setPlansData] = useState([]);

  useEffect(() => {
    instance
      .get("/get_barbershop_plans")
      .then((res) => {
        setPlansData(res.data.plans);
      })
      .catch(() => {
        swalAlertDanger("Error al procesar la solicitud", "", () => {});
      });
  }, []);

  return (
    <div>
      <Navbar />
      <BannerPrices />
      <CardPrices prices={plansData} />
      <Footer />
    </div>
  );
};

export default Prices;

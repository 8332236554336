import React, { useEffect, useState } from "react";
import Sidebar from "../../components/admin/global/Sidebar";
import RegisterBarber from "../../components/admin/components/pages/RegisterBarber/RegisterBarber";
import Logo4 from "../../assets/img/Logo4.png";
import instance from "../../connection_api";

import {
  swalAlertDanger,
  swalAlertInfo,
  swalAlertSuccess,
  swalConfirmationAlert,
} from "../../components/common/Alerts";
import { useNavigate } from "react-router-dom";
import useSessionCheck from "../../customHooks/useSessionCheck";

const RegisterBarberPage = () => {
  useSessionCheck();
  const navigate = useNavigate();

  const [barbershops, setBarbershops] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get("/barbermap_get_barbershops");
        const responseCities = await instance.get("/getCities");
        setBarbershops(response.data.barberShops);
        setCities(responseCities.data.cities);
        setIsLoading(false);
      } catch (error) {
        swalAlertDanger(
          "ERROR AL OBTENER LOS DATOS",
          "Intente nuevamente más tarde",
          () => {
            navigate("/barbermap-admin-cba-administrator");
          }
        );
      }
    };
    fetchData();
  }, []);

  const handleChargeBarber = (data) => {
    swalConfirmationAlert("¿Confirmar registro de barbero?", "", () => {
      instance
        .post(
          "/barbermap_register_barber",
          { data },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            swalAlertSuccess(`${res.data.msg}`, `${res.data.submsg}`, () => {
              navigate("/barbermap-admin-cba-administrator");
            });
          } else {
            swalAlertInfo(`${res.data.msg}`);
          }
        })
        .catch((error) => {
          swalAlertDanger(`ERROR`, "Se produjo un error al cargar", () => {
            navigate("/barbermap-admin-cba-administrator");
          });
        });
    });
  };

  if (isLoading) {
    return (
      <>
        <div className="container bg-light text-center p-4 mt-5 rounded">
          <div className="loading-container">
            <img src={Logo4} alt="Loading" className="loading-image" />
          </div>
        </div>
      </>
    );
  }

  return (
    <div>
        <Sidebar />
      <div className="register-barber-page">
        <RegisterBarber
          barberShops={barbershops}
          cities={cities}
          sendData={handleChargeBarber}
        />
      </div>
    </div>
  );
};
export default RegisterBarberPage;

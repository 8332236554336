import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/NavbarOwnerBarber.css";
import ModalWindow from "../common/ModalWindow";
import FormFastShift from "../barber/FormFastShift";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ButtonsDash = ({
  id_barbershop,
  id_barber,
  services,
  paymentMethods,
  barberLink,
}) => {
  const [modalFastShift, setModalFastShift] = useState(false);

  const copyBarberLink = () => {
    navigator.clipboard.writeText(barberLink);
    toast.success("Enlace de barbería copiado al cortapapeles", {
      autoClose: 2000, // 2 segundos
    });
  };

  return (
    <div>
      <div className="container container-buttons-admin">
        <Link to="/barbermap-barbershop-owner-barbers">
          <button className="btn btn-primary">+ Cargar turnos</button>
        </Link>
        <Link to="/barbermap-barbershop-services">
          <button className="btn btn-primary">Mis servicios</button>
        </Link>
        <Link to="/barbermap-barbershop-owner-barbers">
          <button className="btn btn-primary">Mis barberos</button>
        </Link>
        <button
          className="btn btn-primary"
          onClick={() => {
            setModalFastShift(true);
          }}
        >
          <ElectricBoltIcon /> TURNO RÁPIDO
        </button>

        {barberLink && (
          <button onClick={copyBarberLink}>
            <ContentCopyIcon />
          </button>
        )}
      </div>
      <ModalWindow
        title={"Carga de turno rápida"}
        body={
          <FormFastShift
            id_barbershop={id_barbershop}
            id_barber={id_barber}
            services={services}
            paymentMethods={paymentMethods}
          />
        }
        modalOpen={modalFastShift}
        setModalOpen={setModalFastShift}
        footer={
          "Se registrará el servicio realizado en el historial sin necesidad de reserva del cliente"
        }
      />
      <ToastContainer /> {/* Este es el contenedor de los toasts */}
    </div>
  );
};

export default ButtonsDash;

import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ArrowDownRightCircle } from 'react-bootstrap-icons';
import '../../styles/BannerStyle.css';
import Barber from '../../assets/img/barber1.jpg';


const imageList = [Barber];
const transitionDuration = 11000;

const BannerPrices = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [nextImageIndex, setNextImageIndex] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setNextImageIndex((currentImageIndex + 1) % imageList.length);
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageList.length);
    }, transitionDuration);

    return () => {
      clearInterval(interval);
    };
  }, [currentImageIndex]);

  const handleConnectClick = () => {
    const pricesCardSection = document.getElementById('prices-card');
    if (pricesCardSection) {
      pricesCardSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="banner">
      <Container className='container-banner noselect'>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <span className="tagline">Sistema de Gestión de Barberías</span>
            <h1>
              {'Todo lo que tu negocio necesita, al mejor precio '}
              <span className="wrap"></span>
            </h1>
            <p>Automatizá tu barbería</p>
            <button className="button-connect" onClick={handleConnectClick}>
              Ver planes <ArrowDownRightCircle size={25} />
            </button>
          </Col>
        </Row>
      </Container>

      <div className="image-transition-container">
        <div className="background-gradient"></div>
        <div className="image-wrapper">
          {imageList.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Image ${index}`}
              className="transition-image"
              style={{
                opacity:
                  index === currentImageIndex || index === nextImageIndex ? 1 : 0,
                width: '100%',
              }}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default BannerPrices;

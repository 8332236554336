import React, { useState, useEffect } from "react";

const FormEditService = ({ service, handleEditService }) => {
  const [description, setDescription] = useState(service.service_description);
  const [price, setPrice] = useState(service.service_price);
  const [serviceTime, setServiceTime] = useState(hoursMinutesToMinutes(service.service_time));

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };

  const handleTimeChange = (e) => {
    setServiceTime(e.target.value);
  };

  const handleSaveChanges = () => {
    // Construye un objeto con los datos editados
    const editedService = {
      ...service,
      service_description: description,
      service_price: price,
      service_time: minutesToHoursMinutes(serviceTime),
    };

    // Envía el objeto al controlador de edición
    handleEditService(editedService);
  };

  // Función para convertir horas:minutos a minutos
  function hoursMinutesToMinutes(timeString) {
    const [hours, minutes, _] = timeString.split(":");
    return parseInt(hours) * 60 + parseInt(minutes);
  }

  // Función para convertir minutos a horas:minutos
  function minutesToHoursMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const minutesPart = minutes % 60;
    return `${hours.toString().padStart(2, '0')}:${minutesPart.toString().padStart(2, '0')}:00`;
  }

  return (
    <div className="container">
      <h2>Editar servicio</h2>
      <form>
        <div className="form-group">
          <label htmlFor="serviceDescription">Descripción del servicio:</label>
          <input
            type="text"
            className="form-control"
            id="serviceDescription"
            value={description}
            onChange={handleDescriptionChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="servicePrice">Precio del servicio:</label>
          <input
            type="number"
            className="form-control"
            id="servicePrice"
            value={price}
            onChange={handlePriceChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="service_time">Duración del servicio (minutos):</label>
          <input
            type="text"
            className="form-control"
            id="service_time"
            value={serviceTime}
            onChange={handleTimeChange}
          />
        </div>
        <button
          type="button"
          className="btn btn-book mt-3"
          onClick={handleSaveChanges}
        >
          Guardar cambios
        </button>
      </form>
    </div>
  );
};

export default FormEditService;

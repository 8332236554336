import React, { useEffect, useState } from "react";
import useSessionCheck from "../../customHooks/useSessionCheck";
import { useSelector } from "react-redux";
import instance from "../../connection_api";
import SidebarOwner from "../../components/barber-owner/SidebarOwner";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ModalWindow from "../../components/common/ModalWindow";
import FormCatalogCreateProduct from "../../components/barber-owner/FormCatalogCreateProduct";
import {
  swalAlertDanger,
  swalAlertInfo,
  swalAlertSuccess,
  swalConfirmationAlert,
} from "../../components/common/Alerts";
import TableProductsCatalog from "../../components/barber-owner/TableProductsCatalog";
import FormCatalogRegisterTransaction from "../../components/barber-owner/FormCatalogRegisterTransaction";

const AdminCatalog = () => {
  useSessionCheck();
  const dataUser = useSelector((state) => state.user.dataUser);
  const [dataBarberShop, setDataBarberShop] = useState([]);
  const [categoriesProduct, setCategoriesProduct] = useState([]);
  const [products, setProducts] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const [showModalCreateProduct, setShowModalCreateProduct] = useState(false);
  const [showModalTransaction, setShowModalTransaction] = useState(false);
  const [totalTransaction, setTotalTransaction] = useState(0);

  useEffect(() => {
    if (dataUser) {
      instance
        .get(`/data_barbershop_owner/${dataUser.id_barbershop_owner}`)
        .then((response) => {
          setDataBarberShop(response.data.dataBarberShop);
        })
        .catch((error) => {
          console.log(error);
        });

      instance
        .get("/barbermap/barbershop/get_categories_product")
        .then((res) => {
          setCategoriesProduct(res.data.categories);
        })
        .catch((error) => {
          console.log(error);
        });

      instance
        .get("/barbermap/barbershop/get_products_by_barbershop")
        .then((res) => {
          setProducts(res.data.products);
        })
        .catch((error) => {
          console.log(error);
        });

      instance
        .get("/barbermap/barbershop/get_payments_methods")
        .then((res) => {
          if (res.status === 200) {
            setPaymentMethods(res.data.methods);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dataUser]);

  const handleCreateProduct = async (formData) => {
    swalConfirmationAlert("¿Confirmar carga de producto?", "", () => {
      instance
        .post("/barbermap/barbershop/add_product_catalog", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            swalAlertSuccess(
              "Producto cargado",
              "Los clientes podrán visualizarlo en el catálogo",
              () => {
                window.location.reload();
              }
            );
          } else {
            if (res.status === 203) {
              swalAlertInfo(
                "Error al cargar el servicio",
                "Revise el tipo de archivo permitido para el producto",
                () => {}
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
          swalAlertDanger(
            "Error al cargar el producto",
            "Si el problema persiste, comuníquese con un administrador",
            () => {
              window.location.reload();
            }
          );
        });
    });
  };

  const handleEditProduct = async (id_product, data) => {
    instance
      .post("/barbermap/barbershop/edit_product_catalog", {
        id_product,
        data,
      })
      .then((res) => {
        if (res.status === 200) {
          swalAlertSuccess("Producto modificado", "", () => {
            window.location.reload();
          });
        }
      })
      .catch((error) => {
        console.log(error);
        swalAlertDanger(
          "Error al modificar el producto",
          "Si el problema persiste, comuníquese con un administrador",
          () => {
            window.location.reload();
          }
        );
      });
  };

  const handleDeleteProduct = async (id_product) => {
    swalConfirmationAlert(
      "¿ESTA SEGURO DE ELIMINAR ESTE PRODUCTO",
      "Esta acción no se podrá deshacer",
      () => {
        instance
          .post("/barbermap/barbershop/delete_product_catalog", {
            data: id_product,
          })
          .then((res) => {
            if (res.status === 200) {
              swalAlertSuccess(
                "Producto eliminado",
                "Los clientes ya no podrán verlo en el catálogo",
                () => {
                  window.location.reload();
                }
              );
            }
          })
          .catch((error) => {
            console.log(error);
            swalAlertDanger(
              "Error al eliminar el producto",
              "Si el problema persiste, comuníquese con un administrador",
              () => {
                window.location.reload();
              }
            );
          });
      }
    );
  };

  const handleRegisterTransaction = async (data, payment_method) => {
    let totalTransaction = 0;
    for (let i = 0; i < data.length; i++) {
      totalTransaction += data[i].price_product * data[i].quantity;
    }
    setTotalTransaction(totalTransaction);
    swalConfirmationAlert(
      `¿Confirmar transacción por $${totalTransaction}?`,
      "Podrás ver el detalle de la transacción en los reportes de facturación",
      () => {
        instance
          .post("/barbermap/barbershop/add_transaction_products", {
            data: data,
            payment_method: payment_method,
          })
          .then((res) => {
            if (res.status === 200) {
              swalAlertSuccess(
                "Transacción registrada correctamente",
                "Podrás visualizar detalles en el reporte de facturación",
                () => {
                  window.location.reload();
                }
              );
            } else {
              if (res.status === 203) {
                swalAlertInfo(
                  "Alguno de los productos seleccionados no cuenta con stock suficiente.",
                  "Verifique los datos ingresados o actualice su stock en caso de ser necesario",
                  () => {}
                );
              }
            }
          })
          .catch((error) => {
            console.log(error);
            swalAlertDanger(
              "Error al registrar la transacción",
              "Si el problema persiste comuníquese con un administrador",
              () => {
                window.location.reload();
              }
            );
          });
      }
    );
  };

  return (
    <>
      <div>
        <SidebarOwner dataBarberShop={dataBarberShop} />
      </div>
      <h3 className="text-center">ADMINISTRADOR DE CATALOGO</h3>
      <hr />
      <div className="container d-flex mt-3">
        <button
          className="btn btn-primary col-5 ms-2"
          onClick={() => {
            setShowModalCreateProduct(true);
          }}
        >
          <AddShoppingCartIcon /> AÑADIR PRODUCTO
        </button>
        <button
          className="btn btn-success col-5 ms-5"
          onClick={() => {
            setShowModalTransaction(true);
          }}
        >
          <AddShoppingCartIcon /> REGISTRAR VENTA
        </button>
      </div>
      <div className="mt-5">
        <TableProductsCatalog
          products={products}
          handleEdit={handleEditProduct}
          handleDelete={handleDeleteProduct}
          categories={categoriesProduct}
        />
      </div>
      <ModalWindow
        title={"Carga de producto"}
        body={
          <FormCatalogCreateProduct
            categories={categoriesProduct}
            sendProduct={handleCreateProduct}
          />
        }
        modalOpen={showModalCreateProduct}
        setModalOpen={setShowModalCreateProduct}
        footer={"Los clientes podrán visualizar tus productos cargados"}
      />
      <ModalWindow
        title={"Registrar venta de producto"}
        body={
          <FormCatalogRegisterTransaction
            products={products}
            handleRegisterTransaction={handleRegisterTransaction}
            paymentMethods={paymentMethods}
          />
        }
        modalOpen={showModalTransaction}
        setModalOpen={setShowModalTransaction}
        footer={
          "La transacción quedará registrada para el historial de facturación"
        }
      />
    </>
  );
};

export default AdminCatalog;

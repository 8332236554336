import React from "react";
import Sidebar from "../../components/admin/global/Sidebar";
import Administrator from "../../components/admin/components/pages/Dashboard/Administrator";
// import { UserContext } from "../../components/context/UserContext";
import { validateRole } from "../../controllers/controllers";
import { swalAlertDanger } from "../../components/common/Alerts";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useSessionCheck from "../../customHooks/useSessionCheck";
import BarbershopsPerCity from "./reports/barbershopsPerCity";

const Dashboard = () => {
  useSessionCheck();
  const dataUser = useSelector((state) => state.user.dataUser);
  const allowedRoles = [0];

  if (!validateRole(dataUser.user_rol, allowedRoles)) {
    return null;
  } else {
    return (
      <div>
        <Sidebar />
        <div className="load-barber-shop">
          <BarbershopsPerCity />
        </div>
      </div>
    );
  }
};

export default Dashboard;

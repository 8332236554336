import React, { useState, useEffect } from 'react';
import Baner from "../../assets/img/BanerGrid.svg";
import "../../styles/BanerGrid.css";

const BannerGridManagement = () => {
  const phrases = [
    "Atrae clientes en linea",
    "Ahorra tiempo y costos",
    "Mejora la experiencia del cliente",
    "Obtiene una mayor visibilidad",
    "Olvidate de medios tradicionales para gestionar tus turnos"
  ];

  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        setFade(true);
      }, 1000);
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='container-banner-grid noselect'>
      <div className='banner-grid'>
        <img src={Baner} alt="Banner"/>
        <div className={`banner-text ${fade ? 'fade-in' : 'fade-out'}`}>
          <p>{phrases[currentPhraseIndex]}</p>
        </div>
      </div>
    </div>
  );
}

export default BannerGridManagement;

import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { formatDate, formatTime } from "../../controllers/datetime";
import "../../styles/Acordeon.css";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  swalAlertDanger,
  swalAlertSuccess,
  swalConfirmationAlert,
} from "../common/Alerts";
import { useNavigate } from "react-router-dom";
import instance from "../../connection_api";
import ModalWindow from "../common/ModalWindow";
import FormShiftDone from "../barber/FormShiftDone";
import FormUnregisteredClient from "./FormUnregisteredClient";

const AcordeonBarberShifts = ({
  group,
  handleDeleteSelectedShifts,
  services,
  handleCreate,
}) => {
  const [selShifts, setSelShifts] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedShift, setSelectedShift] = useState(null);
  const [modalDone, setModalDone] = useState(false);

  const [modalReserve, setModalReserve] = useState(false);
  const [reserveShiftId, setReserveShiftId] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener métodos de pago
        const paymentMethodsResponse = await instance.get(
          "/barbermap/barbershop/get_payments_methods"
        );
        if (paymentMethodsResponse.status === 200) {
          setPaymentMethods(paymentMethodsResponse.data.methods);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    return () => {};
  }, []);

  const handleCheckboxChange = (event, shiftId) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelShifts((prevSelectedShifts) => [...prevSelectedShifts, shiftId]);
    } else {
      setSelShifts((prevSelectedShifts) =>
        prevSelectedShifts.filter((id) => id !== shiftId)
      );
    }

    const allChecked = group.shifts.every((shift) =>
      selShifts.includes(shift.id_shift)
    );
    setSelectAllChecked(allChecked);
  };

  const toggleSelectAll = () => {
    if (selectAllChecked) {
      setSelShifts([]);
    } else {
      const allShiftIds = group.shifts.map((shift) => shift.id_shift);
      setSelShifts(allShiftIds);
    }
    setSelectAllChecked(!selectAllChecked);
  };

  const statusCounts = group.shifts.reduce((counts, shift) => {
    const statusId = shift.status_id;
    counts[statusId] = (counts[statusId] || 0) + 1;
    return counts;
  }, {});

  const handleReserveClick = (shiftId) => {
    setReserveShiftId(shiftId);
    setModalReserve(true);
  };

  const handleRealizadoClick = (shift) => {
    swalConfirmationAlert(
      "¿DESEA MARCAR EL TURNO COMO REALIZADO?",
      "No podrá deshacer esta acción",
      () => {
        instance
          .post("/barbermap_barber_complete_shift", { data: shift })
          .then((res) => {
            if (res.status === 200) {
              swalAlertSuccess(`${res.data.msg}`, "", () => {
                window.location.reload();
              });
            } else {
              swalAlertDanger(
                "ERROR AL FINALIZAR EL TURNO",
                "Intentelo nuevamente más tarde",
                () => {
                  navigate("/barbermap-barber-shifts");
                }
              );
            }
          })
          .catch((error) => {
            swalAlertDanger(
              "ERROR EN EL SERVIDOR",
              "Intentelo nuevamente más tarde",
              () => {
                navigate("/barbermap-barber-shifts");
              }
            );
          });
      }
    );
  };

  return (
    <div className="container mt-4">
      <Accordion flush>
        <Accordion.Header>
          <div className="container">
            Historial {group.name} {group.lastname}
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className="card">
            <h5 className="card-title row">
              <div className="col-lg-3 mt-3">
                Turnos disponibles: {statusCounts[1] || 0}
              </div>
              <div className="col-lg-3 mt-3">
                Turnos completados: {statusCounts[4] || 0}
              </div>
              <div className="col-lg-3 mt-3">
                Turnos cancelados: {statusCounts[3] || 0}
              </div>
              <div className="col-lg-3 mt-3">
                Turnos expirados: {statusCounts[5] || 0}
              </div>
            </h5>
          </div>
          <div className="mt-3 d-flex justify-content-end">
            <button
              className="btn btn-danger"
              onClick={() => {
                handleDeleteSelectedShifts(selShifts);
              }}
              disabled={selShifts && selShifts.length === 0}
            >
              Eliminar turnos seleccionados
            </button>
          </div>
          <div>
            <table className="table table-secondary mt-3">
              <thead>
                <tr>
                  <th>Hora</th>
                  <th>Estado</th>
                  <th>Marcar listo</th>
                  <th>
                    <label
                      htmlFor="selectAllCheckbox"
                      className="custom-checkbox-icon"
                    >
                      <input
                        type="checkbox"
                        id="selectAllCheckbox"
                        onChange={toggleSelectAll}
                        checked={selectAllChecked}
                      />
                      Todos
                      <DeleteIcon />
                    </label>
                  </th>
                </tr>
              </thead>
              <tbody className="shifts-table">
                {group.shifts.map((shift) => (
                  <tr key={shift.id_shift}>
                    <div className="d-grid">
                      <td className="cuadro-hora">
                        {formatTime(shift.time_shift)}
                      </td>
                      {shift.status_id == 1 ? (
                        <button
                          className="btn btn-primary"
                          onClick={() => handleReserveClick(shift.id_shift)}
                        >
                          RESERVAR
                        </button>
                      ) : (
                        "-"
                      )}
                    </div>
                    <td>{shift.status_shift}</td>
                    <td>
                      {shift.status_id == 2 || shift.status_id == 6 ? (
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            setSelectedShift(shift.id_shift);
                            setModalDone(true);
                          }}
                        >
                          Listo
                        </button>
                      ) : (
                        <p className="text-success">-</p>
                      )}
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="cuadro-trash"
                        onChange={(e) =>
                          handleCheckboxChange(e, shift.id_shift)
                        }
                        checked={selShifts.includes(shift.id_shift)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Accordion.Body>
      </Accordion>
      {modalDone && (
        <ModalWindow
          title={"Registrar servicio finalizado"}
          setModalOpen={setModalDone}
          modalOpen={modalDone}
          body={
            <FormShiftDone
              shift={selectedShift}
              paymentMethods={paymentMethods}
              handleDone={handleRealizadoClick}
            />
          }
          footer={
            "Quedará registro del servicio realizado, podrás visualizarlo en tu historial y estadísticas."
          }
        />
      )}
      {modalReserve && (
        <ModalWindow
          title={"Reservar turno"}
          setModalOpen={setModalReserve}
          modalOpen={modalReserve}
          body={
            <FormUnregisteredClient
              services={services}
              handleCreate={handleCreate}
              shift={reserveShiftId}
            />
          }
          footer={
            "Se reservará el turno sin necesidad de que el cliente lo registre"
          }
        />
      )}
    </div>
  );
};

export default AcordeonBarberShifts;

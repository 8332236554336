import React from "react";
import "./PodiumPopularBarbershops.css";
import CopaOro from "../../../../assets/img/copaoro.png";
import CopaPlata from "../../../../assets/img/copaplata.png";
import CopaBronce from "../../../../assets/img/copabronce.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PodiumPopularBarbershops = ({ podium }) => {
  const navigate = useNavigate();
  const dataUser = useSelector((state) => state.user.dataUser);

  return (
    <div className="podium container-podium">
      <div className="podium__item">
        <p className="podium__city text-white">
          2° PUESTO <br />
          <b> {podium && podium[1] ? podium[1].name : ""}</b>
        </p>
        <img src={CopaPlata} alt="copa de oro" className="col-12"></img>
        <div className="podium__rank second text-center">
          Servicios realizados <br />
          {podium && podium[1] ? podium[1].completedShifts : ""}
        </div>
        <div className="text-center">
          <b>👍{podium && podium[1] ? podium[1].quantity_type_1 : 0}</b>
          <b className="ms-2">
            👎{podium && podium[1] ? podium[1].quantity_type_2 : 0}
          </b>
        </div>

        <button
          className="btn btn-on col-10 ms-3"
          onClick={() => {
            if (!dataUser) {
              navigate(
                `/barbershop/guest/${podium[1].id_barbershop}/${podium[1].name}`
              );
            } else {
              navigate(
                `/barbershop/${podium[1].id_barbershop}/${podium[1].name}`
              );
            }
          }}
        >
          Ver perfil
        </button>
      </div>
      <div className="podium__item">
        <p className="podium__city text-white">
          1° PUESTO <br />
          <b>{podium && podium[0] ? podium[0].name : ""}</b>
        </p>
        <img src={CopaOro} alt="copa de oro" className="col-12"></img>
        <div className="podium__rank first text-center">
          Servicios realizados <br />
          {podium && podium[0] ? podium[0].completedShifts : ""}
        </div>
        <div className="text-center">
          <b>👍{podium && podium[0] ? podium[0].quantity_type_1 : 0}</b>
          <b className="ms-2">
            👎{podium && podium[0] ? podium[0].quantity_type_2 : 0}
          </b>
        </div>

        <button
          className="btn btn-on col-10 ms-3"
          onClick={() => {
            if (!dataUser) {
              navigate(
                `/barbershop/guest/${podium[0].id_barbershop}/${podium[0].name}`
              );
            } else {
              navigate(
                `/barbershop/${podium[0].id_barbershop}/${podium[0].name}`
              );
            }
          }}
        >
          Ver perfil
        </button>
      </div>
      <div className="podium__item">
        <p className="podium__city text-white">
          3° PUESTO <br />
          <b>{podium && podium[2] ? podium[2].name : ""}</b>
        </p>
        <img src={CopaBronce} alt="copa de oro" className="col-12"></img>
        <div className="podium__rank third text-center">
          Servicios realizados <br />
          {podium && podium[2] ? podium[2].completedShifts : ""}
        </div>
        <div className="text-center">
          <b>👍{podium && podium[2] ? podium[2].quantity_type_1 : 0}</b>
          <b className="ms-2">
            👎{podium && podium[2] ? podium[2].quantity_type_2 : 0}
          </b>
        </div>
        <button
          className="btn btn-on col-10 ms-3"
          onClick={() => {
            if (!dataUser) {
              navigate(
                `/barbershop/guest/${podium[2].id_barbershop}/${podium[2].name}`
              );
            } else {
              navigate(
                `/barbershop/${podium[2].id_barbershop}/${podium[2].name}`
              );
            }
          }}
        >
          Ver perfil
        </button>
      </div>
    </div>
  );
};

export default PodiumPopularBarbershops;

import React, { useState } from "react";
import instance from "../connection_api";
import {
  swalAlertDanger,
  swalAlertInfo,
  swalAlertSuccess,
} from "../components/common/Alerts";
import { Link, useNavigate } from "react-router-dom";
import ModalWindow from "../components/common/ModalWindow";
import FormRecuperationCode from "../components/common/FormRecuperationCode";
import FormGeneratePassword from "../components/common/FormGeneratePassword";
import Image1 from "../assets/img/Logo3.png";
import "../styles/Forgot.css";
import ArrowBack from "../components/common/ArrowBack";
import Footer from "../components/footer/Footer";
const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModalChangePass, setShowModalChangePass] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      instance.post("/user_forgot_password", { email }).then((res) => {
        if (res.status === 200) {
          // Abrir el modal con el código enviado
          setShowModal(true);
        } else {
          if (res.status === 203) {
            swalAlertInfo(
              "El email ingresado no pertenece a ningún usuario de la aplicación",
              "Asegúrese que los datos ingresados son correctos.",
              () => {}
            );
          } else {
            swalAlertDanger(
              "Error al enviar el código",
              "Intentelo nuevamente más tarde",
              () => {
                navigate("/login");
              }
            );
          }
        }
      });
    } catch (error) {
      swalAlertDanger(
        "Ocurrió un error al procesar la solicitud",
        "Intentelo nuevamente más tarde",
        () => {
          navigate("/login");
        }
      );
    }
  };

  const handleCode = (recovery_code) => {
    instance
      .post("/user_verification_code", { recovery_code, email })
      .then((res) => {
        if (res.status === 200) {
          //Codigo correcto, mostrar modal para cambio de clave.
          setShowModal(false);
          setShowModalChangePass(true);
        } else {
          if (res.status === 203) {
            swalAlertInfo("Código incorrecto", "Intentelo nuevamente");
          } else {
            swalAlertDanger(
              "Error al procesar el código de recuperación",
              "",
              () => {
                navigate("/login");
              }
            );
          }
        }
      })
      .catch((error) => {
        swalAlertDanger(
          "Error al intentar realizar la validación del código",
          "Intente nuevamente más tarde",
          () => {
            navigate("/login");
          }
        );
      });
  };

  const handleChangePass = (email, newPassword) => {
    instance
      .post("/validate_user_change_forgot_pass", { email, newPassword })
      .then((res) => {
        if (res.status === 200) {
          swalAlertSuccess(
            "Contraseña generada con éxito",
            "Inicie sesión con su nueva clave",
            () => {
              navigate("/login");
            }
          );
        } else {
          swalAlertInfo(
            "La contraseña no cumple con los requisitos establecidos",
            "La clave debe contener al menos una mayúscula, una minúscula, un dígito y al menos 8 caracteres",
            () => {}
          );
        }
      })
      .catch((error) => {
        swalAlertDanger(
          "Error al procesar la solicitud de cambio de clave",
          "Intentelo nuevamente más tarde",
          () => {
            navigate("/login");
          }
        );
      });
  };

  return (
    <div>
      <div className="container container-forgot">
        <ArrowBack />
        <div className="mt-3 card card-forgot">
          <img src={Image1} alt="barber-image" className="image-forgot"></img>
          <h1>RECUPERAR CONTRASEÑA</h1>
          <form onSubmit={handleSubmit}>
            <div className="form-group form-input-text">
              <input
                placeholder="example@gmail.com"
                type="email"
                id="email"
                className="form-control form-input-text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="cont-btn-enviar">
              <button type="submit" className="btn btn-on  btn-modal-enviar">
                ENVIAR CODIGO
              </button>
            </div>
          </form>
          <h4>¿Ya tienes contraseña?</h4>
          <Link to="/login" className="link-login">
            <p>Logueate aquí</p>
          </Link>
        </div>
      </div>
      <ModalWindow
        className="modal-insert"
        title={
          "Inserte el código de recuperación enviado a su correo electrónico"
        }
        body={<FormRecuperationCode handleCode={handleCode} />}
        setModalOpen={setShowModal}
        modalOpen={showModal}
        footer={"Si no puede visualizar el correo, revise la casilla de SPAM"}
      />

      <ModalWindow
        title={"Generación de nueva clave"}
        body={
          <FormGeneratePassword
            handleChangePass={handleChangePass}
            email={email}
          />
        }
        setModalOpen={setShowModalChangePass}
        modalOpen={showModalChangePass}
        footer={
          "Un miembro del equipo de Barber Map jamás le solicitará su clave."
        }
      />
      <Footer />
    </div>
  );
};

export default ForgotPassword;

import React, { useState } from "react";

const FormShiftDone = ({ shift, paymentMethods, handleDone }) => {
  const [selectedMethod, setselectedMethod] = useState(null);

  const handleMethodChange = (e) => {
    setselectedMethod(e.target.value);
  };

  const handleData = async () => {
    const data = {
      shift,
      selectedMethod,
    };
    handleDone(data);
  };

  return (
    <>
      <form>
        <div className="form-group">
          <select
            id="payment_method"
            name="payment_method"
            className="form-control"
            onChange={handleMethodChange}
            required
          >
            <option value="">Selecciona método de pago utilizado</option>
            {paymentMethods.map((method) => (
              <option
                key={method.id_payment_method}
                value={method.id_payment_method}
              >
                {method.payment_method_description}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group mt-4">
          <button
            type="submit"
            className="btn btn-left"
            onClick={(e) => {
              e.preventDefault();
              handleData();
            }}
          >
            GUARDAR
          </button>
        </div>
      </form>
    </>
  );
};

export default FormShiftDone;

import React, { useState, useEffect } from "react";
import instance from "../../../../../connection_api";
import {
  swalAlertDanger,
  swalAlertInfo,
  swalAlertSuccess,
} from "../../../../common/Alerts";

const FormEditServices = ({ barbershop }) => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    instance
      .post("/barbermap/admin_metacode/get_services_by_barbershop", {
        data: barbershop.id_barbershop,
      })
      .then((res) => {
        setServices(res.data.services);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [barbershop]);

  const handleServiceChange = (index, field, value) => {
    const updatedServices = [...services];
    updatedServices[index][field] = value;
    setServices(updatedServices);
  };

  const handleSaveChanges = () => {
    instance
      .post("/barbermap/admin_metacode/edit_services_barbershop", {
        data: services,
      })
      .then((res) => {
        if (res.status === 200) {
          swalAlertSuccess("Servicios actualizados", "", () => {
            window.location.reload();
          });
        }
      })
      .catch((error) => {
        console.log(error);
        swalAlertDanger("Error al actualizar la informacion", "", () => {});
      });
  };

  return (
    <form className="container mt-4">
      <table className="table table-secondary table-bordered">
        <thead>
          <tr>
            <th>ID</th>
            <th>Descripción</th>
            <th>Precio</th>
            <th>Duración</th>
          </tr>
        </thead>
        <tbody>
          {services && services.length > 0
            ? services.map((service, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="text"
                      value={service.id_service}
                      disabled
                      className="form-control"
                      onChange={(e) =>
                        handleServiceChange(index, "id_service", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={service.service_description}
                      className="form-control"
                      onChange={(e) =>
                        handleServiceChange(
                          index,
                          "service_description",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={service.service_price}
                      className="form-control"
                      onChange={(e) =>
                        handleServiceChange(
                          index,
                          "service_price",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={service.service_duration}
                      className="form-control"
                      onChange={(e) =>
                        handleServiceChange(
                          index,
                          "service_duration",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
              ))
            : "Mostrar para cargar servicios?"}
        </tbody>
      </table>
      <button
        type="button"
        className="btn btn-success col-12 mt-3"
        onClick={handleSaveChanges}
      >
        Guardar cambios
      </button>
    </form>
  );
};

export default FormEditServices;

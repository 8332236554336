import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const ChartBarbershopsByCity = ({ data }) => {
  const options = {
    chart: {
      type: "pie",
    },
    title: {
      text: "Barberías por provincia",
    },
    series: [
      {
        name: "Cities",
        data: data.map((barbershop) => ({
          name: barbershop.city_description,
          y: barbershop.total_barbershops,
        })),
      },
    ],
  };
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options}/>
    </div>
  );
};

export default ChartBarbershopsByCity;

import React from "react";
import Sidebar from "../../components/admin/global/Sidebar";
import ConsultPayments from "../../components/admin/components/pages/ConsultPayments/ConsultPayments";
import useSessionCheck from "../../customHooks/useSessionCheck";

const ConsultPayPage = () => {
  useSessionCheck();
  return (
    <div>
        <Sidebar />
      <div className="load-barber-shop">
        <ConsultPayments />
      </div>
    </div>
  );
};

export default ConsultPayPage;

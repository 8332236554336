import React, { useEffect, useState } from "react";
import NavbarTop from "../../components/nav-bottom/NavTop";
import "../../styles/MainPage.css";
import MapReview from "../../components/map/MapReview";
import instance from "../../connection_api";
import Logo4 from "../../assets/img/Logo4.png";

const MapUser = () => {
  const [barbershops, setBarbershops] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get("/barbermap_get_barbershops");
        const responseCities = await instance.get("/getCities");

        setBarbershops(response.data.barberShops);
        setIsLoading(false);
        setCities(responseCities.data.cities);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setIsLoading(false);
      }
    };

    if (barbershops.length === 0) {
      fetchData();
    }
  }, [barbershops]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleProvinceChange = (event) => {
    const selectedProvinceId = event.target.value;
    setSelectedProvince(selectedProvinceId);
  };
  
  const filteredBarberShops = barbershops.filter((barberShop) => {
    const selectedProvinceLower = selectedProvince.toLowerCase();
    const matchesProvince =
      selectedProvince === "" || barberShop.city.toString() === selectedProvinceLower;
  
    const searchTermLower = searchTerm.toLowerCase();
    const matchesSearchTerm =
      barberShop.name.toLowerCase().includes(searchTermLower) ||
      barberShop.address.toLowerCase().includes(searchTermLower);
  
    return matchesProvince && matchesSearchTerm;
  });

  if (isLoading) {
    return (
      <>
        <div className="container barber-shop-details-page bg-light text-center p-4 mt-5 rounded">
          <div className="loading-container">
            <img src={Logo4} alt="Loading" className="loading-image" />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="container barber-shop-details-page container-nav">
        <NavbarTop />
      </div>
      <div className="mt-3 d-flex nav-city bg-dark">
        <nav className="navbar navbar-dark bg-dark">
          <form className="form-inline">
            <div className="row">
              <div className="col-md-6 background-text-search-bar">
                <input
                  type="text"
                  className="text-search-bar"
                  placeholder="Buscar barberia..."
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
            </div>
          </form>
        </nav>
        
        <select
          value={selectedProvince}
          onChange={handleProvinceChange}
          className="form-city"
        >
          <option value="">Seleccionar provincia</option>
          {cities.map((city) => (
            <option key={city.id_city} value={city.id_city}>
              {city.city_description}
            </option>
          ))}
        </select>
      </div>
      <div className="map">
        <MapReview barberShops={filteredBarberShops} />
      </div>
    </>
  );
};

export default MapUser;

import React, { useEffect, useState } from "react";
import instance from "../../connection_api";
import { swalAlertInfo, swalAlertDanger } from "../common/Alerts";
import { useNavigate } from "react-router-dom";

const FormService = ({ services, shift, handleReserve }) => {
  const [filteredServices, setFilteredServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    instance
      .post("/barbermap_barbershop_verify_disponibility", { services, shift })
      .then((res) => {
        setFilteredServices(res.data.services);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [shift]);

  const handleServiceChange = (e) => {
    setSelectedService(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (selectedService) {
      handleReserve(shift, selectedService);
    } else {
      swalAlertInfo("Por favor, seleccione un servicio", "", () => {});
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="form-group">
        <select
          id="serviceSelect"
          className="form-control"
          onChange={handleServiceChange}
          required
        >
          <option value="">Selecciona un servicio</option>
          {filteredServices.map((service) => (
            <option
              key={service.id_service}
              value={service.id_service}
              disabled={!service.disponibility}
            >
              {service.service_description} (${service.service_price})
            </option>
          ))}
        </select>
      </div>
      <div className="form-group mt-4">
        <button type="submit" className="btn btn-left">
          RESERVAR
        </button>
      </div>
    </form>
  );
};

export default FormService;

import React, { useState } from "react";

const FormEditProduct = ({ product, handleEdit, categories }) => {
  const [editedProduct, setEditedProduct] = useState({
    title_product: product.title_product,
    description_product: product.description_product,
    price_product: product.price_product,
    id_category: product.category_product,
    quantity: product.quantity,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleSaveChanges = () => {
    handleEdit(product.id_product, editedProduct);
  };

  return (
    <form>
      <div className="mb-3">
        <label htmlFor="title_product" className="form-label">
          Título del Producto
        </label>
        <input
          type="text"
          className="form-control"
          id="title_product"
          name="title_product"
          value={editedProduct.title_product}
          onChange={handleInputChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="description_product" className="form-label">
          Descripción del Producto
        </label>
        <textarea
          className="form-control"
          id="description_product"
          name="description_product"
          value={editedProduct.description_product}
          onChange={handleInputChange}
        ></textarea>
      </div>
      <div className="mb-3">
        <label htmlFor="price_product" className="form-label">
          Precio del Producto
        </label>
        <input
          type="number"
          className="form-control"
          id="price_product"
          name="price_product"
          value={editedProduct.price_product}
          onChange={handleInputChange}
        />
      </div>

      <div className="mb-3">
        <label htmlFor="quantity" className="form-label">
          Stock actual
        </label>
        <input
          type="number"
          className="form-control"
          id="quantity"
          name="quantity"
          value={editedProduct.quantity}
          onChange={handleInputChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="id_category" className="form-label">
          Categoría del Producto
        </label>
        <select
          className="form-control"
          id="id_category"
          name="id_category"
          value={editedProduct.id_category}
          onChange={handleInputChange}
        >
          {categories.map((category) => (
            <option key={category.id_category} value={category.id_category}>
              {category.category_description}
            </option>
          ))}
        </select>
      </div>
      <button
        type="button"
        className="btn btn-success mt-3"
        onClick={handleSaveChanges}
      >
        Guardar Cambios
      </button>
    </form>
  );
};

export default FormEditProduct;

import React, { useState } from "react";
import { connect } from "react-redux";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import LogoAdmin from "../../assets/img/Logo4.png";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import HomeIcon from "@mui/icons-material/Home";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ScheduleIcon from "@mui/icons-material/Schedule";
import LogoutIcon from "@mui/icons-material/Logout";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CollectionsIcon from "@mui/icons-material/Collections";
import StarHalfIcon from '@mui/icons-material/StarHalf';
import "../../styles/NavbarOwnerBarber.css";
import { logout } from "../redux/userActions";
import { useSelector } from "react-redux";

const SidebarBarber = ({ barber, logout }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const dataUser = useSelector((state) => state.user.dataUser);

  return (
    <div>
      <Navbar collapseOnSelect expand="lg" className="menu-owner">
        <Container>
          <div className="div-sidebar">
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              <MenuOutlinedIcon />
            </Navbar.Toggle>

            <Link to="/barbermap-barber" className="nav-link">
              <Navbar.Brand>
                <Box display="flex" alignItems="center" ml="15px">
                  <h3 color="white" className="title-owner">
                    BARBER MAP
                  </h3>
                </Box>
              </Navbar.Brand>
            </Link>

            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                alt=""
                width="50px"
                height="50px"
                src={LogoAdmin}
                style={{ cursor: "pointer", borderRadius: "50%" }}
              />
            </Box>
          </div>

          <Navbar.Collapse id="responsive-navbar-nav">
            <div>
              {!isCollapsed && (
                <Box mb="25px">
                  <Box textAlign="center">
                    <Typography
                      variant="h10"
                      color="white"
                      fontWeight="bold"
                      sx={{ m: "10px 0 0 0" }}
                    >
                      <div className="div-name">
                        Barbero
                        {barber && barber !== undefined ? (
                          <h3 className="name-barber">
                            {barber.name} {barber.lastname}
                          </h3>
                        ) : (
                          ""
                        )}
                      </div>
                    </Typography>
                    <Typography variant="h6" color="black"></Typography>
                  </Box>
                </Box>
              )}
            </div>

            <Nav className="ms-auto">
              <Link
                to="/barbermap-barber"
                className="nav-link item-navbar-owner"
              >
                <HomeIcon className="icon-svg" />
                Inicio
              </Link>

              <Link to="/profile" className="nav-link item-navbar-owner">
                <AccountBoxIcon className="icon-svg" />
                Mi perfil
              </Link>

              <Link
                to="/barbermap-barber-shifts"
                className="nav-link item-navbar-owner"
              >
                <CalendarMonthIcon className="icon-svg" />
                Mis turnos
              </Link>

              <Link
                to="/barbermap-barber-history"
                className="nav-link item-navbar-owner"
              >
                <ScheduleIcon className="icon-svg" />
                Mi historial
              </Link>

              <Link
                to={`/barbermap/barbershop/gallery/barber/${dataUser.id_user}`}
                className="nav-link item-navbar-owner"
              >
                <CollectionsIcon className="icon-svg" />
                Galeria de fotos
              </Link>
              <Link
                to="/ranking-barbershop"
                className="nav-link item-navbar-owner"
              >
                <StarHalfIcon className="icon-svg" />
                Ranking
              </Link>    
              <Link
                to="/login"
                onClick={logout}
                className="nav-link item-navbar-owner-logout"
              >
                <LogoutIcon className="icon-svg" />
                Cerrar Sesión
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(null, mapDispatchToProps)(SidebarBarber);

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
const VerticalTab = () => {
  return (
    <div className="background-body noselect">
      <h2 className="text-center h2-tittle-white mt-3 mb-5">Preguntas frecuentes</h2>
      <div class="accordion" id="accordionPanelsStayOpenExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="panelsStayOpen-headingOne">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseOne"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapseOne"
            >
              ¿Qué datos necesitas para registrarte?
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="panelsStayOpen-headingOne"
          >
            <div class="accordion-body">
              <strong>Para registrarte deberás: </strong> Ingresar tu nombre,
              apellido, correo electrónico, ciudad y teléfono. Estos datos son
              solicitados para mejorar la experiencia del usuario.
            </div>
            <strong className="ms-3">
              Tutorial para registrarse:
              <Link to="https://youtu.be/CUMtmY6v45w">Haz click aquí</Link>
            </strong>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseTwo"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseTwo"
            >
              ¿Cómo reservar un turno?
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="panelsStayOpen-headingTwo"
          >
            <div class="accordion-body">
              <p>1 - Primero deberás iniciar sesión.</p>
              <p>
                2 - Busca tu barbería en el mapa o en el apartado de
                'Barberías'.
              </p>
              <p>
                3 - Dirígete a 'Ver turnos' y selecciona tu barbero de
                preferencia.
              </p>
              <p>4 - Elige la fecha y horario que quieras</p>
              <p>5 - Haz click en reservar y listo!</p>
            </div>
            <strong className="ms-3">
              Tutorial para sacar turno:
              <Link to="https://youtu.be/au_SnBH0dcE">Haz click aquí</Link>
            </strong>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="panelsStayOpen-headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseThree"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseThree"
            >
              ¿Qué hago si quiero cancelar un turno?
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="panelsStayOpen-headingThree"
          >
            <div class="accordion-body">
              <strong>Para cancelar un turno</strong> deberás dirigirte una vez
              logueado al apartado de 'Mis Turnos', darle click al botón de
              cancelar y luego confirmar.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="panelsStayOpen-headingFour">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseFour"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseFour"
            >
              ¿La reserva de turnos es instantánea?
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseFour"
            class="accordion-collapse collapse"
            aria-labelledby="panelsStayOpen-headingFour"
          >
            <div class="accordion-body">
              <strong>Sí, la reserva es instantánea.</strong> Una vez reservado
              el turno, el barbero va a poder visualizarlo en su agenda.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="panelsStayOpen-headingFive">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseFive"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseFive"
            >
              ¿Cuánto sale la app por mes?
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseFive"
            class="accordion-collapse collapse"
            aria-labelledby="panelsStayOpen-headingFive"
          >
            <div class="accordion-body">
              Puedes visualizar nuestros planes{" "}
              <Link to="/salon-management-software-prices">
                <strong>aquí</strong>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalTab;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../styles/BannerManagement.css";
import Image1 from "../../assets/img/Function.png";
import Image2 from "../../assets/img/Function4.png";
import Image3 from "../../assets/img/Function5.png";
import Image4 from "../../assets/img/Function3.png";

const BannerInicio = () => {
  return (
    <section
      className="banner-inicio background-banner-functions noselect mt-5"
      id="banner-gestion"
    >
      <Container className="container-banner-image mt-5">
        <Row className="align-items-center col-banner-img">
          <Col>
            <span className="tagline mt-5">AUTOMATIZACIÓN DE RESERVAS</span>
            <h1>
              {"OPTIMIZA TU NEGOCIO Y AGENDA"}{" "}
              <span className="title-important">{"100% ONLINE"}</span>
              <h1>
                <span className="wrap d-none">Software para peluquerías</span>
              </h1>
            </h1>
            <p className="banner-inicio-gestion">
              Sin importar desde donde se contacten, sus clientes podrán
              consultar la disponibilidad en tiempo real y reservar con un sólo
              click. Sin necesidad de que una persona recepcione la reserva.{" "}
            </p>
            <p>
              Evita la superposición de turnos y maximiza la productividad de tu
              negocio
            </p>
          </Col>
          <Col>
            <img src={Image1} alt="GESTION BARBER" className="banner-img" />
          </Col>
        </Row>
      </Container>
      <hr className="hr-line container" />

      <Container className="container-banner-image">
        <Row className="align-items-center col-banner-img">
          <Col className="col-banner-img">
            <img src={Image2} alt="GESTION BARBER" className="banner-img" />
          </Col>
          <Col>
            <h2>
              <span className="tagline">MARKETING LOCAL</span>
            </h2>
            <h1>
              {"AUMENTA TU"}{" "}
              <span className="title-important">{"VISIBILIDAD"}</span>
              <span className="wrap"></span>
            </h1>
            <p>
              Con BarberMap tenes presencia en linea, especialmente con el
              sistema de mapas, aumenta tu visibilidad y atrae nuevos clientes
              que pueden descubrir tu ubicacion y servicios ofrecidos
            </p>
          </Col>
        </Row>
      </Container>
      <hr className="hr-line container" />
      <Container className="container-banner-image">
        <Row className="align-items-center col-banner-img">
          <Col>
            <h2>
              <span className="tagline">EFICIENCIA OPERATIVA</span>
            </h2>
            <h1>
              {"AHORRA"} <span className="title-important">{"TIEMPO"}</span>{" "}
              {"Y"} <span className="title-important">{"COSTOS"}</span>
              <span className="wrap"></span>
            </h1>
            <p className="banner-inicio-gestion">
              {" "}
              <span className="destacado">Ahorra tiempo</span> con la
              automatizacion de tareas administrativas, como la programacion
              manual de citas, ahorra tiempo para dedicarlas a otras partes del
              negocio
            </p>
            <p>
              <span className="destacado">Ahorra costos</span> a largo plazo a
              través de la eficiencia operativa, la retencion y fidelización de
              los clientes
            </p>
          </Col>
          <Col className="col-banner-img">
            <img src={Image3} alt="GESTION BARBER" className="banner-img" />
          </Col>
        </Row>
      </Container>
      <hr className="hr-line container" />
      <Container className="container-banner-image">
        <Row className="align-items-center col-banner-img">
          <Col className="col-banner-img">
            <img src={Image4} alt="GESTION BARBER" className="banner-img" />
          </Col>
          <Col>
            <h2>
              <span className="tagline">FACIL Y RAPIDO</span>
            </h2>
            <h1>
              {"MEJORA LA"}{" "}
              <span className="title-important">
                {"EXPERIENCIA DEL CLIENTE"}
              </span>
              <span className="wrap"></span>
            </h1>
            <p>
              La posibilidad de reservas en linea y acceder a servicios
              personalizados contribuye a una experiencia del cliente mejorada,
              lo que puede traducirse en una percepcion mas positiva del negocio
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BannerInicio;

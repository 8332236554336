import React, { useEffect, useState } from "react";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import instance from "../../connection_api";
import { ProgressBar } from "react-bootstrap";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PaymentsIcon from '@mui/icons-material/Payments';
import PeopleIcon from '@mui/icons-material/People';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import CommentIcon from "@mui/icons-material/Comment";

const CardStats = ({ totalBilling, billingByPaymentMethod, servicesByBarber, servicesByService, totalBillingByBarber }) => {
  const [totalLikes, setTotalLikes] = useState({});
  const [totalServicesDay, setTotalServicesDay] = useState({});
  const [totalComments, setTotalComments] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const cardContent = Object.entries(billingByPaymentMethod || {}).map(
    ([key, value]) => (
      <div key={key} className="payment-method">
        <p>{key}: ${value}</p>
      </div>
    )
  );

  const servicebyBarber = Object.entries(servicesByBarber || {}).map(
    ([key, value]) => (
      <div key={key} className="service-by-barber">
        <p>{key}: {value} servicios</p>
      </div>
    )
  )

  const billingByBarberComponent = Object.entries(totalBillingByBarber || {}).map(
    ([key, value]) => (
      <div key={key} className="payment-method">
        <p>{key}: ${value}</p>
      </div>
    )
  );


  const options = {
    chart: {
      type: "pie",
    },
    title: {
      text: "Tipos de Servicios realizados",
    },
    series: [
      {
        name: "Cantidad",
        colorByPoint: true,
        data: Object.entries(servicesByService || {}).map(([name, quantity]) => ({
          name,
          y: quantity,
        })),
      },
    ],
  };
  
  useEffect(() => {
    const fetchData = async () => {
      const reports = await instance.get(
        "/barbermap/ratings/comments_by_barbershop"
      );
      setTotalLikes(reports.data.quantity_rating[0].like_comment);
      setTotalServicesDay(reports.data.completed_shifts_day[0].counter);
      setTotalComments(reports.data.quantity_comments[0].counter);
      setIsLoading(false);
    };
    fetchData();
  }, []);
  console.log(totalBillingByBarber)

  if (isLoading) {
    return (
      <>
        <div className="container bg-light p-4 mt-5 rounded">
          <div className="">Cargando...</div>
          <ProgressBar animated now={100} />
        </div>
      </>
    );
  }
  return (
    <div>
      <div className="container-card-stats">
        <div className="card card-chart-admin">
          <div className="container-inside-chart-admin">
            <h3>$ {totalBilling}</h3>
            <AttachMoneyIcon className="icon-chart-admin" />
          </div>
          <div className="inside-bot-chart-admin">
            <p>Total generado</p>
          </div>
        </div>

        <div className="card card-chart-admin">
          <div className="container-inside-chart-admin">
            <h3>{totalServicesDay}</h3>
            <ContentCutIcon className="icon-chart-admin" />
          </div>
          <div className="inside-bot-chart-admin">
            <p>Servicios del dia realizados</p>
          </div>
        </div>

        <div className="card card-chart-admin">
          <div className="container-inside-chart-admin">
            <h3>{totalLikes}</h3>
            <ThumbUpIcon className="icon-chart-admin" />
          </div>
          <div className="inside-bot-chart-admin">
            <p>Cantidad de likes</p>
          </div>
        </div>

        <div className="card card-chart-admin">
        <div className="container-inside-chart-admin">
          <h3>{totalComments}</h3>
          <CommentIcon className="icon-chart-admin" />
        </div>
        <div className="inside-bot-chart-admin">
          <p>Cantidad de comentarios</p>
        </div>
      </div>
      </div>




      <div className="container-card-stats">

        <div className="card card-chart-admin">
          <div className="container-inside-chart-admin">
            <h4>{cardContent}</h4>
            <PaymentsIcon className="icon-chart-admin" />
          </div>
          <div className="inside-bot-chart-admin">
            <p className="bottom-text">Método de pago</p>
          </div>
        </div>

        <div className="card card-chart-admin">
          <div className="container-inside-chart-admin">
            <h4>{servicebyBarber}</h4>
            <PeopleIcon className="icon-chart-admin" />
          </div>
          <div className="inside-bot-chart-admin">
            <p className="bottom-text">Servicios realizados por barbero</p>
          </div>
        </div>

        <div className="card card-chart-admin">
          <div className="container-inside-chart-admin">
            <h4>{billingByBarberComponent}</h4>
            <PeopleIcon className="icon-chart-admin" />
          </div>
          <div className="inside-bot-chart-admin">
            <p className="bottom-text">Facturación por barbero</p>
          </div>
        </div>

        <div className="card card-chart-admin-graph">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>


      </div>

    </div>
  );
};

export default CardStats;

import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import instance from "../../connection_api";
import BarberShopDetails from "../../components/barber-owner/BarberShopDetails";
import BarberCard from "../../components/barber-owner/BarberCard";
import Navbar from "../../components/nav-bottom/NavTop";
import FlechaAtras from "../../components/common/ArrowBack";
import FormBarbershopComments from "../../components/barber-owner/FormBarbershopComments";
import BarberShopComments from "../../components/barber-owner/BarberShopComments";
import {
  swalAlertDanger,
  swalAlertInfo,
  swalAlertInfoOrange,
  swalAlertSuccess,
} from "../../components/common/Alerts";
import { useSelector } from "react-redux";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const BarberShop = () => {
  const dataUser = useSelector((state) => state.user.dataUser);
  const { id, name } = useParams();
  const [barberShopData, setBarberShopData] = useState([]);
  const [barbers, setBarbers] = useState([]);
  const [comments, setComments] = useState([]);
  const [barbershopAuth, setBarberShopAuth] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    instance
      .get(`/barbershop/${id}/${name}`)
      .then((response) => {
        setBarberShopData(response.data.response.dataBarberShop);
        setBarbers(response.data.response.dataBarbers);
        setComments(response.data.response.dataComments);
      })
      .catch((error) => {
        console.error(error);
        swalAlertInfo(
          "Para poder registrar su turno, inicie sesión",
          "",
          () => {
            navigate(`/login/barbershop/${id}/${name}`);
          }
        );
      });

    if (id !== undefined) {
      const fetchData = async () => {
        const responseDataBS = await instance.get(
          `/data_barbershop_owner/${id}`
        );
        setBarberShopAuth(responseDataBS.data.dataBarberShop[0].req_auth);
      };
      fetchData();
    }
  }, [id, name, dataUser, navigate]);

  useEffect(() => {
    if (barbershopAuth === 1 && !dataUser) {
      navigate(`/login/barbershop/${id}/${name}`);
      setBarberShopAuth(null);
    }
  }, [barbershopAuth, id, name, navigate, dataUser]);

  const sendComment = (data) => {
    instance
      .post("/barbermap_client_send_comment", {
        data: data,
        id_user: dataUser.id_user,
        id_barbershop: id,
      })
      .then((res) => {
        if (res.status === 200) {
          swalAlertSuccess(
            "COMENTARIO ENVIADO",
            "¡Gracias por tu valoración!",
            () => {
              window.location.reload();
            }
          );
        } else {
          swalAlertInfoOrange(
            "UPS...",
            "Ocurrió un error al recibir el comentario. Intentelo nuevamente más tarde",
            () => {
              navigate("/user-management");
            }
          );
        }
      })
      .catch((error) => {
        swalAlertDanger(
          "ERROR AL CARGAR",
          "Intentelo nuevamente más tarde",
          () => {
            navigate("/user-management");
          }
        );
      });
  };

  return (
    <div className="barber-shop-details-page">
      <div className="navbar-top-details">
        <Navbar />
      </div>
      <div className="container">
        <div className="arrow-back-details">
          <FlechaAtras />
        </div>
        {barberShopData ? (
          <div>
            <div className="row">
              <div>
                <BarberShopDetails barberShopData={barberShopData} />
              </div>
              <div>
                <button className="btn btn-primary ms-3">
                  <Link
                    to={`/catalog-barbershop/${id}/${name}`}
                    className="nav-link navtext"
                  >
                    <ShoppingCartIcon className="icon-svg" />
                    Catálogo de productos
                  </Link>
                </button>
              </div>
              <div>
                <BarberCard barbers={barbers} />
              </div>
            </div>
          </div>
        ) : (
          <p>Cargando datos...</p>
        )}
        <div className="mt-3 container">
          <FormBarbershopComments sendComment={sendComment} />
        </div>
        <div className="mt-3 container">
          <BarberShopComments comments={comments} />
        </div>
      </div>
    </div>
  );
};

export default BarberShop;

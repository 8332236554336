import React from "react";
import Logo from "../../assets/img/Logo3.png";
import Instagram from "../../assets/img/ig2.png";
import Linkedin from "../../assets/img/linkedin.png";
import TikTok from "../../assets/img/tiktok.png";
import "../../styles/FooterStyle.css";
import { FaWhatsapp } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { Link } from "react-router-dom";
export const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer>
      <section className="footer-top-wrapper noselect">
        <section className="footer-top">
          <div className="logo-footer">
            <img src={Logo} alt="Logo Simcor" />
          </div>
          <div className="support">
            <h6>Soporte online</h6>
            <a
              href="https://wa.me/3512035657?text=Hola!%20Quiero%20comunicarme%20con%20Barber%20Map"
              className="icon-footer"
            >
              <FaWhatsapp />
            </a>
            <a href="mailto:soporte@metacode.com" className="icon-footer">
              <AiOutlineMail />
            </a>
          </div>
        </section>
        <section>
          <menu className="menu-footer2">
            <h6 className="text-center">Redes sociales</h6>
            <div className="d-flex">
            <a
              className="icon-footer-social"
              href="https://www.instagram.com/metacodeargentina/"
            >
              <img src={Instagram} alt="SocialIcon" />
            </a>
            <a
              className="icon-footer-social"
              alt="SocialIcon"
              href="https://www.linkedin.com/company/metacodearg/"
            >
              <img src={Linkedin} />
            </a>
            <a
              className="icon-footer-social"
              alt="SocialIcon"
              href="https://www.tiktok.com/@metacodearg?lang=es"
            >
              <img src={TikTok} />
            </a>
            </div>
          </menu>
        </section>

        <section>
          <menu className="menu-footer">
            <h2>Descubre Barber Map</h2>
            <Link to="privacy-policy" className="span-text text-center">
              <p>Políticas de privacidad</p>
            </Link>
          </menu>
        </section>

        <section className="footer-bottom">
          <small>
            &copy; {year} Derechos de autor. Todos los derechos reservados.
          </small>
        </section>
      </section>
    </footer>
  );
};

export default Footer;

import React from 'react';
import { BsCalendarCheck } from "react-icons/bs"
import Baner from "../../assets/img/BanerGridAd.svg"
import "../../styles/BanerGrid.css";
import { Link } from 'react-router-dom';

const PricesAction = () => { 
      return (
        <div className='container-banner-grid noselect mb-5'>
          <div className='banner-grid'>
            <img src={Baner} alt="Banner" />
              <div className='banner-text-2'>
                <BsCalendarCheck className="icon-grid-gestion" />
                <p className='grid-prices'>5000$/mes</p>
                <p className='grid-prices'>Probalo por 2 semanas gratis y unite a la digitalizacion</p>
                  <div className='banner-grid-button'>
                    <Link to="/">
                      <button>Probrar gratis</button>
                    </Link>
                    <Link to="/salon-management-software-prices">
                      <button>Ver Planes y Precios</button>
                    </Link>
                  </div>
              </div>
          </div>
        </div>
      );
    }

export default PricesAction;

import React, { useEffect, useState } from "react";
import { validateRole } from "../../controllers/controllers";
import instance from "../../connection_api";
import { useSelector } from "react-redux";
import SidebarOwner from "../../components/barber-owner/SidebarOwner";
import useSessionCheck from "../../customHooks/useSessionCheck";
import ButtonsDash from "../../components/barber-owner/ButtonsDash";
import CardStats from "../../components/barber-owner/CardsStats";
import { swalAlertDanger } from "../../components/common/Alerts";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/Footer";

const DashboardBarberOwner = () => {
  useSessionCheck();
  const dataUser = useSelector((state) => state.user.dataUser);
  const [dataBarberShop, setDataBarberShop] = useState([]);
  const [nextShifts, setNextShifts] = useState([]);
  const [services, setServices] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [barbershopMember, setBarbershopMember] = useState(null);
  const allowedRoles = [1];
  const navigate = useNavigate();
  const [totalBilling, setTotalBilling] = useState(null);
  const [billingByPaymentMethod, setbillingByPaymentMethod] = useState([]);
  const [servicesByBarber, setservicesByBarber] = useState([]);
  const [servicesByService, setservicesByService] = useState([]);
  const [totalBillingByBarber, setTotalBillingByBarber] = useState([]);

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // Los meses en JavaScript son de 0 a 11
  const currentYear = currentDate.getFullYear();

  const [selectedMonth, setSelectedMonth] = useState(currentMonth.toString());
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());
  const [fetchButtonClicked, setFetchButtonClicked] = useState(false);

  const monthNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const generateMonthOptions = () => {
    return monthNames.map((month, index) => (
      <option key={index + 1} value={(index + 1).toString()}>
        {month}
      </option>
    ));
  };
  const generateOptions = (start, end) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push(
        <option key={i} value={i.toString()}>
          {i}
        </option>
      );
    }
    return options;
  };

  useEffect(() => {
    if (dataUser) {
      instance
        .get(`/data_barbershop_owner/${dataUser.id_barbershop_owner}`)
        .then((response) => {
          setDataBarberShop(response.data.dataBarberShop);
        })
        .catch((error) => {
          console.log(error);
        });

      instance
        .get("/barbermap/barber/shiftBoard")
        .then((res) => {
          setNextShifts(res.data.nextShifts);
        })
        .catch((error) => {
          console.log(error);
        });

      instance
        .get("/barbermap/barber/barbershop_member")
        .then((res) => {
          if (res.status === 200) {
            if (res.data.id_barbershop[0].id_barbershop !== "") {
              setBarbershopMember(res.data.id_barbershop[0].id_barbershop);
            } else {
              setBarbershopMember(dataUser.id_barbershop_owner);
            }
          } else {
            setBarbershopMember(dataUser.id_barbershop_owner);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      instance
        .get("/barbermap/barbershop/get_payments_methods")
        .then((res) => {
          if (res.status === 200) {
            setPaymentMethods(res.data.methods);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dataUser]);
  let linkBarber;
  if (dataBarberShop && dataBarberShop.length > 0) {
    linkBarber = `barbermap.com.ar/#/barbershop/${dataBarberShop[0].id_barbershop}/${dataBarberShop[0].name}`;
  }

  useEffect(() => {
    if (barbershopMember !== null) {
      instance
        .post("/barbermap_barbershop_get_services_by_id", {
          data: barbershopMember,
        })
        .then((res) => {
          if (res.status === 200) {
            setServices(res.data.services);
          }
        })
        .catch((error) => {
          swalAlertDanger(
            "Error al obtener los datos",
            "Si el problema persiste, comuníquese con soporte técnico",
            () => {
              navigate("/login");
            }
          );
        });
      const data = {
        month: selectedMonth,
        age: selectedYear,
      };
      instance
        .post("/barbermap/barbershop/billing_by_month", { data: data })
        .then((res) => {
          setTotalBilling(res.data.response.totalBilling);
          setbillingByPaymentMethod(res.data.response.billingByPaymentMethod);
          setservicesByBarber(res.data.response.servicesByBarber);
          setservicesByService(res.data.response.servicesByService);
          setTotalBillingByBarber(res.data.response.billingByBarber);
        });
      setFetchButtonClicked(false);
    }
  }, [fetchButtonClicked, barbershopMember]);

  if (dataUser) {
    if (!validateRole(dataUser.user_rol, allowedRoles)) {
      return null;
    } else {
      return (
        <div>
          <SidebarOwner dataBarberShop={dataBarberShop} />
          <br />
          <div className="container">
            <hr />
            <ButtonsDash
              id_barbershop={barbershopMember}
              id_barber={dataUser.id_user}
              services={services}
              paymentMethods={paymentMethods}
              barberLink={linkBarber}
            />
            <hr />
            <div className="d-flex">
              <label htmlFor="mes" className="text-white"></label>
              <select
                className="select-side"
                id="mes"
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
              >
                {generateMonthOptions()}
              </select>

              <label htmlFor="anio" className="text-white"></label>
              <select
                className="select-side"
                id="anio"
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
              >
                {generateOptions(currentYear, currentYear + 5)}{" "}
                {/* Genera opciones para los próximos 5 años */}
              </select>

              <button
                onClick={() => setFetchButtonClicked(true)}
                className="btn btn-success"
              >
                Obtener Datos
              </button>

              {/* <FormFilterDate handleInfo={handleInfo} /> */}
            </div>
            <CardStats
              totalBilling={totalBilling}
              billingByPaymentMethod={billingByPaymentMethod}
              servicesByBarber={servicesByBarber}
              servicesByService={servicesByService}
              totalBillingByBarber={totalBillingByBarber}
            />
            <br />
            <hr />
          </div>
          <div className="container container-card-admin">
            <div className="card card-history">
              <h4>
                Consulta el historial y disponibilidad de todos tus barberos
              </h4>
              <Link to="/barbermap-barbershop-history">
                <button className="btn btn-primary">Historial de turnos</button>
              </Link>
            </div>
            <div className="card card-history">
              <h4>¿Tenes dudas? Accede a todos los tutoriales</h4>
              <Link to="/salon-management-software-tutorial">
                <button className="btn btn-primary">Tutoriales</button>
              </Link>
            </div>
          </div>
          {/* <TableShifts nextShifts={nextShifts} /> */}
          <hr className="container" />
          <Footer />
        </div>
      );
    }
  }
  return null;
};

export default DashboardBarberOwner;

import React, { useEffect, useState } from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import CardMap from "./CardMap";
import "../../styles/MapCards.css";
import instance from "../../connection_api";
import { swalAlertDanger } from "../common/Alerts";
import { useNavigate } from "react-router-dom";

const MapReview = ({ barberShops }) => {
  const [userLatitude, setUserLatitude] = useState(null);
  const [userLongitude, setUserLongitude] = useState(null);
  const [locationLoaded, setLocationLoaded] = useState(false);
  const [zoom, setZoom] = useState(14);

  useEffect(() => {
    const getUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setUserLatitude(position.coords.latitude);
            setUserLongitude(position.coords.longitude);
            setLocationLoaded(true);
          },
          (error) => {
            setLocationLoaded(true);
            handleLocationError();
          }
        );
      } else {
        setLocationLoaded(true);
        handleLocationError();
      }
    };

    getUserLocation();
  }, []);

  const handleLocationError = () => {
    setUserLatitude(-34.61);
    setUserLongitude(-58.38);
  };

  const initialPosition = { lat: userLatitude, lng: userLongitude };

  return (
    <div className="map-review-container">
      {locationLoaded ? (
        <Map center={initialPosition} zoom={zoom}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          {barberShops.map((barberShop) => (
            <Marker
              key={barberShop.id_barbershop}
              position={[barberShop.latitude, barberShop.longitude]}
            >
              <Popup>
                <div className="custom-popup-horizontal">
                  <CardMap barberShop={barberShop} />
                </div>
              </Popup>
            </Marker>
          ))}
        </Map>
      ) : (
        <p className="card">Esperando ubicación de usuario...</p>
      )}
    </div>
  );
};

export default MapReview;

import React, { useState, useEffect } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useSelector } from "react-redux";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import ModalWindow from "../common/ModalWindow";
import FormAddPhotosBarber from "../barber-owner/FormAddPhotosBarber";
import instance from "../../connection_api";
import {
  swalAlertDanger,
  swalAlertInfo,
  swalAlertInfoOrange,
  swalAlertSuccess,
} from "../common/Alerts";

const GalleryProfileBarber = ({ filesBarber, handleDelete }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dataUser = useSelector((state) => state.user.dataUser);

  const [showModal, setShowModal] = useState([]);
  const [selectedBarber, setSelectedBarber] = useState(null);

  const handleModal = async () => {
    setShowModal(true);
    setSelectedBarber(dataUser.id_user);
  };

  const handleAddPhotos = async (formData) => {
    instance
      .post("/barbermap/barber/add_images_to_gallery", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          swalAlertSuccess(
            "Fotos cargadas correctamente",
            "Los clientes podrán visualizar sus trabajos",
            () => {
              window.location.reload();
            }
          );
        } else {
          if (res.status === 203) {
            swalAlertInfo(
              `${res.data.msg}`,
              "Si desea aumentar su límite, contacte a un administrador",
              () => {
                window.location.reload();
              }
            );
          } else {
            swalAlertInfoOrange(
              "Ups",
              "Ocurrió un error en la carga",
              () => {}
            );
          }
        }
      })
      .catch((error) => {
        swalAlertDanger(
          "Error al cargar los archivos",
          "Intentelo nuevamente más tarde",
          () => {}
        );
      });
  };

  return (
    <>
      <div className="container mt-5">
        <h2>GALERIA DEL BARBERO</h2>
        <button
          className="btn btn-dark "
          onClick={() => {
            handleModal(dataUser.id_user);
          }}
        >
          <AddAPhotoIcon />
        </button>
        <table className="table table-striped container mt-4">
          <thead>
            <tr>
              <th>Foto</th>
              <th>Eliminar</th>
            </tr>
          </thead>
          <tbody>
            {filesBarber.map((photo) => (
              <tr key={photo.id_file}>
                <td>
                  <img
                    src={`${apiUrl}/gallery_photos/${photo.file_name}`}
                    alt={`Photo ${photo.id_file}`}
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                </td>
                <td>
                  <button
                    className="btn btn-danger mt-3"
                    onClick={() => handleDelete(photo)}
                  >
                    <DeleteForeverIcon />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          className="btn btn-danger"
          onClick={() => {
            window.history.back();
          }}
        >
          VOLVER
        </button>
      </div>
      {selectedBarber != null ? (
        <ModalWindow
          title={"Añadir imágenes para el barbero"}
          modalOpen={showModal}
          setModalOpen={setShowModal}
          body={
            <FormAddPhotosBarber
              barber={selectedBarber}
              handleAddPhotos={handleAddPhotos}
            />
          }
          footer={
            "Puedes colocar imágenes de los servicios realizados por el barbero. Límite de 20 imágenes por barbero"
          }
        />
      ) : (
        ""
      )}
    </>
  );
};

export default GalleryProfileBarber;

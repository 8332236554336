import React, { useState, useEffect } from "react";
import Sidebar from "../../components/admin/global/Sidebar";
import useSessionCheck from "../../customHooks/useSessionCheck";
import PlansBarbershop from "../../components/admin/components/pages/ConsultPlans/PlansBarbershop";
import instance from "../../connection_api";
import Logo4 from "../../assets/img/Logo4.png";
import {
  swalAlertDanger,
  swalAlertSuccess,
  swalConfirmationAlert,
} from "../../components/common/Alerts";

const ConsultPlans = () => {
  useSessionCheck();

  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    instance
      .get("/get_barbershop_plans")
      .then((res) => {
        setPlans(res.data.plans);
        setIsLoading(false);
      })
      .catch((error) => {
        swalAlertDanger(
          "ERROR AL OBTENER LOS DATOS",
          "Intente nuevamente más tarde",
          () => {}
        );
      });
  }, []);

  if (isLoading) {
    return (
      <>
        <div className="container bg-light text-center p-4 mt-5 rounded">
          <div className="loading-container">
            <img src={Logo4} alt="Loading" className="loading-image" />
          </div>
        </div>
      </>
    );
  }

  const handleDelete = async (data) => {
    swalConfirmationAlert(
      "¿Eliminar plan seleccionado?",
      "Esta acción es irreversible, el cliente no podrá visualizar el plan, verifica las barberías que contengan este plan.",
      () => {
        instance
          .post("/barbermap/admin/delete_plan", { data: data })
          .then((res) => {
            if (res.status === 200) {
              swalAlertSuccess("Plan eliminado correctamente", "", () => {
                window.location.reload();
              });
            }
          })
          .catch((error) => {
            console.log(error);
            swalAlertDanger("Error al eliminar el plan", "", () => {
              window.location.reload();
            });
          });
      }
    );
  };

  return (
    <div>
      <Sidebar />
      <div className="container">
        <PlansBarbershop plans={plans} handleDelete={handleDelete} />
      </div>
    </div>
  );
};

export default ConsultPlans;

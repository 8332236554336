import React, { useState } from "react";
import { swalAlertInfo } from "../common/Alerts";

const FormUnregisteredClient = ({ services, handleCreate, shift }) => {
  const [serviceName, setServiceName] = useState("");
  const [name, setName] = useState("");

  const onSubmit = () => {
    const data = {
      id_shift: shift,
      name: name,
      service: serviceName,
    };
    if (name == "" || serviceName == "") {
      return swalAlertInfo("Complete los campos del formulario", "", () => {});
    }
    handleCreate(data);
  };

  const handleServiceChange = (event) => {
    setServiceName(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  return (
    <>
      <div className="row">
        <div className="col-6">
          <select
            value={serviceName}
            onChange={handleServiceChange}
            className="form-control"
            required
          >
            <option value="">Seleccionar servicio</option>
            {services.map((service) => (
              <option key={service.id_service} value={service.id_service}>
                {service.service_description}
              </option>
            ))}
          </select>
        </div>
        <div className="col-6">
          <input
            className="form-control"
            placeholder="Cliente"
            value={name}
            onChange={handleNameChange}
            required
          />
        </div>
        <div className="col-12">
          <button className="btn btn-success mt-3 col-12" onClick={onSubmit}>
            Reservar turno
          </button>
        </div>
      </div>
    </>
  );
};

export default FormUnregisteredClient;

import React, { useEffect, useState } from "react";
import "../styles/LoginStyle.css";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/img/Logo3.png";
import "bootstrap/dist/css/bootstrap.min.css";
import instance from "../connection_api";
import { Link } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import {
  swalAlertDanger,
  swalAlertInfo,
  swalAlertSuccess,
} from "../components/common/Alerts";
import { verifyRoutes } from "../controllers/controllers";
import { setUserData } from "../components/redux/userActions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const Login = () => {
  const { id, name } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error] = useState("");

  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const [registerValues, setRegisterValues] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    registerEmail: "",
    registerPassword: "",
  });

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [barbershopAuth, setBarberShopAuth] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const [acceptTerms, setAcceptTerms] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await instance.get("/getCities");
      setCities(response.data.cities);

      if (id !== undefined) {
        const responseDataBS = await instance.get(
          `/data_barbershop_owner/${id}`
        );
        setBarberShopAuth(responseDataBS.data.dataBarberShop[0].req_auth);
      }

      setIsLoading(false);
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    if (barbershopAuth === 0) {
      navigate(`../../../barbershop/${id}/${name}`);
      setBarberShopAuth(null);
    }
  }, [barbershopAuth, id, name, navigate]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleChangeRegister = (event) => {
    const { name, value } = event.target;
    setRegisterValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const handleTermsChange = () => {
    setAcceptTerms(!acceptTerms);
  };

  const handleSubmitRegister = (event) => {
    event.preventDefault();

    if (!acceptTerms) {
      swalAlertInfo(
        "Términos y Condiciones",
        "Por favor, acepta los términos y condiciones antes de registrarte",
        () => {}
      );
      return; // Sale de la función handleSubmitRegister sin enviar el formulario
    }

    const registerData = {
      name: registerValues.firstName,
      lastname: registerValues.lastName,
      cellphone: registerValues.phone,
      email: registerValues.registerEmail,
      password: registerValues.registerPassword,
      city: selectedCity,
    };

    instance
      .post("/register", registerData)
      .then((response) => {
        if (response.status === 200) {
          swalAlertSuccess(
            "USUARIO REGISTRADO",
            "Ingrese al sistema con sus credenciales",
            () => {
              if (id && name) {
                setIsCheckboxChecked(false);
                navigate(`/login/barbershop/${id}/${name}`);
              } else {
                navigate("/");
              }
            }
          );
        } else {
          if (response.status === 203) {
            swalAlertInfo(
              "Verifique sus datos ingresados",
              `La contraseña debe contener al menos 1 mayúscula, 1 digito y 8 caracteres. 
              Ingrese el prefijo de su celular sin el número 0`,
              () => {}
            );
          } else {
            swalAlertDanger(
              "Ocurrió un error al registrarse",
              "Intentelo nuevamente más tarde",
              () => {
                navigate("/");
              }
            );
          }
        }
      })
      .catch((error) => {
        swalAlertDanger(
          "Servidor no disponible",
          "Intentelo nuevamente más tarde",
          () => {
            navigate("/");
          }
        );
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    instance
      .post("/login", values)
      .then((response) => {
        if (response.status === 200) {
          const user = response.data.user;
          dispatch(setUserData(user));
          if (id && name) {
            navigate(`../../../barbershop/${id}/${name}`);
          } else {
            navigate(verifyRoutes(user));
          }
        } else {
          swalAlertDanger(
            "CONTRASEÑA INCORRECTA",
            "Intentelo nuevamente",
            () => {}
          );
        }
      })
      .catch((error) => {
        swalAlertDanger(
          "Credenciales incorrectas",
          "Intentelo nuevamente",
          () => {}
        );
      });
  };

  if (isLoading) {
    return (
      <>
        <div className="container bg-light p-4 mt-5 rounded">
          <div className="">Cargando...</div>
          <ProgressBar animated now={100} />
        </div>
      </>
    );
  }

  return (
    <div className="login-page">
      <div className="section">
        <div className="container">
          <div className="text-danger">{error && error}</div>
          <div className="row full-height justify-content-center content_pag">
            <div className="col-12 text-center align-self-center py-5">
              <div className="logo-barber">
                <Link to="/">
                  <img src={Logo} alt="BARBER MAP"></img>
                </Link>
              </div>
              <form>
                <div className="section2 pb-5 pt-5 pt-sm-2 text-center">
                  <h6 className="login-text mb-0 pb-3">
                    <span>Iniciar sesión</span>
                    <span>Registrar</span>
                  </h6>
                  <input
                    className="checkboxs"
                    type="checkbox"
                    id="reg-log"
                    name="reg-log"
                    checked={isCheckboxChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="reg-log"></label>
                  <div className="card-3d-wrap mx-auto">
                    <div className="card-3d-wrapper">
                      <div className="card-front">
                        <div className="center-wrap">
                          <div className="section text-center">
                            <h4 className="mb-4 pb-3 register-text">
                              Iniciar sesión
                            </h4>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-style"
                                placeholder="Correo Electrónico"
                                name="username"
                                id="username"
                                value={values.username}
                                onChange={handleChange}
                              />
                              <i className="input-icon uil uil-at"></i>
                            </div>
                            <div className="form-group mt-2">
                              <input
                                type="password"
                                className="form-style"
                                placeholder="Contraseña"
                                name="password"
                                id="password"
                                value={values.password}
                                onChange={handleChange}
                              />
                              <i className="input-icon uil uil-lock-alt"></i>
                            </div>
                            <button
                              type="submit"
                              className="btn-login mt-4"
                              onClick={handleSubmit}
                            >
                              Iniciar sesión
                            </button>
                            <br />
                            <br />
                            <div onClick={handleCheckboxChange}>
                              <p className="forgot-pass mb-1 mt-4 text-center span-text">
                                ¿No tienes cuenta? ¡Registrate!
                                <label htmlFor="reg-log"></label>
                              </p>
                            </div>
                            <p className="forgot-pass mb-0 mt-4 text-center">
                              <Link
                                to="/forgot-password"
                                className="forgot-password text-info"
                              >
                                Olvidaste tu contraseña?
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card-back">
                        <div className="center-wrap">
                          <div className="section text-center">
                            <h4 className="register-text mb-3 pb-3">
                              Registrar usuario
                            </h4>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-style"
                                placeholder="Nombre"
                                name="firstName"
                                value={registerValues.firstName}
                                onChange={handleChangeRegister}
                              />
                              <i className="input-icon uil uil-user"></i>
                            </div>
                            <div className="form-group mt-2">
                              <input
                                type="text"
                                className="form-style"
                                placeholder="Apellido"
                                name="lastName"
                                value={registerValues.lastName}
                                onChange={handleChangeRegister}
                              />
                              <i className="input-icon uil uil-user"></i>
                            </div>
                            <div className="form-group mt-2">
                              <input
                                type="tel"
                                className="form-style"
                                placeholder="Teléfono"
                                name="phone"
                                value={registerValues.phone}
                                onChange={handleChangeRegister}
                              />
                              <i className="input-icon uil uil-phone"></i>
                            </div>
                            <div className="form-group mt-2">
                              <select
                                className="form-style"
                                name="city"
                                value={selectedCity}
                                onChange={(event) =>
                                  setSelectedCity(event.target.value)
                                }
                              >
                                <option value="" disabled>
                                  Seleccionar ciudad
                                </option>
                                {cities.map((city) => (
                                  <option
                                    key={city.id_city}
                                    value={city.id_city}
                                  >
                                    {city.city_description}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="form-group mt-2">
                              <input
                                type="email"
                                className="form-style"
                                placeholder="Correo Electrónico"
                                name="registerEmail"
                                value={registerValues.registerEmail}
                                onChange={handleChangeRegister}
                              />
                              <i className="input-icon uil uil-at"></i>
                            </div>
                            <div className="form-group mt-2">
                              <input
                                type="password"
                                className="form-style"
                                placeholder="Contraseña"
                                name="registerPassword"
                                value={registerValues.registerPassword}
                                onChange={handleChangeRegister}
                              />
                              <i className="input-icon uil uil-lock-alt"></i>
                            </div>
                            <div className="form-group-check mt-2">
                              <input
                                type="checkbox"
                                id="acceptTermsCheckbox"
                                name="acceptTermsCheckbox"
                                checked={acceptTerms}
                                onChange={handleTermsChange}
                                required
                              />{" "}
                              <label
                                htmlFor="acceptTermsCheckbox"
                                className="ml-2"
                              >
                                Acepto los{" "}
                                <Link to="/privacy-policy">
                                  Términos y Condiciones
                                </Link>
                              </label>
                            </div>
                            <button
                              type="submit"
                              className="btn-login mt-4"
                              onClick={handleSubmitRegister}
                            >
                              Registrarse
                            </button>
                            <div onClick={handleCheckboxChange}>
                              <p className="forgot-pass mb-1 mt-4 text-center span-text">
                                ¿Ya tienes cuenta? <b>Inicia sesión</b>
                                <label htmlFor="reg-log"></label>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

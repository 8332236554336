import React, { useState } from "react";

const FormBarberShift = ({ onSubmit, dataBarber }) => {
  const [formData, setFormData] = useState({
    barber: dataBarber[0].id_user,
    dateFrom: "",
    dateTo: "",
    startTime: "",
    endTime: "",
    interval: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <div className="container form-owner-barber">
      <div className="card card-owner-barber text-center">
        <h4 className="mb-4">
          GENERA TUS<br /> TURNOS AQUÍ
        </h4>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="mb-3 col-6">
              <label htmlFor="dateFrom" className="form-label">
                Fecha desde:
              </label>
              <input
                className="form-control"
                type="date"
                id="dateFrom"
                name="dateFrom"
                value={formData.dateFrom}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3 col-6">
              <label htmlFor="dateTo" className="form-label">
                Fecha hasta:
              </label>
              <input
                className="form-control"
                type="date"
                id="dateTo"
                name="dateTo"
                value={formData.dateTo}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="mb-3 col-6">
              <label htmlFor="startTime" className="form-label">
                Hora desde:
              </label>
              <input
                className="form-control"
                type="time"
                id="startTime"
                name="startTime"
                value={formData.startTime}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3 col-6">
              <label htmlFor="endTime" className="form-label">
                Hora hasta:
              </label>
              <input
                className="form-control"
                type="time"
                id="endTime"
                name="endTime"
                value={formData.endTime}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mb-3 col-6">
            <label htmlFor="interval" className="form-label">
              Intervalo entre turnos (minutos):
            </label>
            <input
              className="form-control"
              type="number"
              id="interval"
              name="interval"
              value={formData.interval}
              onChange={handleChange}
            />
          </div>
          <button type="submit" className="btn btn-primary btn-barber-owner">
            Generar turnos
          </button>
        </form>
      </div>
    </div>
  );
};

export default FormBarberShift;

import React from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const AdminGalleryBarberTable = ({ filesBarber, handleDelete }) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  return (
    <>
      <div className="container mt-5">
        <h2>GALERIA DEL BARBERO</h2>
        <table className="table table-striped container mt-4">
          <thead>
            <tr>
              <th>Foto</th>
              <th>Eliminar</th>
            </tr>
          </thead>
          <tbody>
            {filesBarber.map((photo) => (
              <tr key={photo.id_file}>
                <td>
                  <img
                    src={`${apiUrl}/gallery_photos/${photo.file_name}`}
                    alt={`Photo ${photo.id_file}`}
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                </td>
                <td>
                  <button
                    className="btn btn-danger mt-3"
                    onClick={() => handleDelete(photo)}
                  >
                    <DeleteForeverIcon />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          className="btn btn-danger"
          onClick={() => {
            window.history.back();
          }}
        >
          VOLVER
        </button>
      </div>
    </>
  );
};

export default AdminGalleryBarberTable;

import React, { useState } from "react";
import Logo from "../../assets/img/barber3.png";
import { useNavigate } from "react-router-dom";
import "../../styles/SidebarBarberOwner.css";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CollectionsIcon from "@mui/icons-material/Collections";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ModalWindow from "../common/ModalWindow";
import FormAddPhotosBarber from "./FormAddPhotosBarber";
import instance from "../../connection_api";
import {
  swalAlertDanger,
  swalAlertInfo,
  swalAlertInfoOrange,
  swalAlertSuccess,
} from "../common/Alerts";

const BarberCardAdmin = ({ barbers, handleDelete }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState([]);
  const [selectedBarber, setSelectedBarber] = useState(null);
  const handleViewBarber = (id_user) => {
    navigate(`/barbermap-barbershop/barber/${id_user}`);
  };

  const handleModal = async (barber) => {
    setShowModal(true);
    setSelectedBarber(barber);
  };

  const handleAddPhotos = async (formData) => {
    instance
      .post("/barbermap/barber/add_images_to_gallery", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          swalAlertSuccess(
            "Fotos cargadas correctamente",
            "Los clientes podrán visualizar sus trabajos",
            () => {
              window.location.reload();
            }
          );
        } else {
          if (res.status === 203) {
            swalAlertInfo(
              `${res.data.msg}`,
              "Si desea aumentar su límite, contacte a un administrador",
              () => {
                window.location.reload();
              }
            );
          } else {
            swalAlertInfoOrange(
              "Ups",
              "Ocurrió un error en la carga",
              () => {}
            );
          }
        }
      })
      .catch((error) => {
        swalAlertDanger(
          "Error al cargar los archivos",
          "Intentelo nuevamente más tarde",
          () => {}
        );
      });
  };

  return (
    <>
      <div className="mt-5 cardBarber-admin">
        <div className="div-card-barber-owner">
          {barbers.map((barber, index) => (
            <div className="card card-barber-owner" key={index}>
              <div className="content-container">
                <img className="logo-owner" src={Logo} alt="Logo" />
                <div className="barber-owner-details">
                  <h2 className="nameCardpres-barber">Profesional:</h2>
                  <h2 className="nameCardpres-barber">
                    {barber.name} {barber.lastname}
                  </h2>
                </div>
                <div className="div-buttons-card">
                  <button
                    className="btn btn-success ms-3"
                    onClick={() => handleViewBarber(barber.id_user)}
                  >
                    <CalendarMonthIcon />
                  </button>
                  <button
                    className="btn btn-dark ms-3"
                    onClick={() => {
                      handleModal(barber.id_user);
                    }}
                  >
                    <AddAPhotoIcon />
                  </button>
                  <br />
                  <button
                    className="btn btn-dark ms-3"
                    onClick={() => {
                      navigate(
                        `/barbermap/barbershop/gallery/barber/${barber.id_user}`
                      );
                    }}
                  >
                    <CollectionsIcon />
                  </button>
                  <button
                    className="btn btn-danger ms-3"
                    onClick={() => handleDelete(barber.id_user)}
                  >
                    <DeleteForeverIcon />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {selectedBarber != null ? (
        <ModalWindow
          title={"Añadir imágenes para el barbero"}
          modalOpen={showModal}
          setModalOpen={setShowModal}
          body={
            <FormAddPhotosBarber
              barber={selectedBarber}
              handleAddPhotos={handleAddPhotos}
            />
          }
          footer={
            "Puedes colocar imágenes de los servicios realizados por el barbero. Límite de 20 imágenes por barbero"
          }
        />
      ) : (
        ""
      )}
    </>
  );
};

export default BarberCardAdmin;

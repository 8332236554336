import React, { useState } from "react";

const FormRecuperationCode = ({ handleCode }) => {
  const [recoveryCode, setRecoveryCode] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    handleCode(recoveryCode);
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="recoveryCode">Código de Recuperación:</label>
          <input
            type="text"
            id="recoveryCode"
            className="form-control"
            value={recoveryCode}
            onChange={(e) => setRecoveryCode(e.target.value)}
            required
          />
        </div>
        <div className="cont-btn-modals">
          <button type="submit" className="btn btn-primary btn-modal">
            VERIFICAR
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormRecuperationCode;

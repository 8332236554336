import React, { useEffect, useState } from "react";
import instance from "../../connection_api";
// import {
//   swalAlertDanger,
//   swalAlertInfo,
//   swalAlertInfoOrange,
//   swalAlertSuccess,
// } from "../../components/common/Alerts";
// import FlechaAtras from "../../components/common/ArrowBack";
import Navbar from "../../components/nav-bottom/NavTop";
// import useSessionCheck from "../../customHooks/useSessionCheck";
import { useParams } from "react-router-dom";
import ProductCard from "../../components/catalog/ProductCard";

const UserCatalog = () => {
  // useSessionCheck();
  const { id_barbershop } = useParams();
  const [categoriesProduct, setCategoriesProduct] = useState([]);
  const [products, setProducts] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    instance
      .get("/barbermap/barbershop/get_categories_product")
      .then((res) => {
        setCategoriesProduct(res.data.categories);
      })
      .catch((error) => {
        console.log(error);
      });

    instance
      .post("/barbermap/user/get_catalog_by_idBarbershop", {
        data: id_barbershop,
      })
      .then((res) => {
        setProducts(res.data.products);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const filteredProducts = selectedCategory
      ? products.filter(
          (product) => product.category_product == selectedCategory
        )
      : products;
    setFilteredProducts(filteredProducts);
  }, [selectedCategory, products]);

  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
    const filteredProducts = categoryId
      ? products.filter((product) => product.category_product == categoryId)
      : products;
    setFilteredProducts(filteredProducts);
  };

  return (
    <>
      <div className="barber-shop-details-page">
        <div className="container-navbar">
          <Navbar />
        </div>
        <div>
          <br />
          <div className="mt-3 container-select-product">
            <label className="ms-4 text-light" htmlFor="categorySelect">Filtrar por categoría</label>
            <select
              id="categorySelect"
              className="form-city"
              onChange={(e) => handleCategoryChange(e.target.value)}
              value={selectedCategory || ""}
            >
              <option value="">Todas las categorías</option>
              {categoriesProduct.map((category) => (
                <option key={category.id_category} value={category.id_category}>
                  {category.category_description}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="container product-container row mt-5">
        {filteredProducts.map((product) => (
          <ProductCard
            key={product.id_product}
            product={product}
            handleEdit={""}
            handleDelete={""}
          />
        ))}
      </div>
    </>
  );
};

export default UserCatalog;

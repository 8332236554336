import React from "react";
import "../../styles/MapCards.css";
import "../../styles/BarberShopDetails.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";

const BarberShopDetails = ({ barberShopData }) => {
  return (
    <>
      <div className="container barbershop-detail">
        <div className="card-barbershop">
          {barberShopData.map((barbershop) => (
            <div key={barbershop.id}>
              <div>
                <img
                  className="img-logo-details"
                  src={`https://apiserver.barbermap.com.ar/logo_barbershops/${barbershop.path_logo}`}
                  // src={`http://localhost:5001/logo_barbershops/${barbershop.path_logo}`}
                  alt="test"
                />{" "}
              </div>
              <hr/>
              <div className="name-detail">{barbershop.name}</div>
              <hr/>
              <div className="address-detail">
                <FontAwesomeIcon icon={faMapMarker} /> {barbershop.address}
              </div>
              <hr/>
              <div className="description-detail">{barbershop.description}</div>
            </div>
          ))}
        </div>
      </div>
      {/* <div className="div__conteiner">
        {barberShopData.map((barbershop) => (
          <div key={barbershop.id}>
            <div className="div__contImg">
              <hr className="hr__back" />
              <img
                className="img__barberdetail"
                src={`http://localhost:5001/logo_barbershops/${barbershop.path_logo}`}
                alt="test"
              />{" "}
            </div>
            <div className="div__description">
              <div className="descrip__Tname">
                <h1 className="Barber__name">{barbershop.name}</h1>
              </div>
              <div className="div__adress">
                <FontAwesomeIcon icon={faMapMarker} className="icon__barberD" />
                <p className="font "> {barbershop.address}</p>
              </div>
              <div>
                <p className="font">Descripcion: {barbershop.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div> */}
    </>
  );
};

export default BarberShopDetails;

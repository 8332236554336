import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../styles/BannerStyle.css";
import "../../styles/ManagementHome.css";

const BannerTutorial = () => {
  return (
    <section className="banner background-banner-tutorial noselect">
      <Container className="container-banner-tutorial">
        <Row>
          <Col>
            <span className="tagline">
              Software de administracion y gestión de barberias
            </span>
            <h1>
              {"¿Cómo podemos ayudarte?"}
              <span className="wrap"></span>
            </h1>
            <p>Resuelve tus dudas y encuentra los mejores consejos.</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BannerTutorial;

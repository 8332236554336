import { useEffect } from "react";
import { swalAlertTimer } from "../components/common/Alerts";
import instance from "../connection_api";
import { useNavigate } from "react-router-dom";

const useSessionCheck = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await instance.get(`/sesion_pass`);
        return response;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          await swalAlertTimer(
            "SESION EXPIRADA, POR FAVOR VUELVA A INICIAR SESION",
            3000
          );
          navigate('/login');
        }
        return error;
      }
    };
    // Llama a la función de verificación de sesión cada vez que se monta el componente
    checkSession();
  }, []);

  return null; // No renderiza nada en el componente
};

export default useSessionCheck;

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SidebarOwner from "../../components/barber-owner/SidebarOwner";
import SidebarBarber from "../../components/barber/SidebarBarber";
import instance from "../../connection_api";
import { useSelector } from "react-redux";
import {
  swalAlertDanger,
  swalAlertInfoOrange,
  swalAlertSuccess,
  swalConfirmationAlert,
} from "../../components/common/Alerts";
import useSessionCheck from "../../customHooks/useSessionCheck";
import AdminGalleryBarberTable from "../../components/barber-owner/AdminGalleryBarberTable";
import Navbar from "../../components/nav-bottom/NavTop";
import GalleryBarber from "../user/GalleryBarber";
import GalleryProfileBarber from "../../components/barber/GalleryProfileBarber";

const AdminGalleryBarberView = () => {
  // useSessionCheck();
  const { id_user } = useParams();
  const dataUser = useSelector((state) => state.user.dataUser);
  const [dataBarberShop, setDataBarberShop] = useState([]);
  const [filesBarber, setFilesBarber] = useState([]);
  const [nameBarber, setNameBarber] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (dataUser) {
      instance
        .get(`/data_barbershop_owner/${dataUser.id_barbershop_owner}`)
        .then((response) => {
          setDataBarberShop(response.data.dataBarberShop);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    instance
      .get(`/get_files_by_barber/barber/${id_user}`)
      .then((res) => {
        if (res.status === 200) {
          setFilesBarber(res.data.files);
        }
      })
      .catch((error) => {
        console.log(error);
        swalAlertDanger(
          "Error al obtener los archivos para este barbero",
          "Intentelo nuevamente más tarde",
          () => {
            window.history.back();
          }
        );
      });

    if (dataUser && dataUser.user_rol === 2) {
      instance
        .get(`/barbershop_databarber_by_id/${dataUser.id_user}`)
        .then((res) => {
          setNameBarber(res.data[0]);
        })
        .catch((error) => {
          swalAlertDanger(
            "Error al obtener los turnos",
            "Intentelo nuevamente más tarde",
            () => {
              navigate("/user-management");
            }
          );
        });
    }
  }, [dataUser]);

  const handleDelete = (data) => {
    const file = data.id_file;
    swalConfirmationAlert(
      "¿Eliminar foto?",
      "No podrá deshacer esta acción",
      () => {
        instance
          .post("/barbermap/barber/delete_file", { file })
          .then((res) => {
            if (res.status === 200) {
              swalAlertSuccess("Foto eliminada", "", () => {
                window.location.reload();
              });
            } else {
              swalAlertInfoOrange(
                "Ups...",
                "Ocurrió un error al eliminar la foto",
                () => {}
              );
            }
          })
          .catch((error) => {
            swalAlertDanger(
              "ERROR EN EL SERVIDOR",
              "Intentelo nuevamente más tarde",
              () => {
                window.location.reload();
              }
            );
          });
      }
    );
  };

  return (
    <>
      {!dataUser || dataUser.user_rol === 3 ? (
        <>
          <div className="container">
            <Navbar />
          </div>
          <div className="mt-5">
            <GalleryBarber filesBarber={filesBarber} />
          </div>
        </>
      ) : dataUser.user_rol === 2 ? (
        <div>
          <div>
            <SidebarBarber barber={nameBarber} />
          </div>
          <GalleryProfileBarber
            filesBarber={filesBarber}
            handleDelete={handleDelete}
          />
        </div>
      ) : (
        <>
          <SidebarOwner dataBarberShop={dataBarberShop} />
          <div>
            <AdminGalleryBarberTable
              filesBarber={filesBarber}
              handleDelete={handleDelete}
            />
          </div>
        </>
      )}
    </>
  );
};

export default AdminGalleryBarberView;

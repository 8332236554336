import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux/es/hooks/useSelector";

const ProductCard = (props) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dataUser = useSelector((state) => state.user.dataUser);

  const { product, handleEdit, handleDelete } = props;

  if (!product) {
    return null;
  }

  return (
    <div className="col-md-3 mb-5 product-container">
      <div className="card product-details" key={product.id_product}>
        <img
          src={`${apiUrl}/catalog/${product.path_file_product}`}
          alt={`Photo ${product.path_file_product}`}
          loading="lazy"
        />
        <div className="product-details" key={product.id_product}>
          <hr />
          <h5>{product.title_product}</h5>
          <h6>{product.description_product}</h6>
          <h6>Stock actual: {product.quantity} unidades</h6>
          <p>${product.price_product}</p>
        </div>
        {dataUser.user_rol !== 3 && (
          <div className="product-actions">
            <button
              className="btn btn-primary"
              onClick={() => handleEdit(product)}
            >
              <EditIcon />
            </button>
            <button
              className="btn btn-danger"
              onClick={() => handleDelete(product.id_product)}
            >
              <DeleteIcon />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductCard;

import React from 'react'

const ConsultFreePage = () => {
  return (
    <div className='container'>
        <h1>APARTADO PARA CONSULTAR COBROS</h1>
    </div>
  )
}

export default ConsultFreePage
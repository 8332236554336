import React, { useState } from "react";
import { connect } from "react-redux";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import LogoAdmin from "../../../assets/img/Logo4.png";
import { logout } from "../../redux/userActions";
import "../../../styles/NavbarOwnerBarber.css";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import HomeIcon from "@mui/icons-material/Home";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <div>
      <Navbar expand="lg" className="menu-owner">
        <Container>
          <Navbar.Brand>
            <div className="div-sidebar">
              <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                onClick={() => setIsCollapsed(!isCollapsed)}
              >
                <MenuOutlinedIcon />
              </Navbar.Toggle>
            </div>
          </Navbar.Brand>

          <Navbar.Collapse id="responsive-navbar-nav">
            <div className="div-name text-decoration-none">ADMINISTRADOR BARBERMAP</div>
            <Nav className="ms-auto">
              <Nav.Link className="item-navbar-owner">
                <Link
                  to="/barbermap-admin-cba-administrator"
                  className="nav-link navtext"
                >
                  <HomeIcon className="icon-svg" />
                  Dashboard
                </Link>
              </Nav.Link>
              <Nav.Link className="item-navbar-owner">
                <Link
                  to="/barber-shops"
                  className="nav-link navtext"
                >
                  <PublicOutlinedIcon className="icon-svg" />
                  Ir al mapa
                </Link>
              </Nav.Link>
              <NavDropdown
                title="Gestiones"
                id="basic-nav-dropdown"
                className="nav-link item-navbar-owner-2"
              >
                <NavDropdown.Item>
                  <Link to="/barbermap-admin-cba-load-business-shop" className="navtext-2 nav-link">
                  <AddBusinessIcon className="icon-svg navtext-2" />
                    Cargar barberia
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    to="/barbermap-admin-cba-register-business"
                    className="nav-link navtext-2"
                  >
                    <PersonAddAlt1Icon className="icon-svg navtext-2" />
                    Registrar Barbero

                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="Consultas"
                id="basic-nav-dropdown"
                className="nav-link item-navbar-owner-2"
              >
                <NavDropdown.Item>
                  <Link
                    to="/barbermap-admin-cba-consult-business"
                    className="navtext-2 nav-link"
                  >
                    <ReceiptLongIcon className="icon-svg navtext-2" />
                    Consultar Barberías
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    to="/barbermap-admin-cba-consult-plans"
                    className="navtext-2 nav-link"
                  >
                    <RequestQuoteIcon className="icon-svg navtext-2" />
                    Consultar planes
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    to="/barbermap-admin-cba-consult-users"
                    className="navtext-2 nav-link"
                  >
                    <ManageAccountsIcon className="icon-svg" />
                    Consultar usuarios
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link className="item-navbar-owner-logout">
                <Link to="/login" onClick={logout} className="nav-link navtext">
                  <LogoutIcon className="icon-svg" />
                  Cerrar Sesión
                </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(null, mapDispatchToProps)(Sidebar);

import React, { useState, useEffect } from "react";
import Sidebar from "../../components/admin/global/Sidebar";
import ConsultUsers from "../../components/admin/components/pages/ConsultUsers/ConsultUsers";
import { useNavigate } from "react-router-dom";
import instance from "../../connection_api";
import Logo4 from "../../assets/img/Logo4.png";
import {
  swalAlertDanger,
  swalAlertSuccess,
  swalConfirmationAlert,
} from "../../components/common/Alerts";
import useSessionCheck from "../../customHooks/useSessionCheck";

const ConsultUserPage = () => {
  useSessionCheck();
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [cities, setCities] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get("/get_all_users");
        const responseRol = await instance.get("/get_roles");
        const responseCities = await instance.get("/getCities");

        setUsers(response.data.users);
        setRoles(responseRol.data.roles);
        setCities(responseCities.data.cities);

        setIsLoading(false);
      } catch (error) {
        swalAlertDanger("SESION EXPIRADA", "Vuelva a iniciar sesión", () => {
          navigate("/login");
        });
      }
    };
    fetchData();
  }, []);

  const handleChangeDataUser = (user) => {
    swalConfirmationAlert("¿Guardar cambios en el usuario?", "", () => {
      instance
        .post("/barbermap_admin_update_profile_user", { data: user })
        .then((res) => {
          if (res.status === 200) {
            swalAlertSuccess(`${res.data.msg}`, "", () => {
              navigate("/barbermap-admin-cba-administrator");
            });
          } else {
            swalAlertDanger("ERROR AL ACTUALIZAR LOS DATOS", "", () => {
              navigate("/barbermap-admin-cba-administrator");
            });
          }
        })
        .catch((error) => {
          swalAlertDanger(
            "ERROR AL ACTUALIZAR LOS DATOS",
            "Intentelo nuevamente más tarde",
            () => {
              navigate("/barbermap-admin-cba-consult-users");
            }
          );
        });
    });
  };

  if (isLoading) {
    return (
      <>
        <div className="container bg-light text-center p-4 mt-5 rounded">
          <div className="loading-container">
            <img src={Logo4} alt="Loading" className="loading-image" />
          </div>
        </div>
      </>
    );
  }

  return (
    <div>
        <Sidebar />
      <div className="load-barber-shop background-sidebar">
        <ConsultUsers
          users={users}
          roles={roles}
          cities={cities}
          handleChangeDataUser={handleChangeDataUser}
        />
      </div>
    </div>
  );
};
export default ConsultUserPage;

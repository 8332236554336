import React, { useState, useEffect } from "react";
import SidebarBarber from "../../components/barber/SidebarBarber";
import instance from "../../connection_api";
import { useSelector } from "react-redux";
import FormSearchDate from "../../components/commonForms/FormSearchDate";
import { useNavigate } from "react-router-dom";
import {
  swalAlertDanger,
  swalAlertSuccess,
  swalConfirmationAlert,
} from "../../components/common/Alerts";
import AcordeonBarberShifts from "../../components/barber-owner/AcordeonBarbersShifts";
import useSessionCheck from "../../customHooks/useSessionCheck";

const BarberHistory = () => {
  useSessionCheck();
  const dataUser = useSelector((state) => state.user.dataUser);
  const navigate = useNavigate();
  const [nameBarber, setNameBarber] = useState();
  const [shifts, setShifts] = useState([]);
  const [isOpenShifts, setOpenShifts] = useState(false);

  useEffect(() => {
    instance
      .get(`/barbershop_databarber_by_id/${dataUser.id_user}`)
      .then((res) => {
        setNameBarber(res.data[0]);
      })
      .catch((error) => {
        swalAlertDanger(
          "Error al obtener los turnos",
          "Intentelo nuevamente más tarde",
          () => {
            navigate("/user-management");
          }
        );
      });
  }, []);

  const handleSearch = (date) => {
    const data = {
      date,
      barber: dataUser.id_user,
    };
    instance
      .post("/barbermap_barbershop_barber_history", { data: data })
      .then((res) => {
        if (res.status === 200) {
          setShifts(res.data.shiftHistory);
          setOpenShifts(true);
        } else if (res.status === 203) {
          setShifts([]);
          setOpenShifts(true);
        }
      })
      .catch((error) => {
        swalAlertDanger(
          "Error al obtener los turnos",
          "Intentelo nuevamente más tarde",
          () => {
            navigate("/");
          }
        );
      });
  };

  const handleDeleteSelectedShifts = (data) => {
    swalConfirmationAlert(
      "¿DESEA ELIMINAR LOS TURNOS SELECCIONADOS?",
      "Solo se eliminarán los turnos en estado disponible o reservado. Si el turno estaba reservado se le notificará al cliente la cancelación del mismo",
      () => {
        instance
          .post("/barbermap_barbershop_delete_shifts", { data: data })
          .then((res) => {
            if (res.status === 200) {
              swalAlertSuccess("TURNOS ELIMINADOS", "", () => {
                navigate("/barbermap-barbershop-owner");
              });
            } else {
              swalAlertDanger(
                "ERROR AL ELIMINAR LOS TURNOS SELECCIONADOS",
                "Intente nuevamente más tarde",
                () => {
                  navigate("/barbermap-barbershop-owner");
                }
              );
            }
          });
      }
    );
  };

  return (
    <>
      <div>
        <SidebarBarber barber={nameBarber} />
        <div>
          <div className="mt-5 ms-2">
            <FormSearchDate onSearch={handleSearch} />
            <div className="container animated-wipe-down list-group">
              {isOpenShifts ? (
                <>
                  {shifts.length > 0 ? (
                    shifts.map((group) => (
                      <AcordeonBarberShifts
                        key={group.id_barber}
                        group={group}
                        handleDeleteSelectedShifts={handleDeleteSelectedShifts}
                      />
                    ))
                  ) : (
                    <div className="card card-body text-info mt-4">
                      No se encontraron turnos en el historial para la fecha
                      seleccionada
                    </div>
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BarberHistory;
